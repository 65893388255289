import Axios from "axios";
import moment from "moment";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import React, { useEffect, useState, useRef } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { Tab } from "@mui/material";
import useForceUpdate from "use-force-update";
import { API } from "../constants/api_url";
import { PropTypes } from "prop-types";
import { ThemeProvider, createTheme } from "@mui/material";
import { OverlayPanel } from "primereact/overlaypanel";
import { useHistory } from "react-router-dom";
import { MultiSelect } from "primereact/multiselect";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Tooltip } from "primereact/tooltip";
import Swal from "sweetalert2";
import APIServices from "../service/APIService";
import { Tag } from "primereact/tag";
import { Button } from "primereact/button";
import MaterialTable from "material-table";
import { hardcoded } from "../components/hardcoded/hardcodedid";
import { array, object } from "yup";
import { Splitter, SplitterPanel } from "primereact/splitter";
import { Divider } from "primereact/divider";
import { v4 as uuidv4 } from "uuid";
import { BlockUI } from "primereact/blockui";
import { checkRoleAccessByRoleIds } from "../components/BGHF/helper";
import KPIMetrics from "./KPIMetrics";
import { tvssection } from "../assets/tvs/js/tvssection";
import Tabs from "@mui/material/Tabs";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import WaterWithdrawalChart from "./Charts/WaterWithdrawal";
import LTIFChart from "./Charts/Ltif";
import HazardousWasteChart from "./Charts/HazardousWasteChart";
import SafetyCentralChart from "./Charts/SafetyCentral";
import SocialChart1 from "./Charts/SocialChart1";
import SocialChart2 from "./Charts/SocialChart2";
import SocialChart3 from "./Charts/SocialChart3";
import {Dashboard} from "./Charts/Dashboard";
import {
  HealthSafetyChart,
  HealthSafetyTrainingChart,
} from "./Charts/HealthSafetyChart";
import AntiCorruptionTrainingChart from "./Charts/Governance";
import { UnderDevelopment } from "../components/UnderDevelopment";
import { EnergyConsumptionChart } from "./Charts/EnergyConsumptionChart";
import { Scope2Chart } from "./Charts/Scope2Chart";
import { ScopeEmissionChart } from "./Charts/ScopeEmissionChart";
import FuelConsumptionChart from "./Charts/FuelConsumptionChart";
import RefrigerantChart from "./Charts/Refrigerant";
import { WasteManagement } from "./Charts/WasteManagementChart";
import SocialChart4 from "./Charts/SocialChart4";
import SupplyChainChart from "./Charts/SupplyChainChart";
import Scope1Chart from "./Charts/Scope1Chart";
import CombustionChart from "./Charts/CombustionChart";
import WaterDischargedChart from "./Charts/WaterDischarged";
import { WaterGeneratedChart } from "./Charts/WaterGeneratedChart";
import { NonHazardousChart } from "./Charts/NonHazardousChart";
import HazardousAndNonHazardousChart from "./Charts/HazardousAndNonHazardousChart";
import LtifMonthChart from "./Charts/LtifMonthChart";
import { SupplierMSIDashboard } from "./Charts/SupplierMSIDashboard";
import TrirMonthChart from "./Charts/TrirMonthChart";
import { SafetyTrainingChart } from "./Charts/SafetyTrainingChart";
import { Scope3Chart } from "./Charts/Scope3Chart";
import { FatalityChart } from "./Charts/FatalityChart";
import { SupplyChainPieChart } from "./Charts/SupplyChainPieChart";
import SocialChart5 from "./Charts/SocialChart5";
import GovernanceChart2 from "./Charts/GovernanceChart2";
import GovernanceChart3 from "./Charts/GovernanceChart3";
const { DateTime } = require("luxon");
const defaultMaterialTheme = createTheme();

const QuantitativeReporterOverallNew = () => {
  const { fymonth } = useSelector((state) => state.user.fyStartMonth);

  const allRoles = useSelector((state) => state.user.allRoles);
  const [approles, setAppRoles] = useState([]);
  const dpnamerefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];
  const formref = useRef(null);
  const [locList, setLocList] = useState({
    country: [],
    city: [],
    location: [],
  });

  const [assigneedialog, setAssigneeDialog] = useState(false);
  const [assigneeobj, setAssigneeObj] = useState({
    reviewer_ids: [],
    reporter_ids: [],
    approver_ids: [],
  });
  const entityref = useRef(null);
  const userList = useSelector((state) => state.userlist.userList);
  const [tempload, setTempLoad] = useState(false);
  const [assignedlist, setAssignedList] = useState([]);
  const [quantitativesubmission, setQuantiativeSubmission] = useState([]);
  const [stdlist, setStdList] = useState([]);
  const [subcat1, setSubCat1] = useState([]);
  const [subcat2, setSubCat2] = useState([]);
  const [subcat3, setSubCat3] = useState([]);
  const [subcat4, setSubCat4] = useState([]);
  const statusref = useRef(null);
  const login_data = useSelector((state) => state.user.userdetail);
  const admin_data = useSelector((state) => state.user.admindetail);
  const [entityoption, setEntityOption] = useState([]);
  const [formoption, setFormOption] = useState([]);
  const [yearoption, setYearOption] = useState([]);
  const [statusoption, setStatusOption] = useState([]);
  const [activeindex, setActiveIndex] = useState(0);
  const navigate = useHistory();
  const months = [
    "January",
    "Febraury",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const [submittedQuantitative, setSubmittedQuantitative] = useState([]);
  const [sectionlist, setSectionList] = useState([
    { name: "All", id: 0 },
    { name: "Sustainability", id: 8 },
    { name: "Health", id: 9 },
    { name: "Safety Central", id: 10 },
    { name: "Safety Operational", id: 11 },
    { name: "Supply Chain", id: 12 },
    { name: "Dealership Sustainability", id: 13 },
    { name: "Environmental", id: 14 },
    { name: "Social", id: 15 },
    { name: "Governance", id: 16 },
  ]);

  const [overallsubmittedQuantitative, setOverallSubmittedQuantitative] =
    useState([]);
  const [rawsitelist, setRawSitelist] = useState([]);
  const [filter, setFilter] = useState({
    year: null,
    entity: [],
    form: [],
    status: [],
    section: 0,
    country: 0,
    city: null,
    location: null,
  });
  const [refineddata, setRefinedData] = useState([]);
  const [refineddatabk, setRefinedDataBk] = useState([]);
  const [activetab, setActiveTab] = useState([6, 7, 1]);

  const [dcflist, setDcfList] = useState([]);
  const [dplist, setDPList] = useState([]);
  const [load, setLoading] = useState(false);
  const forceUpdate = useForceUpdate();
  const [remarksdialog, setRemarksDialog] = useState(false);
  const [remarksdata, setRemarksData] = useState([]);
  const [value, setValue] = useState("OVERVIEW");
  const [filterBy, setFilterBy] = useState("");

  const frequency_list = [
    { name: "Monthly", id: 1 },
    { name: "Bi-Monthly", id: 2 },
    { name: "Quartely", id: 3 },
    { name: "Annually", id: 4 },
    { name: "Bi-Annually", id: 5 },
    { name: "Undefined", id: 6 },
  ];
  const handleVisibilityChange = (isVisible) => {
    console.log(isVisible);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function CustomTabPanel(props) {
    const { children, value, tabValue, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== tabValue}
        id={`⁠ incident-tabpanel-${tabValue}`}
        aria-labelledby={`⁠ incident-tab-${tabValue}`}
        {...other}
      >
        {value === tabValue && <Box>{children}</Box>}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  const customFontStyle = {
    fontFamily: "Lato, sans-serif",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textTransform: "none",
  };
  const TABS = {
    OVERVIEW: "OVERVIEW",
    ENVIRONMENT: "ENVIRONMENT",
    SAFETY_OPERATIONS: "SAFETY_OPERATIONS",
    SAFETY_CENTRAL: "SAFETY_CENTRAL",
    HEALTH: "HEALTH",
    SUSTAINABILITY: "SUSTAINABILITY",
    SUPPLY_CHAIN: "SUPPLY_CHAIN",
    DEALERS: "DEALERS",
    SOCIAL: "SOCIAL",
    GOVERNANCE: "GOVERNANCE",
  };

  useEffect(() => {
    console.log(allRoles);
    let uriString = {
      include: [
        {
          relation: "locationTwos",
          scope: { include: [{ relation: "locationThrees" }] },
        },
      ],
    };
    let efstd = {
      include: [
        {
          relation: "newEfCategories",
          scope: {
            include: [
              {
                relation: "newEfSubcategory1s",
                scope: {
                  include: [
                    {
                      relation: "newEfSubcategory2s",
                      scope: {
                        include: [
                          {
                            relation: "newEfSubcategory3s",
                            scope: {
                              include: [{ relation: "newEfSubcategory4s" }],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
          },
        },
      ],
    };
    let uriString2 = {
      include: [
        {
          relation: "newTopics",
          scope: {
            include: [
              {
                relation: "newMetrics",
                scope: { include: [{ relation: "newDataPoints" }] },
              },
            ],
          },
        },
      ],
    };
    let Overall =
      API.Categories +
      `?filter=${encodeURIComponent(JSON.stringify(uriString2))}`;
    const promise0 = APIServices.get(API.DCF);
    const promise1 = APIServices.get(API.QN_Submit_UP(admin_data.id));
    const promise2 = APIServices.get(
      API.LocationOne_UP(admin_data.id) +
        `?filter=${encodeURIComponent(JSON.stringify(uriString))}`
    );
    const promise3 = APIServices.get(API.DCF_Entity_UP(admin_data.id));
    const promise4 = APIServices.get(API.DF_Entity_UP(admin_data.id));
    const promise5 = APIServices.get(API.SRF_Entity_UP(admin_data.id));
    const promise6 = APIServices.get(API.DCF_Entity_User_UP(admin_data.id));
    const promise7 = APIServices.get(API.DF_Entity_User_UP(admin_data.id));
    const promise8 = APIServices.get(API.SRF_Entity_User_UP(admin_data.id));
    const promise9 = APIServices.get(API.DataPoint);
    const promise10 = APIServices.get(
      API.EF_Std + `?filter=${encodeURIComponent(JSON.stringify(efstd))}`
    );
    const promise11 = APIServices.get(API.EF_SC1);
    const promise12 = APIServices.get(API.EF_SC2);
    const promise13 = APIServices.get(API.EF_SC3);
    const promise14 = APIServices.get(API.EF_SC4);
    const promise15 = APIServices.get(API.AssignDCFClient_UP(admin_data.id));
    const promise16 = APIServices.get(Overall);
    const promise17 = APIServices.get(
      API.GetRoleByUserId(admin_data.id, login_data.id)
    );
    const promise18 = APIServices.get(API.Roles);
    Promise.all([
      promise0,
      promise1,
      promise2,
      promise3,
      promise4,
      promise5,
      promise6,
      promise7,
      promise8,
      promise9,
      promise10,
      promise11,
      promise12,
      promise13,
      promise14,
      promise15,
      promise16,
      promise17,
      promise18,
    ]).then((values) => {
      console.log(values[15].data);
      let curated_dcf_ids = [],
        dcf_list = values[0].data;
      setAppRoles(values[18].data);
      if (values[15].data && values[15].data.length > 0) {
        values[16].data.forEach((cat) => {
          if (cat.newTopics !== undefined) {
            cat.newTopics.forEach((topic) => {
              if (
                topic.newMetrics !== undefined &&
                values[15].data[0].topic_ids.includes(topic.id) &&
                (topic.tag === null || parseFloat(topic.tag) === admin_data.id)
              ) {
                topic.newMetrics.forEach((metric) => {
                  if (
                    Array.isArray(metric.data1) &&
                    values[15].data[0].metric_ids.includes(metric.id) &&
                    metric.data1[0].type !== undefined &&
                    metric.data1[0].type === 0 &&
                    (metric.tag === null || metric.tag === admin_data.id)
                  ) {
                    if (metric.newDataPoints !== undefined) {
                      metric.newDataPoints.forEach((dp) => {
                        if (
                          Array.isArray(dp.data1) &&
                          dp.data1.length !== 0 &&
                          dp.data1[0].datasource !== null &&
                          typeof dp.data1[0].datasource === "number"
                        ) {
                          let dcf_index = values[0].data.findIndex(
                            (i) => i.id === dp.data1[0].datasource
                          );
                          if (
                            !curated_dcf_ids.includes(dp.data1[0].datasource) &&
                            dcf_index !== -1
                          ) {
                            if (
                              values[0].data[dcf_index].tags === null ||
                              !values[0].data[dcf_index].tags.length ||
                              values[0].data[dcf_index].tags.includes(
                                admin_data.id
                              )
                            ) {
                              curated_dcf_ids.push(dp.data1[0].datasource);

                              let index = dcf_list.findIndex(
                                (i) => i.id === dp.data1[0].datasource
                              );
                              if (index !== -1) {
                                dcf_list[index]["section"] = findKeyById(
                                  tvssection,
                                  metric.id
                                );
                              }
                            }
                          }
                        }
                      });
                    }
                  }
                });
              }
            });
          }
        });
      }
      setSubCat1(values[11].data);
      setSubCat2(values[12].data);
      setSubCat3(values[13].data);
      setSubCat4(values[14].data);

      let stdList = values[10].data;
      let quantitative_submitted = values[1].data,
        qn_submit_list = [],
        overall_qn_submit_list = [],
        quantitative_ass_list = values[6].data,
        qualitative_ass_list = values[7].data,
        srf_ass_list = values[8].data;
      let quantitative_entity_list = values[3].data,
        qualitative_entity_list = values[4].data,
        srf_entity_list = values[5].data;

      let shapedSite = values[2].data
        .map((item) => {
          if (item.locationTwos) {
            item.locationTwos = item.locationTwos.filter(
              (locationTwo) =>
                locationTwo.locationThrees &&
                locationTwo.locationThrees.length > 0
            );
          }
          return item;
        })
        .filter((item) => item.locationTwos && item.locationTwos.length > 0);

      console.log(shapedSite);

      let filtered_qn_ass = quantitative_ass_list.filter((i) => {
        return (
          dcf_list.map((j) => j.id).includes(i.dcfId) &&
          curated_dcf_ids.includes(i.dcfId) &&
          checkEntity(i, values[3].data, "dcfId") &&
          (login_data.role === "clientadmin" ||
            checkRoleAccessByRoleIds(
              [login_data.id],
              [1],
              i.level,
              i[`tier${i.level}_id`],
              values[17].data.filter(
                (i) =>
                  i.user_id === login_data.id &&
                  i.userProfileId === admin_data.id
              ),
              shapedSite
            ).includes(login_data.id))
        );
      });
      console.log(filtered_qn_ass);

      setDcfList(dcf_list);
      setDPList(values[9].data);
      setLocList((prev) => ({
        ...prev,
        country: [
          { name: "Enterprise-wide", id: 0 },
          ...shapedSite.map((i) => ({ name: i.name, id: i.id })),
        ],
      }));

      setRawSitelist(shapedSite);
      setStdList(values[10].data);
      quantitative_submitted.forEach((item) => {
        let filtered_qn_ass_index = filtered_qn_ass.findIndex((i) => {
          console.log(
            i.id,
            item.entityUserAssId,
            i.entityAssId,
            item.entityAssId
          );
          return (
            i.id === item.entityUserAssId && i.entityAssId === item.entityAssId
          );
        });
        if (filtered_qn_ass_index !== -1) {
          overall_qn_submit_list.push(item);
          if (
            filtered_qn_ass[filtered_qn_ass_index].reporter_ids.includes(
              login_data.id
            ) ||
            filtered_qn_ass[filtered_qn_ass_index].reviewer_ids.includes(
              login_data.id
            )
          ) {
            qn_submit_list.push(item);
          }
        }
      });
      setAssignedList(
        filtered_qn_ass.filter(
          (i) =>
            i.reporter_ids.includes(login_data.id) ||
            i.reviewer_ids.includes(login_data.id)
        )
      );
      setQuantiativeSubmission(quantitative_submitted);

      let yearOption = generateFiscalYearsByAssignment(
        filtered_qn_ass,
        fymonth
      );
      console.log(yearOption);
      if (yearOption.length) {
        let currentYr = Math.max(...yearOption.map((i) => i.value));

        let filteredQnAssByYear = filterAssignmentsByFiscalYear(
          filtered_qn_ass.filter(
            (i) =>
              i.reporter_ids.includes(login_data.id) ||
              i.reviewer_ids.includes(login_data.id)
          ),
          currentYr
        );
        console.log(filteredQnAssByYear);
        if (filteredQnAssByYear.length) {
          calculateAndCreateYearData(
            filteredQnAssByYear,
            values[0].data,
            quantitative_submitted.filter((i) =>
              isDateInFiscalYear(currentYr, getRPTextFormat(i.reporting_period))
            ),
            stdList,
            {
              cat1: values[11].data,
              cat2: values[12].data,
              cat3: values[13].data,
              cat4: values[14].data,
            },
            currentYr
          ).then((res) => {
            console.log(res);
            let entity_option = Array.from(
              new Set(res.map((i) => getCoverageTextById(i, shapedSite)))
            ).map((i) => ({ title: i, id: i }));
            setFormOption(
              dcf_list.filter((i) => res.map((i) => i.dcfId).includes(i.id))
            );
            setEntityOption(entity_option);
            setYearOption(yearOption);
            if (res.length) {
              onSelectedChangeIntial("year", currentYr, res);
            } else {
              setFilter((prev) => ({ ...prev, year: currentYr }));
              setLoading(true);
            }
            // setRefinedData(res);
            setRefinedDataBk(res);
          });
        } else {
          setLoading(true);
        }
      } else {
        setLoading(true);
      }
      // setOverallSubmittedQuantitative(
      //     overall_qn_submit_list.filter((i) => i.type !== 0)
      // );
      // setSubmittedQuantitative(qn_submit_list.filter((i) => i.type === 1));
      // extractDPFromForms(qn_submit_list.filter((i) => i.type === 1 ));
    });
  }, []);

  function findKeyById(tvssection, id) {
    for (const key in tvssection) {
      if (tvssection[key].includes(id)) {
        return parseFloat(key);
      }
    }
    return 0;
  }
  function generateFiscalYearsByAssignment(assignments, fymonth) {
    const result = [];

    assignments.forEach((assignment) => {
      const { start_date, end_date } = assignment;
      const fiscalYears = getFiscalYearRange_YearOption(
        fymonth,
        start_date,
        end_date
      );

      fiscalYears.forEach((fy) => {
        if (!result.some((r) => r.value === fy.value && r.title === fy.title)) {
          result.push(fy);
        }
      });
    });

    return result;
  }
  function getFiscalYearRange_YearOption(fymonth, startDate, endDate) {
    const start = DateTime.fromISO(startDate).plus({ months: 1 });
    const end = endDate
      ? DateTime.fromISO(endDate).plus({ months: 1 })
      : DateTime.local();
    const result = [];

    let startYear = start.year;
    let endYear = end.year;

    if (fymonth !== 1) {
      if (start.month < fymonth) {
        startYear -= 1;
      }
      if (end.month < fymonth) {
        endYear -= 1;
      }
    }

    for (let year = startYear; year <= endYear; year++) {
      if (fymonth === 1) {
        result.push({ value: year, title: year.toString() });
      } else {
        result.push({
          value: year + 1,
          title: `${year}-${(year + 1).toString().slice(-2)}`,
        });
      }
    }

    return result;
  }
  const getFiscalYearRange = (year, fymonth) => {
    let startDate, endDate;
    console.log(fymonth);

    if (fymonth === 1) {
      startDate = DateTime.fromObject({ year, month: 1, day: 1 }).startOf(
        "day"
      );
      endDate = DateTime.fromObject({ year, month: 12, day: 31 }).endOf("day");
    } else {
      startDate = DateTime.fromObject({
        year: year - 1,
        month: fymonth,
        day: 1,
      }).startOf("day");
      endDate = DateTime.fromObject({ year, month: fymonth - 1, day: 1 }).endOf(
        "month"
      );
    }

    return { startDate, endDate };
  };
  const filterAssignmentsByFiscalYear = (assignments, year) => {
    const { startDate, endDate } = getFiscalYearRange(year, fymonth);
    const currentDate = DateTime.local().startOf("day");
    console.log(startDate, endDate, year);

    return assignments.filter((assignment) => {
      const assignmentStartDate = assignment.start_date
        ? DateTime.fromISO(assignment.start_date, { zone: "utc" })
            .startOf("month")
            .plus({ month: 1 })
        : currentDate;
      const assignmentEndDate = assignment.end_date
        ? DateTime.fromISO(assignment.end_date, { zone: "utc" })
            .startOf("day")
            .plus({ month: 1 })
        : currentDate;
      console.log(
        assignmentStartDate.toFormat("yyyy-MM-dd"),
        assignmentEndDate.toFormat("yyyy-MM-dd")
      );
      return (
        (assignmentStartDate >= startDate && assignmentStartDate <= endDate) ||
        (assignmentEndDate >= startDate && assignmentEndDate <= endDate) ||
        (assignmentStartDate <= startDate && assignmentEndDate >= endDate)
      );
    });
  };
  function getFiscalYearsFromStartDate(start_date) {
    console.log(start_date);
    const startDate = DateTime.fromISO(start_date, { zone: "utc" }).plus({
      months: 1,
    });
    const currentDate = DateTime.now();

    let startFiscalYear, currentFiscalYear;
    const fiscalYears = [];

    if (fymonth === 1) {
      // When fiscal month is January, it's a single year
      startFiscalYear = startDate.year;
      currentFiscalYear = currentDate.year;

      for (let year = startFiscalYear; year <= currentFiscalYear; year++) {
        fiscalYears.push({ name: year, label: `${year}` });
      }
    } else {
      // Normal fiscal year spanning two calendar years
      startFiscalYear =
        startDate.month >= fymonth ? startDate.year : startDate.year - 1;
      currentFiscalYear =
        currentDate.month >= fymonth ? currentDate.year : currentDate.year - 1;

      for (let year = startFiscalYear; year <= currentFiscalYear; year++) {
        const label = `${year}-${(year + 1).toString().slice(-2)}`;
        fiscalYears.push({ name: year + 1, label });
      }

      // Include the current fiscal year only if the current month is before the fiscal year start month
      if (currentDate.month < fymonth) {
        fiscalYears.pop();
      }
    }

    return fiscalYears;
  }

  function getMonthsBetween_(
    startDate,
    endDate,
    frequency,
    requiredYear,
    fyMonth
  ) {
    // Convert input dates to DateTime objects
    const inputStartDate = DateTime.fromISO(startDate, { zone: "utc" });
    const inputEndDate = endDate
      ? DateTime.fromISO(endDate, { zone: "utc" }).endOf("month")
      : DateTime.utc().endOf("month");

    // Calculate the fiscal year start and end dates
    let startYear = requiredYear;
    if (
      fyMonth > 1 &&
      (DateTime.utc().month < fyMonth ||
        (DateTime.utc().month === fyMonth &&
          DateTime.utc().year < requiredYear))
    ) {
      startYear -= 1;
    }
    const fiscalStartDate = DateTime.utc(startYear, fyMonth, 1);
    const fiscalEndDate = DateTime.utc(startYear + 1, fyMonth, 1).minus({
      days: 1,
    });

    // Clamp the start_date and end_date within the fiscal year and current date
    const clampedStartDate =
      inputStartDate < fiscalStartDate ? fiscalStartDate : inputStartDate;
    const clampedEndDate =
      inputEndDate > fiscalEndDate ? fiscalEndDate : inputEndDate;
    const finalEndDate =
      clampedEndDate > DateTime.utc() ? DateTime.utc() : clampedEndDate;

    const months = [];
    let currentMonthDate = clampedStartDate;

    while (currentMonthDate <= finalEndDate) {
      if (frequency === 1) {
        if (currentMonthDate <= finalEndDate) {
          months.push(currentMonthDate.toFormat("MMM-yyyy"));
        }
      } else {
        let periodEnd = currentMonthDate
          .plus({ months: frequency - 1 })
          .endOf("month");
        if (periodEnd > finalEndDate) {
          periodEnd = finalEndDate;
        }
        if (currentMonthDate <= finalEndDate) {
          months.push(
            currentMonthDate.toFormat("MMM-yyyy") +
              " to " +
              periodEnd.toFormat("MMM-yyyy")
          );
        }
      }

      currentMonthDate = currentMonthDate.plus({ months: frequency });
    }
    console.log(months);
    return months;
  }

  function getMonthsBetween(
    startDate,
    endDate,
    frequency,
    requiredYear,
    fyMonth
  ) {
    // Default to the current date if startDate or endDate is null
    const currentDate = DateTime.now();
    startDate = startDate
      ? DateTime.fromISO(startDate).startOf("month")
      : currentDate;
    endDate = endDate ? DateTime.fromISO(endDate) : currentDate;

    // Define fiscal year start and end months based on fyMonth
    const fiscalStartMonth = fyMonth - 1; // 0-based month
    const fiscalEndMonth = (fyMonth + 11) % 12; // Fiscal year ends 11 months after fyMonth

    // Define start and end date based on requiredYear and fyMonth
    let fiscalStartDate = DateTime.fromObject({
      year: requiredYear,
      month: fiscalStartMonth + 1,
      day: 1,
    });
    let fiscalEndDate = DateTime.fromObject({
      year: requiredYear + 1,
      month: fiscalEndMonth + 1,
      day: 1,
    }).minus({ days: 1 }); // Last day of the fiscal year

    if (fyMonth !== 1) {
      // Special case for fiscal year starting in April
      fiscalStartDate = DateTime.fromObject({
        year: requiredYear - 1,
        month: fiscalStartMonth + 1,
        day: 1,
      });
      fiscalEndDate = DateTime.fromObject({
        year: requiredYear,
        month: fiscalEndMonth + 1,
        day: 1,
      }).minus({ days: 1 });
    }

    // Adjust startDate and endDate to be within the fiscal year
    startDate = startDate < fiscalStartDate ? fiscalStartDate : startDate;
    endDate = endDate > fiscalEndDate ? fiscalEndDate : endDate;
    console.log(startDate, endDate, requiredYear);
    // List to store the months
    const months = [];
    let current = startDate.startOf("month");

    // Loop to add months based on frequency
    while (current <= endDate) {
      let nextDate = current.plus({ months: frequency - 1 }).startOf("month");
      if (current > endDate) {
        nextDate = endDate;
      }
      if (frequency === 1) {
        months.push(current.toFormat("MMM-yyyy"));
      } else {
        months.push(
          `${current.toFormat("MMM-yyyy")} to ${nextDate.toFormat("MMM-yyyy")}`
        );
      }
      current = nextDate.plus({ months: 1 }).startOf("month");
    }

    return months;
  }

  const getStatusCode = (arr) => {
    console.log(arr);
    return new Promise((resolve, err) => {
      let codes = [];
      arr.forEach((i) => {
        Object.entries(i).forEach((j) => {
          if (!j[0].includes("name")) {
            if (j[1].length) {
              if (j[1][0].id !== undefined) {
                if (j[1][0].status === 5) {
                  if (!codes.includes(7)) {
                    codes.push(7);
                  }
                } else if (j[1][0].status === 4) {
                  if (!codes.includes(6)) {
                    codes.push(6);
                  }
                } else if (j[1][0].status === 3) {
                  if (!codes.includes(5)) {
                    codes.push(5);
                  }
                } else if (j[1][0].status === 2) {
                  if (!codes.includes(8)) {
                    codes.push(8);
                  }
                } else if (j[1][0].status === 1) {
                  if (!codes.includes(1)) {
                    codes.push(1);
                  }
                } else if (
                  getOverdueDays(j[0]) <= -15 &&
                  (j[1][0].status === null || j[1][0].status === 0)
                ) {
                  if (!codes.includes(2)) {
                    codes.push(2);
                  }
                } else if (
                  getOverdueDays(j[0]) <= 0 &&
                  (j[1][0].status === null || j[1][0].status === 0)
                ) {
                  if (!codes.includes(3)) {
                    codes.push(3);
                  }
                } else if (
                  getOverdueDays(j[0]) > 0 &&
                  (j[1][0].status === null || j[1][0].status === 0)
                ) {
                  if (!codes.includes(4)) {
                    codes.push(4);
                  }
                }
              } else {
                console.log(j);
                if (getOverdueDays(j[0]) <= -15) {
                  if (!codes.includes(2)) {
                    codes.push(2);
                  }
                } else if (getOverdueDays(j[0]) <= 0) {
                  if (!codes.includes(3)) {
                    codes.push(3);
                  }
                } else if (getOverdueDays(j[0]) > 0) {
                  if (!codes.includes(4)) {
                    codes.push(4);
                  }
                }
              }
            } else {
              if (getOverdueDays(j[0]) <= -15) {
                if (!codes.includes(2)) {
                  codes.push(2);
                }
              } else if (getOverdueDays(j[0]) <= 0) {
                if (!codes.includes(3)) {
                  codes.push(3);
                }
              } else if (getOverdueDays(j[0]) > 0) {
                if (!codes.includes(4)) {
                  codes.push(4);
                }
              }
            }
          }
        });
      });
      if (!codes.length) {
        codes.push(0);
      }
      resolve(codes);
    });
  };

  const getDPNameHC = (arr) => {
    let result = [],
      count = 0;
    console.log(arr);
    arr.forEach((i) => {
      Object.values(i).forEach((j) => {
        j.forEach((k) => {
          if (result.findIndex((x) => x.label === k.dp) === -1) {
            count++;
            result.push({ label: k.dp, order: count });
          }
        });
      });
    });
    console.log(result);
    return result;
  };
  async function calculateAndCreateYearData(
    assignment,
    dcf_list,
    submission_list,
    efstdlist,
    subcat,
    requiredyear
  ) {
    console.log(assignment);
    const yearData = [];
    const actualData = [];
    const uniqueCombinations = [];
    return new Promise((resolve, reject) => {
      assignment.forEach((item) => {
        console.log(item);
        // Extract necessary information
        const {
          start_date,
          end_date,
          tier0_id,
          tier1_id,
          tier2_id,
          tier3_id,
          dcfId,
          frequency,
          standard,
        } = item;

        // Convert start_date and end_date to Luxon DateTime objects
        const startDate = DateTime.fromISO(start_date);
        const endDate = end_date ? DateTime.fromISO(end_date) : DateTime.utc();

        // Generate year array based on start_date and end_date
        const startDateYear = startDate.year;
        const endDateYear = endDate.year;
        const yearArray = Array.from(
          { length: endDateYear - startDateYear + 1 },
          (_, index) => startDateYear + index
        );
        console.log(
          frequency,
          item,
          getMonthsBetween(
            start_date,
            end_date,
            frequency === 4 ? 12 : frequency === 5 ? 6 : frequency,
            requiredyear,
            fymonth
          )
        );
        // Check if the combination already exists
        const existingCombinationIndex = uniqueCombinations.findIndex(
          (combination) => {
            return (
              combination.tier0_id === tier0_id &&
              combination.tier1_id === tier1_id &&
              combination.tier2_id === tier2_id &&
              combination.tier3_id === tier3_id &&
              combination.standard === standard &&
              combination.dcfId === dcfId
            );
          }
        );

        if (existingCombinationIndex !== -1) {
          const existingYears = new Set(
            uniqueCombinations[existingCombinationIndex].year
          );

          // Add new years from yearArray to the existingYears Set
          yearArray.forEach((year) => {
            existingYears.add(year);
          });
          console.log(item.id === 87, item);
          actualData.push({
            ...item,
            year: yearArray,
            dataobject: getMonthsBetween(
              start_date,
              end_date,
              frequency === 4 ? 12 : frequency === 5 ? 6 : frequency,
              requiredyear,
              fymonth
            ),
          });
          uniqueCombinations[existingCombinationIndex].year =
            Array.from(existingYears);
          // Convert the Set back to an array and update the uniqueCombinations entry
          uniqueCombinations[existingCombinationIndex].dataobject = [
            ...uniqueCombinations[existingCombinationIndex].dataobject,
            ...getMonthsBetween(
              start_date,
              end_date,
              frequency === 4 ? 12 : frequency === 5 ? 6 : frequency,
              requiredyear,
              fymonth
            ),
          ];
        } else {
          // If the combination doesn't exist, create a new object
          actualData.push({
            ...item,
            year: [...yearArray, DateTime.utc().toLocal().year + 1],
            dataobject: getMonthsBetween(
              start_date,
              end_date,
              frequency === 4 ? 12 : frequency === 5 ? 6 : frequency,
              requiredyear,
              fymonth
            ),
          });
          uniqueCombinations.push({
            ...item,
            year: [...yearArray, DateTime.utc().toLocal().year + 1],
            dataobject: getMonthsBetween(
              start_date,
              end_date,
              frequency === 4 ? 12 : frequency === 5 ? 6 : frequency,
              requiredyear,
              fymonth
            ),
          });
        }
      });
      console.log(uniqueCombinations);
      uniqueCombinations.forEach((item) => {
        console.log(item, item.dcfId);
        const {
          start_date,
          end_date,
          tier0_id,
          tier1_id,
          tier2_id,
          tier3_id,
          dcfId,
          locationId,
          entityAssId,
          frequency,
          level,
          standard,
          dataobject,
        } = item;
        console.log(actualData);
        // let newObj = { standard, company_id: admin_data.id, level, locationId, entityAssId, entityUserAssId: item.id, frequency, self: item.reviewer_ids.length !== 0 ? false : true }

        console.log(start_date, end_date);
        const dcfItem = dcf_list.find((item) => item.id === dcfId);

        console.log(dataobject);
        if (dcfItem) {
          let data1ForYear = [],
            msn = [
              {
                name: JSON.parse(
                  JSON.stringify(
                    dcfItem.data1.filter((obj) => obj.type === "number")
                  )
                ),
              },
            ];
          data1ForYear = JSON.parse(
            JSON.stringify(dcfItem.data1.filter((obj) => obj.type === "number"))
          );
          item.year.forEach((y) => {
            item.dataobject
              .filter((i) => {
                return isDateInFiscalYear(y, i);
              })
              .forEach((xy, xindx) => {
                let act = actualData.filter(
                  (i) =>
                    i.dcfId === item.dcfId &&
                    i.tier0_id === tier0_id &&
                    i.tier1_id === tier1_id &&
                    i.tier2_id === tier2_id &&
                    i.tier3_id === tier3_id &&
                    i.standard === standard &&
                    i.dataobject.includes(xy)
                );
                let newObj2 = {
                  company_id: admin_data.id,
                  level,
                  locationId,
                  entityAssId,
                };
                newObj2["self"] =
                  act[0].reviewer_ids.length === 0 ? true : false;
                newObj2["refobj"] = item;
                newObj2["frequency"] = act[0].frequency;
                newObj2["entityUserAssId"] = act[0].id;
                newObj2["standard"] = act[0].standard;
                if (
                  hardcoded.dcf.includes(dcfId.toString()) ||
                  hardcoded.dcf2.includes(dcfId.toString())
                ) {
                  if (dcfId === 11 || dcfId === 257) {
                    msn.push(
                      getValueByMonthlyHC3Sub(
                        dcfId,
                        tier0_id,
                        tier1_id,
                        tier2_id,
                        tier3_id,
                        [xy],
                        dcfId === 11
                          ? ["DPA0131", "DPA0132", "DPA0336"]
                          : ["DPAN096", "DPAN099", "DPAN098"],
                        submission_list,
                        efstdlist,
                        subcat,
                        newObj2.standard,
                        "",
                        newObj2.self,
                        newObj2
                      )
                    );
                  } else if (dcfId === 10 || dcfId === 16) {
                    msn.push(
                      getValueByMonthlyHC1Sub(
                        dcfId,
                        tier0_id,
                        tier1_id,
                        tier2_id,
                        tier3_id,
                        [xy],
                        dcfId === 10
                          ? ["DPA0136", "DPA0138"]
                          : ["DPA0287", "DPA0289"],
                        submission_list.filter((i) => i.dcfId === dcfId),
                        efstdlist,
                        subcat,
                        newObj2.standard,
                        dcfId === 10 ? "kg" : "USD",
                        newObj2.self,
                        newObj2
                      )
                    );
                  } else if (dcfId === 275 || dcfId === 263) {
                    console.log(xy);
                    msn.push(
                      getValueByMonthlyEmpCat275(
                        dcfId,
                        tier0_id,
                        tier1_id,
                        tier2_id,
                        tier3_id,
                        [xy],
                        submission_list.filter((i) => i.dcfId === dcfId),
                        "nos",
                        newObj2.self,
                        newObj2
                      )
                    );
                  } else if (dcfId === 284) {
                    console.log(xy);
                    msn.push(
                      getValueByMonthlyEmpNewTurn284(
                        dcfId,
                        tier0_id,
                        tier1_id,
                        tier2_id,
                        tier3_id,
                        [xy],
                        submission_list.filter((i) => i.dcfId === dcfId),
                        "nos",
                        newObj2.self,
                        newObj2
                      )
                    );
                  } else {
                    msn.push(
                      getValueByMonthlyHC(
                        dcfId,
                        tier0_id,
                        tier1_id,
                        tier2_id,
                        tier3_id,
                        [xy],
                        data1ForYear.map((i) => i.name),
                        submission_list.filter((i) => i.dcfId === dcfId),
                        newObj2.self,
                        newObj2
                      )
                    );
                  }
                } else {
                  msn.push(
                    getValueByMonthly(
                      dcfId,
                      tier0_id,
                      tier1_id,
                      tier2_id,
                      tier3_id,
                      [xy],
                      data1ForYear.map((i) => i.name),
                      submission_list.filter((i) => i.dcfId === dcfId),
                      newObj2.self,
                      newObj2
                    )
                  );
                }
              });
          });
          console.log(msn, item.dataobject);
          item.year.forEach(async (y) => {
            console.log(y);
            if (
              dcfId === 11 ||
              dcfId === 10 ||
              dcfId === 16 ||
              dcfId === 257 ||
              dcfId === 275 ||
              dcfId === 263 ||
              dcfId === 284
            ) {
              let msn_ = msn.filter((i) => {
                return isDateInFiscalYear(y, Object.keys(i)[0]);
              });
              let names_ = getDPNameHC(msn_);
              msn_.forEach((i) => {
                Object.values(i).forEach((j) => {
                  let oldObj = {};
                  let oldObjInd = j.findIndex((l) => {
                    return l.id !== null && l.id !== undefined;
                  });
                  if (oldObjInd !== -1) {
                    console.log(j[oldObjInd]);
                    oldObj = {
                      id: j[oldObjInd].id,
                      type: j[oldObjInd].type,
                      status: j[oldObjInd].status,
                      remarks:
                        j[oldObjInd].remarks === undefined
                          ? null
                          : j[oldObjInd].remarks,
                      self: j[oldObjInd].self,
                    };
                  }
                  names_.forEach((k) => {
                    let nameindex = j.findIndex((l) => l.dp === k.label);

                    if (nameindex === -1 && k.label !== null) {
                      j.push({
                        ...oldObj,
                        category: null,
                        diff: false,
                        form_type: 2,
                        current: "-",
                        dp: k.label,
                        order: k.order,
                      });
                    } else if (k.label !== null) {
                      j[nameindex].order = k.order;
                    }
                  });
                  j.sort((a, b) => {
                    return a.order - b.order;
                  }, 0);
                });
              });

              msn_.unshift({
                name: names_.filter((i) => {
                  return i.label !== null;
                }),
              });
              yearData.push({
                uid: uuidv4(),
                section: dcf_list.find((i) => i.id === dcfId).section,
                year: y,
                tier0_id,
                tier1_id,
                tier2_id,
                tier3_id,
                dcfId,
                form_type:
                  hardcoded.dcf.includes(dcfId.toString()) ||
                  hardcoded.dcf2.includes(dcfId.toString())
                    ? 2
                    : 1,
                status: await getStatusCode(
                  msn_.filter((i) => {
                    return (
                      Object.keys(i)[0] === "name" ||
                      isDateInFiscalYear(y, Object.keys(i)[0])
                    );
                  })
                ),
                dp_array: msn_.filter((i) => {
                  return (
                    Object.keys(i)[0] === "name" ||
                    isDateInFiscalYear(y, Object.keys(i)[0])
                  );
                }),
              });
            } else {
              yearData.push({
                uid: uuidv4(),
                year: y,
                section: dcf_list.find((i) => i.id === dcfId).section,
                tier0_id,
                tier1_id,
                tier2_id,
                tier3_id,
                dcfId,
                form_type:
                  hardcoded.dcf.includes(dcfId.toString()) ||
                  hardcoded.dcf2.includes(dcfId.toString())
                    ? 2
                    : 1,
                status: await getStatusCode(
                  msn.filter((i) => {
                    return (
                      Object.keys(i)[0] === "name" ||
                      isDateInFiscalYear(y, Object.keys(i)[0])
                    );
                  })
                ),
                dp_array: msn.filter((i) => {
                  return (
                    Object.keys(i)[0] === "name" ||
                    isDateInFiscalYear(y, Object.keys(i)[0])
                  );
                }),
              });
            }
          });
        }

        console.log(yearData);
      });

      resolve(yearData);
    });
  }

  function getKey(frequency, year) {
    const months = Array.from({ length: 12 }, (_, i) =>
      DateTime.local(year, i + 1, 1).toFormat("LLL")
    );
    const keys = [];
    console.log(months);
    switch (frequency) {
      case 1:
        months.forEach((month) => {
          const key = month + "-" + year;
          keys.push({ [key]: {} });
        });
        break;
      case 2:
        for (let i = 0; i < months.length; i += 2) {
          const startMonthIndex = i;
          const endMonthIndex = Math.min(
            startMonthIndex + 1,
            months.length - 1
          );
          const key =
            months[startMonthIndex] +
            "-" +
            year +
            " to " +
            months[endMonthIndex] +
            "-" +
            year;
          keys.push({ [key]: {} });
        }
        break;
      case 3:
        for (let i = 0; i < months.length; i += 3) {
          const startMonthIndex = i;
          const endMonthIndex = Math.min(
            startMonthIndex + 2,
            months.length - 1
          );
          const key =
            months[startMonthIndex] +
            "-" +
            year +
            " to " +
            months[endMonthIndex] +
            "-" +
            year;
          keys.push({ [key]: {} });
        }
        break;
      case 4:
        keys.push({
          [`${months[0]}-${year} to ${months[months.length - 1]}-${year}`]: {},
        });
        break;
      case 5:
        const midIndex = Math.ceil(months.length / 2);
        const firstHalf = months.slice(0, midIndex);
        const secondHalf = months.slice(midIndex);
        const key1 =
          firstHalf[0] +
          "-" +
          year +
          " to " +
          firstHalf[firstHalf.length - 1] +
          "-" +
          year;
        const key2 =
          secondHalf[0] +
          "-" +
          year +
          " to " +
          secondHalf[secondHalf.length - 1] +
          "-" +
          year;
        keys.push({ [key1]: {} }, { [key2]: {} });
        break;
      default:
        break;
    }

    // Create the final object
    const result = {};
    keys.forEach((keyObj) => {
      Object.assign(result, keyObj);
    });
    console.log(result);
    return result;
  }
  function getPreviousPeriod(frequency, key) {
    if (frequency === 1) {
      const [month, year] = key.split("-");
      const previousDate = DateTime.fromFormat(
        `${month}-1-${year}`,
        "LLL-d-yyyy"
      ).minus({ months: 1 });
      const previousMonth = previousDate.toFormat("LLL");
      const previousYear = previousDate.year;
      return previousMonth + "-" + previousYear;
    } else {
      const endMonth = key.split(" to ")[0].split("-")[0].trim();
      const endYear = key.split(" to ")[0].split("-")[1].trim();
      const previousDate = DateTime.fromFormat(
        `${endMonth}-1-${endYear}`,
        "LLL-d-yyyy"
      ).minus({ months: 1 });
      const previousMonth = previousDate.toFormat("LLL");
      const previousYear = previousDate.year;
      return previousMonth + "-" + previousYear;
    }
  }
  const getRPTextFormat = (item) => {
    if (item.length !== 0) {
      if (item.length >= 2) {
        const startDate = DateTime.fromFormat(item[0], "MM-yyyy").toFormat(
          "LLL-yyyy"
        );
        const endDate = DateTime.fromFormat(
          item[item.length - 1],
          "MM-yyyy"
        ).toFormat("LLL-yyyy");
        return `${startDate} to ${endDate}`;
      } else {
        return DateTime.fromFormat(item[0], "MM-yyyy").toFormat("LLL-yyyy");
      }
    }
  };
  function calculatePercentage(value1, value2) {
    if (
      (value1 === null || value1 === 0) &&
      (value2 === null || value2 === 0)
    ) {
      return "-";
    }
    if (value2 === 0) {
      return "100 %";
    }
    console.log(value1, value2);
    return parseInt(Math.abs(((value1 - value2) / value2) * 100)) + "%";
  }

  const getValueByMonthly = (
    dcfId,
    tier0,
    tier1,
    tier2,
    tier3,
    arr,
    dpnames,
    qn_submit_list,
    self,
    refobj
  ) => {
    // let months = ['Jan-' + yr, 'Feb-' + yr, 'Mar-' + yr, 'Apr-' + yr, 'May-' + yr, 'Jun-' + yr, 'Jul-' + yr, 'Aug-' + yr, 'Sep-' + yr, 'Oct-' + yr, 'Nov-' + yr, 'Dec-' + yr]
    let final_result = {};
    console.log(dpnames);
    arr.forEach((obj) => {
      console.log(obj);
      let result = {
        [obj]: dpnames.map((i) => ({
          ...refobj,
          remarks: null,
          category: null,
          form_type: 1,
          current: "",
          percentage: "",
          text: "Pending Submission",
          col: getNumberOfColumn(obj),
          status: null,
        })),
      };
      let result_arr = [];
      let index = qn_submit_list.findIndex((i) => {
        console.log(getRPTextFormat(i.reporting_period), obj);
        return (
          i.dcfId === dcfId &&
          i.tier0_id === tier0 &&
          i.tier1_id === tier1 &&
          i.tier2_id === tier2 &&
          i.tier3_id === tier3 &&
          getRPTextFormat(i.reporting_period) === obj
        );
      });
      if (dcfId === 203) {
        console.log(index);
      }

      if (index !== -1) {
        let index2 = qn_submit_list.findIndex((i) => {
          console.log(
            getPreviousPeriod(obj.includes("to") ? 2 : 1, obj),
            getRPTextFormat(i.reporting_period[i.reporting_period.length - 1]),
            obj
          );
          return (
            i.dcfId === dcfId &&
            (i.type == 0 ? i.reject === 1 : true) &&
            i.tier0_id === tier0 &&
            i.tier1_id === tier1 &&
            i.tier2_id === tier2 &&
            i.tier3_id === tier3 &&
            getRPTextFormat([
              i.reporting_period[i.reporting_period.length - 1],
            ]) === getPreviousPeriod(obj.includes("to") ? 2 : 1, obj)
          );
        });

        if (
          index2 !== -1 &&
          qn_submit_list[index2].response.length &&
          qn_submit_list[index].response.length
        ) {
          const { type1, reject1 } = qn_submit_list[index2];
          const { type, reject } = qn_submit_list[index];
          let value = null,
            value2 = null;
          result_arr = [];
          dpnames.forEach((dpname) => {
            let dp_index = qn_submit_list[index].response.findIndex(
              (item) => item.name === dpname
            );
            if (dp_index !== -1) {
              value =
                qn_submit_list[index].response[dp_index].value !== undefined &&
                qn_submit_list[index].response[dp_index].value !== null
                  ? parseFloat(qn_submit_list[index].response[dp_index].value)
                  : 0;
            } else {
              value = 0;
            }
            let dp_index2 = qn_submit_list[index2].response.findIndex(
              (item) => item.name === dpname
            );
            if (dp_index2 !== -1) {
              value2 =
                qn_submit_list[index2].response[dp_index2].value !==
                  undefined &&
                qn_submit_list[index2].response[dp_index2].value !== null
                  ? parseFloat(qn_submit_list[index2].response[dp_index2].value)
                  : 0;
            } else {
              value = 0;
            }

            result_arr.push({
              remarks: qn_submit_list[index].return_remarks,
              col: getNumberOfColumn(obj),
              id: qn_submit_list[index].id,
              category: 2,
              form_type: 1,
              diff: value === value2 ? null : value > value2 ? false : true,
              current: value,
              month: getRPTextFormat(qn_submit_list[index].reporting_period),
              percentage: calculatePercentage(value, value2),
              type,
              text:
                type === 0 && (reject === 0 || reject === null)
                  ? "Drafted"
                  : type === 0 && (reject === 1 || reject === 2)
                  ? "Requires Resubmission"
                  : type === 1 && reject === 1
                  ? "Requires Re-Review"
                  : type === 1
                  ? "Pending Review"
                  : type === 2
                  ? "Pending Approval"
                  : type === 3
                  ? "Approved & Locked"
                  : "Pending Submssion",
              status:
                type === 0 && (reject === 0 || reject === null)
                  ? 0
                  : type === 0 && (reject === 1 || reject === 2)
                  ? 1
                  : type === 1 && reject === 1
                  ? 2
                  : type === 1
                  ? 3
                  : type === 2
                  ? 4
                  : type === 3
                  ? 5
                  : null,
            });
          });
          result = { [obj]: attachObj(result_arr, refobj) };
        } else {
          const { type, reject } = qn_submit_list[index];
          let value = null;
          result_arr = [];

          dpnames.forEach((dpname) => {
            let dp_index = qn_submit_list[index].response.findIndex(
              (item) => item.name === dpname
            );
            if (dp_index !== -1) {
              value =
                qn_submit_list[index].response[dp_index].value !== undefined &&
                qn_submit_list[index].response[dp_index].value !== null
                  ? parseFloat(qn_submit_list[index].response[dp_index].value)
                  : "-";
            } else {
              value = "-";
            }
            result_arr.push({
              remarks: qn_submit_list[index].return_remarks,
              col: getNumberOfColumn(obj),
              id: qn_submit_list[index].id,
              category: 1,
              form_type: 1,
              diff: false,
              current: value,
              month: getRPTextFormat(qn_submit_list[index].reporting_period),
              percentage: "-",
              type,
              text:
                type === 0 && (reject === 0 || reject === null)
                  ? "Drafted"
                  : type === 0 && (reject === 1 || reject === 2)
                  ? "Requires Resubmission"
                  : type === 1 && reject === 1
                  ? "Requires Re-Review"
                  : type === 1
                  ? "Pending Review"
                  : type === 2
                  ? "Pending Approval"
                  : type === 3
                  ? "Approved & Locked"
                  : "Pending Submission",
              status:
                type === 0 && (reject === 0 || reject === null)
                  ? 0
                  : type === 0 && (reject === 1 || reject === 2)
                  ? 1
                  : type === 1 && reject === 1
                  ? 2
                  : type === 1
                  ? 3
                  : type === 2
                  ? 4
                  : type === 3
                  ? 5
                  : null,
            });
          });
          result = { [obj]: attachObj(result_arr, refobj) };
        }
      }
      final_result = { ...final_result, ...result };
    });
    console.log(final_result, arr);
    return final_result;
  };
  const getValueByMonthlyHC = (
    dcfId,
    tier0,
    tier1,
    tier2,
    tier3,
    arr,
    dpnames,
    qn_submit_list,
    self,
    refobj
  ) => {
    // let months = ['Jan-' + yr, 'Feb-' + yr, 'Mar-' + yr, 'Apr-' + yr, 'May-' + yr, 'Jun-' + yr, 'Jul-' + yr, 'Aug-' + yr, 'Sep-' + yr, 'Oct-' + yr, 'Nov-' + yr, 'Dec-' + yr]
    let final_result = {};
    console.log(dpnames);
    arr.forEach((obj) => {
      console.log(obj);
      let result = {
        [obj]: [
          {
            ...refobj,
            remarks: null,
            category: null,
            form_type: 3,
            current: "",
            percentage: "",
            text: "Pending Submission",
            col: getNumberOfColumn(obj),
            status: null,
          },
        ],
      };
      let result_arr = [];
      let index = qn_submit_list.findIndex((i) => {
        console.log(getRPTextFormat(i.reporting_period), obj);
        return (
          i.dcfId === dcfId &&
          i.tier0_id === tier0 &&
          i.tier1_id === tier1 &&
          i.tier2_id === tier2 &&
          i.tier3_id === tier3 &&
          getRPTextFormat(i.reporting_period) === obj
        );
      });

      if (index !== -1) {
        const { type, reject } = qn_submit_list[index];
        let value = null;
        result_arr = [];
        let list_arr = [
          {
            remarks: qn_submit_list[index].return_remarks,
            form_type: 3,
            col: getNumberOfColumn(obj),
            id: qn_submit_list[index].id,
            category: 1,
            diff: false,
            current: "",
            month: getRPTextFormat(qn_submit_list[index].reporting_period),
            percentage: "-",
            type,
            self,
            text:
              type === 0 && (reject === 0 || reject === null)
                ? "Drafted"
                : type === 0 && (reject === 1 || reject === 2)
                ? "Unlocked for Resubmission"
                : type === 1 && reject === 2
                ? "Unlocked for Review Again"
                : type === 1
                ? "Pending Review"
                : type === 2
                ? "Pending Approval"
                : type === 3
                ? "Approved & Locked"
                : "Pending Submission",
            status:
              type === 0 && (reject === 0 || reject === null)
                ? 0
                : type === 0 && (reject === 1 || reject === 2)
                ? 1
                : type === 1 && reject === 2
                ? 2
                : type === 1
                ? 3
                : type === 2
                ? 4
                : type === 3
                ? 5
                : null,
          },
        ];

        result = { [obj]: attachObj(list_arr, refobj) };
      }
      final_result = { ...final_result, ...result };
    });
    console.log(final_result, arr);
    return final_result;
  };
  const recall = async (dcfId, _id, index1, index2, year) => {
    let qnloc = JSON.parse(JSON.stringify(quantitativesubmission));
    let qnindex = qnloc.findIndex((i) => i.id === _id);
    let refbk = JSON.parse(JSON.stringify(refineddatabk));
    let ref = JSON.parse(JSON.stringify(refineddata));
    const { uid } = ref[index1];
    let refbkind = refbk.findIndex((i) => i.uid === uid);
    if (qnindex !== -1 && refbkind !== -1) {
      setTempLoad(true);
      const { value: accept } = await Swal.fire({
        title: `<div style="overflow:visible;font-size:20px;font-weight:600;margin-top:0px">Recall DCF Submission</div>`,
        html: `<div style="overflow:auto;max-height:200px" >Are you sure want to recall this review</div>`,

        confirmButtonColor: "red",
        showCancelButton: true,

        confirmButtonText: "Revoke submission",
      });
      if (accept) {
        let newObj = {
          type: 0,
          last_modified_on: DateTime.utc(),
          last_modified_by: login_data.id,
        };
        APIServices.patch(API.QN_Submission_Edit(_id), newObj)
          .then(async (res) => {
            qnloc[qnindex] = { ...qnloc[qnindex], ...newObj };
            setQuantiativeSubmission(qnloc);
            try {
              Object.values(ref[index1].dp_array[index2]).forEach((i) => {
                for (let j = 0; j < i.length; i++) {
                  i[0].status = 0;
                  i[0].type = 0;
                }
              });
              ref[index1].status = await getStatusCode(ref[index1].dp_array);
              refbk[refbkind].status = await getStatusCode(
                ref[index1].dp_array
              );
              refbk[refbkind].dp_array[index2] = ref[index1].dp_array[index2];
              console.log(ref);

              setRefinedData(ref);
              setRefinedDataBk(refbk);
              setTempLoad(false);
            } catch (e) {
              console.log(e);
              setTempLoad(true);
            }
            // calculateAndCreateYearData(assignedlist, dcflist, qnloc, stdlist, { cat1: subcat1, cat2: subcat2, cat3: subcat3, cat4: subcat4 }).then((res) => {
            //     setRefinedDataBk(res)
            //     let expanded_indexs = refineddata.map((i, j) => (i.expanded === true) ? j : null).filter(i => i !== null).map((i) => i)
            //     console.log(expanded_indexs)
            //     let filteredData = res.filter((i) => { return (i.year === filter.year || filter.year === null) && (filter.entity.includes(getCoverageTextById(i, rawsitelist)) || filter.entity.length === 0) && (filter.form.includes(i.dcfId) || filter.form.length === 0) && (filter.status.includes(i.status) || filter.status.length === 0) })
            //     filteredData.forEach((i, j) => {
            //         if (expanded_indexs.includes(j)) {
            //             i.expanded = true
            //         }
            //     })
            //     setRefinedData(filteredData);
            //     setTempLoad(false)
            // }

            // )
          })
          .catch((e) => {
            setTempLoad(false);
          });
      } else {
        setTempLoad(false);
      }
    }
  };
  const recallReview = async (dcfId, _id, index1, index2, year) => {
    let qnloc = JSON.parse(JSON.stringify(quantitativesubmission));
    let qnindex = qnloc.findIndex((i) => i.id === _id);
    let refbk = JSON.parse(JSON.stringify(refineddatabk));
    let ref = JSON.parse(JSON.stringify(refineddata));
    const { uid } = ref[index1];
    let refbkind = refbk.findIndex((i) => i.uid === uid);
    if (qnindex !== -1 && refbkind !== -1) {
      setTempLoad(true);
      const { value: accept } = await Swal.fire({
        title: `<div style="overflow:visible;font-size:20px;font-weight:600;margin-top:0px">Recall DCF Review</div>`,
        html: `<div style="overflow:auto;max-height:200px" >Are you sure want to recall this review</div>`,

        confirmButtonColor: "red",
        showCancelButton: true,

        confirmButtonText: "Revoke Submission",
      });
      if (accept) {
        let newObj = {
          type: 1,
          last_modified_on: DateTime.utc(),
          last_modified_by: login_data.id,
        };
        APIServices.patch(API.QN_Submission_Edit(_id), newObj)
          .then(async (res) => {
            qnloc[qnindex] = { ...qnloc[qnindex], ...newObj };
            setQuantiativeSubmission(qnloc);
            try {
              Object.values(ref[index1].dp_array[index2]).forEach((i) => {
                for (let j = 0; j < i.length; i++) {
                  i[0].status = 3;
                  i[0].type = 1;
                }
              });
              ref[index1].status = await getStatusCode(ref[index1].dp_array);
              refbk[refbkind].status = await getStatusCode(
                ref[index1].dp_array
              );
              refbk[refbkind].dp_array[index2] = ref[index1].dp_array[index2];
              console.log(ref);

              setRefinedData(ref);
              setRefinedDataBk(refbk);
              setTempLoad(false);
            } catch (e) {
              console.log(e);
              setTempLoad(true);
            }
          })
          .catch((e) => {
            setTempLoad(false);
          });
      } else {
        setTempLoad(false);
      }
    }
  };
  const recall_bk = async (dcfId, _id, index1, index2, year) => {
    let qnloc = JSON.parse(JSON.stringify(quantitativesubmission));
    let qnindex = qnloc.findIndex((i) => i.id === _id);
    if (qnindex !== -1) {
      setTempLoad(true);
      const { value: accept } = await Swal.fire({
        title: `<div style="overflow:visible;font-size:20px;font-weight:600;margin-top:0px">Recall DCF Submission</div>`,
        html: `<div style="overflow:auto;max-height:200px" >Are you sure want to recall this submission</div>`,

        confirmButtonColor: "red",
        showCancelButton: true,

        confirmButtonText: "Revoke submission",
      });
      if (accept) {
        let newObj = {
          type: 0,
          last_modified_on: DateTime.utc(),
          last_modified_by: login_data.id,
        };
        APIServices.patch(API.QN_Submission_Edit(_id), newObj)
          .then(async (res) => {
            qnloc[qnindex] = { ...qnloc[qnindex], ...newObj };
            setQuantiativeSubmission(qnloc);
            calculateAndCreateYearData(assignedlist, dcflist, qnloc, stdlist, {
              cat1: subcat1,
              cat2: subcat2,
              cat3: subcat3,
              cat4: subcat4,
            }).then((res) => {
              setRefinedDataBk(res);
              let expanded_indexs = refineddata
                .map((i, j) => (i.expanded === true ? j : null))
                .filter((i) => i !== null)
                .map((i) => i);
              console.log(expanded_indexs);
              let filteredData = res.filter((i) => {
                return (
                  (i.year === filter.year || filter.year === null) &&
                  (filter.entity.includes(
                    getCoverageTextById(i, rawsitelist)
                  ) ||
                    filter.entity.length === 0) &&
                  (filter.form.includes(i.dcfId) || filter.form.length === 0) &&
                  (i.status.some((status) => filter.status.includes(status)) ||
                    filter.status.length === 0)
                );
              });
              filteredData.forEach((i, j) => {
                if (expanded_indexs.includes(j)) {
                  i.expanded = true;
                }
              });
              setRefinedData(filteredData);
              setTempLoad(false);
            });
          })
          .catch((e) => {
            setTempLoad(false);
          });
      } else {
        setTempLoad(false);
      }
    }
  };
  const reviewAction = async (dcfId, _id, index1, index2, year, rp_name) => {
    let qnloc = JSON.parse(JSON.stringify(quantitativesubmission));
    let refbk = JSON.parse(JSON.stringify(refineddatabk));
    let ref = JSON.parse(JSON.stringify(refineddata));
    const { uid } = ref[index1];
    let refbkind = refbk.findIndex((i) => i.uid === uid);
    let qnindex = qnloc.findIndex((i) => i.id === _id);

    console.log(ref[index1].dp_array[index2]);

    let dt = DateTime.utc();
    if (qnindex !== -1 && refbkind !== -1) {
      setTempLoad(true);
      Swal.fire({
        title: "Review data for  " + rp_name,
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Quick Review",
        confirmButtonColor: "#5B8FF7",
        denyButtonText: `Check & Review`,
        denyButtonColor: "green",
      }).then(async (result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          const { value: accept } = await Swal.fire({
            html: `<div >Do you wish to review this without opening the detailed submission ?</div>`,

            confirmButtonColor: "green",
            showCancelButton: true,

            confirmButtonText: "Yes, Review",
          });

          if (accept) {
            let newObj = {
              type: 2,
              reject: 0,
              reviewer_modified_on: DateTime.utc(),
              reviewer_modified_by: login_data.id,
              last_modified_on: DateTime.utc(),
              last_modified_by: login_data.id,
            };
            APIServices.patch(API.QN_Submission_Edit(_id), newObj)
              .then(async (res) => {
                qnloc[qnindex] = { ...qnloc[qnindex], ...newObj };
                setQuantiativeSubmission(qnloc);
                try {
                  Object.values(ref[index1].dp_array[index2]).forEach((i) => {
                    for (let j = 0; j < i.length; i++) {
                      i[0].status = 4;
                      i[0].type = 2;
                    }
                  });
                  ref[index1].status = await getStatusCode(
                    ref[index1].dp_array
                  );
                  refbk[refbkind].status = await getStatusCode(
                    ref[index1].dp_array
                  );
                  refbk[refbkind].dp_array[index2] =
                    ref[index1].dp_array[index2];
                  console.log(ref);

                  setRefinedData(ref);
                  setRefinedDataBk(refbk);
                  setTempLoad(false);
                } catch (e) {
                  console.log(e);
                  setTempLoad(true);
                }
                // calculateAndCreateYearData(assignedlist, dcflist, qnloc, stdlist, { cat1: subcat1, cat2: subcat2, cat3: subcat3, cat4: subcat4 }).then((res) => {
                //     setRefinedDataBk(res)
                //     let expanded_indexs = refineddata.map((i, j) => (i.expanded === true) ? j : null).filter(i => i !== null).map((i) => i)
                //     console.log(expanded_indexs)
                //     let filteredData = res.filter((i) => { return (i.year === filter.year || filter.year === null) && (filter.entity.includes(getCoverageTextById(i, rawsitelist)) || filter.entity.length === 0) && (filter.form.includes(i.dcfId) || filter.form.length === 0) && (filter.status.includes(i.status) || filter.status.length === 0) })
                //     filteredData.forEach((i, j) => {
                //         if (expanded_indexs.includes(j)) {
                //             i.expanded = true
                //         }
                //     })
                //     setRefinedData(filteredData);
                //     setTempLoad(false)
                // }

                // )
              })
              .catch((e) => {
                setTempLoad(false);
              });
          } else {
            setTempLoad(false);
          }
        } else if (result.isDenied) {
          setTempLoad(false);
          window.open(
            window.location.origin + "/data_input_reviewer/" + dcfId + "/" + _id
          );
        } else {
          setTempLoad(false);
        }
      });
    }
  };

  const getValueByMonthlyHC3Sub = (
    dcfId,
    tier0,
    tier1,
    tier2,
    tier3,
    arr,
    dpnames,
    qn_submit_list,
    efstdlist,
    subcat,
    standardId,
    categoryId,
    self,
    refobj
  ) => {
    // let months = ['Jan-' + yr, 'Feb-' + yr, 'Mar-' + yr, 'Apr-' + yr, 'May-' + yr, 'Jun-' + yr, 'Jul-' + yr, 'Aug-' + yr, 'Sep-' + yr, 'Oct-' + yr, 'Nov-' + yr, 'Dec-' + yr]

    let stdindx = efstdlist.findIndex((i) => i.id === standardId);

    const [a, b, x] = dpnames;

    let final_result = {};
    if (stdindx !== -1) {
      arr.forEach((obj) => {
        console.log(obj);
        let result = {
          [obj]: [refobj],
        };
        let result_arr = [];
        let index = qn_submit_list.findIndex((i) => {
          console.log(getRPTextFormat(i.reporting_period), obj);
          return (
            i.dcfId === dcfId &&
            i.standard === standardId &&
            i.tier0_id === tier0 &&
            i.tier1_id === tier1 &&
            i.tier2_id === tier2 &&
            i.tier3_id === tier3 &&
            getRPTextFormat(i.reporting_period) === obj
          );
        });
        console.log(index);
        if (index !== -1) {
          let index2 = qn_submit_list.findIndex((i) => {
            console.log(
              getPreviousPeriod(obj.includes("to") ? 2 : 1, obj),
              getRPTextFormat(
                i.reporting_period[i.reporting_period.length - 1]
              ),
              obj
            );
            return (
              i.dcfId === dcfId &&
              i.standard === standardId &&
              (i.type == 0
                ? i.reject === 1 || i.reject === 2
                  ? true
                  : false
                : true) &&
              i.tier0_id === tier0 &&
              i.tier1_id === tier1 &&
              i.tier2_id === tier2 &&
              i.tier3_id === tier3 &&
              getRPTextFormat([
                i.reporting_period[i.reporting_period.length - 1],
              ]) === getPreviousPeriod(obj.includes("to") ? 2 : 1, obj)
            );
          });
          console.log(index2, obj);
          if (
            index2 !== -1 &&
            qn_submit_list[index2].response.length &&
            qn_submit_list[index].response.length
          ) {
            const { type1, reject1 } = qn_submit_list[index2];
            const { type, reject } = qn_submit_list[index];
            let value = null,
              value2 = null;
            result_arr = [];
            let list_arr = [];
            let list_arr2 = [];
            qn_submit_list[index2].response.forEach((i) => {
              let ind = list_arr2.findIndex(
                (j) => j[a] === i[a] && j[b] === i[b]
              );
              if (ind !== -1) {
                list_arr2[ind][x] += i[x];
              } else {
                list_arr2.push(i);
              }
            });
            qn_submit_list[index].response.forEach((i) => {
              let ind = list_arr.findIndex(
                (j) => j[a] === i[a] && j[b] === i[b]
              );
              if (ind !== -1) {
                list_arr[ind][x] += i[x];
                list_arr[ind].current = list_arr[ind][x];
                let value = list_arr[ind][x],
                  value2 = null;
                let oldIndex = list_arr2.findIndex(
                  (j) => j[a] === i[a] && j[b] === i[b]
                );
                if (oldIndex !== -1) {
                  value2 = list_arr2[oldIndex][x];
                  list_arr[ind].percentage = calculatePercentage(value, value2);
                  list_arr[ind].diff =
                    value === value2 ? null : value > value2 ? false : true;
                  list_arr[ind].category = 2;
                }
              } else {
                let value = i[x],
                  value2 = null;
                let oldIndex = list_arr2.findIndex(
                  (j) => j[a] === i[a] && j[b] === i[b]
                );
                if (oldIndex !== -1) {
                  value2 = list_arr2[oldIndex][x];
                  list_arr.push({
                    ...i,
                    remarks: qn_submit_list[index].return_remarks,
                    col: getNumberOfColumn(obj),
                    id: qn_submit_list[index].id,
                    category: 2,
                    form_type: 2,
                    diff:
                      value === value2 ? null : value > value2 ? false : true,
                    current: i[x],
                    month: getRPTextFormat(
                      qn_submit_list[index].reporting_period
                    ),
                    percentage: calculatePercentage(value, value2),
                    type,
                    self,
                    text:
                      type === 0 && (reject === 0 || reject === null)
                        ? "Drafted"
                        : type === 0 && (reject === 1 || reject === 2)
                        ? "Unlocked for Resubmission"
                        : type === 1 && reject === 2
                        ? "Unlocked for Review Again"
                        : type === 1
                        ? "Pending Review"
                        : type === 2
                        ? "Pending Approval"
                        : type === 3
                        ? "Approved & Locked"
                        : "Pending Submission",
                    status:
                      type === 0 && (reject === 0 || reject === null)
                        ? 0
                        : type === 0 && (reject === 1 || reject === 2)
                        ? 1
                        : type === 1 && reject === 2
                        ? 2
                        : type === 1
                        ? 3
                        : type === 2
                        ? 4
                        : type === 3
                        ? 5
                        : null,
                  });
                } else {
                  list_arr.push({
                    ...i,
                    remarks: qn_submit_list[index].return_remarks,
                    col: getNumberOfColumn(obj),
                    id: qn_submit_list[index].id,
                    category: 1,
                    form_type: 2,
                    diff: false,
                    current: i[x],
                    month: getRPTextFormat(
                      qn_submit_list[index].reporting_period
                    ),
                    percentage: "-",
                    type,
                    self,
                    text:
                      type === 0 && (reject === 0 || reject === null)
                        ? "Drafted"
                        : type === 0 && (reject === 1 || reject === 2)
                        ? "Unlocked for Resubmission"
                        : type === 1 && reject === 2
                        ? "Unlocked for Review Again"
                        : type === 1
                        ? "Pending Review"
                        : type === 2
                        ? "Pending Approval"
                        : type === 3
                        ? "Approved & Locked"
                        : "Pending Submission",
                    status:
                      type === 0 && (reject === 0 || reject === null)
                        ? 0
                        : type === 0 && (reject === 1 || reject === 2)
                        ? 1
                        : type === 1 && reject === 2
                        ? 2
                        : type === 1
                        ? 3
                        : type === 2
                        ? 4
                        : type === 3
                        ? 5
                        : null,
                  });
                }
              }
            });
            console.log(qn_submit_list[index], qn_submit_list[index2]);
            result = { [obj]: attachObj(list_arr, refobj) };
          } else {
            const { type, reject } = qn_submit_list[index];
            let value = null;
            result_arr = [];
            let list_arr = [];
            if (qn_submit_list[index].response.length) {
              qn_submit_list[index].response.forEach((i) => {
                let ind = list_arr.findIndex(
                  (j) => j[a] === i[a] && j[b] === i[b]
                );
                if (ind !== -1) {
                  list_arr[ind][x] += i[x];
                  list_arr[ind].current = list_arr[ind][x];
                } else {
                  list_arr.push({
                    ...i,
                    remarks: qn_submit_list[index].return_remarks,
                    col: getNumberOfColumn(obj),
                    id: qn_submit_list[index].id,
                    form_type: 2,
                    category: 1,
                    diff: false,
                    current: i[x],
                    month: getRPTextFormat(
                      qn_submit_list[index].reporting_period
                    ),
                    percentage: "-",
                    type,
                    self,
                    text:
                      type === 0 && (reject === 0 || reject === null)
                        ? "Drafted"
                        : type === 0 && (reject === 1 || reject === 2)
                        ? "Unlocked for Resubmission"
                        : type === 1 && reject === 2
                        ? "Unlocked for Review Again"
                        : type === 1
                        ? "Pending Review"
                        : type === 2
                        ? "Pending Approval"
                        : type === 3
                        ? "Approved & Locked"
                        : "Pending Submission",
                    status:
                      type === 0 && (reject === 0 || reject === null)
                        ? 0
                        : type === 0 && (reject === 1 || reject === 2)
                        ? 1
                        : type === 1 && reject === 2
                        ? 2
                        : type === 1
                        ? 3
                        : type === 2
                        ? 4
                        : type === 3
                        ? 5
                        : null,
                  });
                }
              });
            } else {
              list_arr.push({
                edit: 1,
                remarks: qn_submit_list[index].return_remarks,
                col: getNumberOfColumn(obj),
                id: qn_submit_list[index].id,
                form_type: 2,
                category: 1,
                diff: false,
                current: "-",
                month: getRPTextFormat(qn_submit_list[index].reporting_period),
                percentage: "-",
                type,
                self,
                text:
                  type === 0 && (reject === 0 || reject === null)
                    ? "Drafted"
                    : type === 0 && (reject === 1 || reject === 2)
                    ? "Unlocked for Resubmission"
                    : type === 1 && reject === 2
                    ? "Unlocked for Review Again"
                    : type === 1
                    ? "Pending Review"
                    : type === 2
                    ? "Pending Approval"
                    : type === 3
                    ? "Approved & Locked"
                    : "Pending Submission",
                status:
                  type === 0 && (reject === 0 || reject === null)
                    ? 0
                    : type === 0 && (reject === 1 || reject === 2)
                    ? 1
                    : type === 1 && reject === 2
                    ? 2
                    : type === 1
                    ? 3
                    : type === 2
                    ? 4
                    : type === 3
                    ? 5
                    : null,
              });
            }

            result = { [obj]: attachObj(list_arr, refobj) };
          }
        }

        final_result = { ...final_result, ...result };
      });
    } else {
      arr.forEach((obj) => {
        let result = {
          [obj]: [],
        };
        final_result = { ...final_result, ...result };
      });
    }
    Object.values(final_result).forEach((j) => {
      let find = Object.values(j).filter(
        (i) => i[a] !== undefined && i[b] !== undefined
      );
      console.log(find, j);
      Object.values(j).forEach((k) => {
        if (k.category !== null && find.length) {
          let name1 = subcat["cat2"].find((i) => i.id === k[a]);
          let name2 = subcat["cat3"].find((i) => i.id === k[b]);
          k.dp =
            (name1 === undefined ? "NA" : name1.title) +
            "^" +
            (name2 === undefined ? "NA" : name2.title);
        } else {
          k.dp = null;
        }
      });
    });
    return final_result;
  };

  function getOverdueDays(monthString) {
    console.log(monthString);
    const [startMonth, endMonth] = monthString.split(" to ");

    const month = endMonth ? endMonth : startMonth;
    const [monthValue, year] = month.split("-");
    const endOfMonth = DateTime.fromObject({
      year: parseInt(year),
      month: DateTime.fromFormat(monthValue, "LLL").month,
    }).endOf("month");
    const currentDate = DateTime.local();
    console.log(month, endOfMonth.diff(currentDate, "days").days);
    return endOfMonth.diff(currentDate, "days").days;
  }
  const attachObj = (arr, refobj) => {
    for (let i = 0; i < arr.length; i++) {
      arr[i] = { ...arr[i], refobj: refobj.refobj };
    }
    return arr;
  };
  const getValueByMonthlyHC1Sub = (
    dcfId,
    tier0,
    tier1,
    tier2,
    tier3,
    arr,
    dpnames,
    qn_submit_list,
    efstdlist,
    subcat,
    standardId,
    unit,
    self,
    refobj
  ) => {
    // let months = ['Jan-' + yr, 'Feb-' + yr, 'Mar-' + yr, 'Apr-' + yr, 'May-' + yr, 'Jun-' + yr, 'Jul-' + yr, 'Aug-' + yr, 'Sep-' + yr, 'Oct-' + yr, 'Nov-' + yr, 'Dec-' + yr]

    let stdindx = efstdlist.findIndex((i) => i.id === standardId);

    const [a, x] = dpnames;

    let final_result = {};
    if (stdindx !== -1) {
      arr.forEach((obj) => {
        console.log(obj);
        let result = {
          [obj]: [refobj],
        };
        let result_arr = [];
        let index = qn_submit_list.findIndex((i) => {
          console.log(getRPTextFormat(i.reporting_period), obj);
          return (
            i.dcfId === dcfId &&
            i.standard === standardId &&
            i.tier0_id === tier0 &&
            i.tier1_id === tier1 &&
            i.tier2_id === tier2 &&
            i.tier3_id === tier3 &&
            getRPTextFormat(i.reporting_period) === obj
          );
        });
        console.log(index);
        if (index !== -1) {
          let index2 = qn_submit_list.findIndex((i) => {
            console.log(
              getPreviousPeriod(obj.includes("to") ? 2 : 1, obj),
              getRPTextFormat(
                i.reporting_period[i.reporting_period.length - 1]
              ),
              obj
            );
            return (
              i.dcfId === dcfId &&
              i.standard === standardId &&
              (i.type == 0
                ? i.reject === 1 || i.reject === 2
                  ? true
                  : false
                : true) &&
              i.tier0_id === tier0 &&
              i.tier1_id === tier1 &&
              i.tier2_id === tier2 &&
              i.tier3_id === tier3 &&
              getRPTextFormat([
                i.reporting_period[i.reporting_period.length - 1],
              ]) === getPreviousPeriod(obj.includes("to") ? 2 : 1, obj)
            );
          });
          console.log(index2, obj);
          if (
            index2 !== -1 &&
            qn_submit_list[index2].response.length &&
            qn_submit_list[index].response.length
          ) {
            const { type1, reject1 } = qn_submit_list[index2];
            const { type, reject } = qn_submit_list[index];
            let value = null,
              value2 = null;
            result_arr = [];
            let list_arr = [];
            let list_arr2 = [];
            qn_submit_list[index2].response.forEach((i) => {
              let ind = list_arr2.findIndex((j) => j[a] === i[a]);
              if (ind !== -1) {
                list_arr2[ind][x] += i[x];
              } else {
                list_arr2.push(i);
              }
            });
            qn_submit_list[index].response.forEach((i) => {
              let ind = list_arr.findIndex((j) => j[a] === i[a]);
              if (ind !== -1) {
                list_arr[ind][x] += i[x];
                list_arr[ind].current = list_arr[ind][x];
                let value = list_arr[ind][x],
                  value2 = null;
                let oldIndex = list_arr2.findIndex((j) => j[a] === i[a]);
                if (oldIndex !== -1) {
                  value2 = list_arr2[oldIndex][x];
                  list_arr[ind].percentage = calculatePercentage(value, value2);
                  list_arr[ind].diff =
                    value === value2 ? null : value > value2 ? false : true;
                  list_arr[ind].category = 2;
                }
              } else {
                let value = i[x],
                  value2 = null;
                let oldIndex = list_arr2.findIndex((j) => j[a] === i[a]);
                if (oldIndex !== -1) {
                  value2 = list_arr2[oldIndex][x];
                  list_arr.push({
                    ...i,
                    remarks: qn_submit_list[index].return_remarks,
                    col: getNumberOfColumn(obj),
                    id: qn_submit_list[index].id,
                    category: 2,
                    form_type: 2,
                    diff:
                      value === value2 ? null : value > value2 ? false : true,
                    current: i[x],
                    month: getRPTextFormat(
                      qn_submit_list[index].reporting_period
                    ),
                    percentage: calculatePercentage(value, value2),
                    type,
                    self,
                    text:
                      type === 0 && (reject === 0 || reject === null)
                        ? "Drafted"
                        : type === 0 && (reject === 1 || reject === 2)
                        ? "Unlocked for Resubmission"
                        : type === 1 && reject === 2
                        ? "Unlocked for Review Again"
                        : type === 1
                        ? "Pending Review"
                        : type === 2
                        ? "Pending Approval"
                        : type === 3
                        ? "Approved & Locked"
                        : "Pending Submission",
                    status:
                      type === 0 && (reject === 0 || reject === null)
                        ? 0
                        : type === 0 && (reject === 1 || reject === 2)
                        ? 1
                        : type === 1 && reject === 2
                        ? 2
                        : type === 1
                        ? 3
                        : type === 2
                        ? 4
                        : type === 3
                        ? 5
                        : null,
                  });
                } else {
                  list_arr.push({
                    ...i,
                    remarks: qn_submit_list[index].return_remarks,
                    col: getNumberOfColumn(obj),
                    id: qn_submit_list[index].id,
                    category: 1,
                    form_type: 2,
                    diff: false,
                    self,
                    current: i[x],
                    month: getRPTextFormat(
                      qn_submit_list[index].reporting_period
                    ),
                    percentage: "-",
                    type,
                    text:
                      type === 0 && (reject === 0 || reject === null)
                        ? "Drafted"
                        : type === 0 && (reject === 1 || reject === 2)
                        ? "Unlocked for Resubmission"
                        : type === 1 && reject === 2
                        ? "Unlocked for Review Again"
                        : type === 1
                        ? "Pending Review"
                        : type === 2
                        ? "Pending Approval"
                        : type === 3
                        ? "Approved & Locked"
                        : "Pending Submission",
                    status:
                      type === 0 && (reject === 0 || reject === null)
                        ? 0
                        : type === 0 && (reject === 1 || reject === 2)
                        ? 1
                        : type === 1 && reject === 2
                        ? 2
                        : type === 1
                        ? 3
                        : type === 2
                        ? 4
                        : type === 3
                        ? 5
                        : null,
                  });
                }
              }
            });
            console.log(qn_submit_list[index], qn_submit_list[index2]);
            result = { [obj]: attachObj(list_arr, refobj) };
          } else {
            const { type, reject } = qn_submit_list[index];
            let value = null;
            result_arr = [];
            let list_arr = [];
            if (qn_submit_list[index].response) {
              qn_submit_list[index].response.forEach((i) => {
                let ind = list_arr.findIndex((j) => j[a] === i[a]);
                if (ind !== -1) {
                  list_arr[ind][x] += i[x];
                  list_arr[ind].current = list_arr[ind][x];
                } else {
                  list_arr.push({
                    ...i,
                    remarks: qn_submit_list[index].return_remarks,
                    col: getNumberOfColumn(obj),
                    id: qn_submit_list[index].id,
                    form_type: 2,
                    category: 1,
                    diff: false,
                    current: i[x],
                    self,
                    month: getRPTextFormat(
                      qn_submit_list[index].reporting_period
                    ),
                    percentage: "-",
                    type,
                    text:
                      type === 0 && (reject === 0 || reject === null)
                        ? "Drafted"
                        : type === 0 && (reject === 1 || reject === 2)
                        ? "Unlocked for Resubmission"
                        : type === 1 && reject === 2
                        ? "Unlocked for Review Again"
                        : type === 1
                        ? "Pending Review"
                        : type === 2
                        ? "Pending Approval"
                        : type === 3
                        ? "Approved & Locked"
                        : "Pending Submission",
                    status:
                      type === 0 && (reject === 0 || reject === null)
                        ? 0
                        : type === 0 && (reject === 1 || reject === 2)
                        ? 1
                        : type === 1 && reject === 2
                        ? 2
                        : type === 1
                        ? 3
                        : type === 2
                        ? 4
                        : type === 3
                        ? 5
                        : null,
                  });
                }
              });
            } else {
              list_arr.push({
                edit: 1,
                remarks: qn_submit_list[index].return_remarks,
                col: getNumberOfColumn(obj),
                id: qn_submit_list[index].id,
                form_type: 2,
                category: 1,
                diff: false,
                self,
                current: "-",
                month: getRPTextFormat(qn_submit_list[index].reporting_period),
                percentage: "-",
                type,
                text:
                  type === 0 && (reject === 0 || reject === null)
                    ? "Drafted"
                    : type === 0 && (reject === 1 || reject === 2)
                    ? "Unlocked for Resubmission"
                    : type === 1 && reject === 2
                    ? "Unlocked for Review Again"
                    : type === 1
                    ? "Pending Review"
                    : type === 2
                    ? "Pending Approval"
                    : type === 3
                    ? "Approved & Locked"
                    : "Pending Submission",
                status:
                  type === 0 && (reject === 0 || reject === null)
                    ? 0
                    : type === 0 && (reject === 1 || reject === 2)
                    ? 1
                    : type === 1 && reject === 2
                    ? 2
                    : type === 1
                    ? 3
                    : type === 2
                    ? 4
                    : type === 3
                    ? 5
                    : null,
              });
            }

            result = { [obj]: attachObj(list_arr, refobj) };
          }
        }

        final_result = { ...final_result, ...result };
      });
    } else {
      arr.forEach((obj) => {
        let result = {
          [obj]: [],
        };
        final_result = { ...final_result, ...result };
      });
    }
    Object.values(final_result).forEach((j) => {
      let find = Object.values(j).filter((i) => i[a] !== undefined);
      Object.values(j).forEach((k) => {
        if (k.category !== null && find.length) {
          let name1 = subcat["cat1"].find((i) => i.id === k[a]);

          k.dp = name1 === undefined ? "NA" : name1.title + "^" + unit;
        } else {
          k.dp = null;
        }
      });
    });
    return final_result;
  };
  const getValueByMonthlyEmpCat275 = (
    dcfId,
    tier0,
    tier1,
    tier2,
    tier3,
    arr,
    qn_submit_list,
    unit,
    self,
    refobj
  ) => {
    // let months = ['Jan-' + yr, 'Feb-' + yr, 'Mar-' + yr, 'Apr-' + yr, 'May-' + yr, 'Jun-' + yr, 'Jul-' + yr, 'Aug-' + yr, 'Sep-' + yr, 'Oct-' + yr, 'Nov-' + yr, 'Dec-' + yr]
    let land_veh_list = [
      "Standard Taxi / Sedan",
      "Hybrid Vehicle",
      "Executive Taxi",
      "Dual Purpose 4 x 4 (SUV)",
      "Electric Vehicle (EV)",
      "Van/ Coach",
    ];
    const value_arr = ["a1", "b1", "c1", "d1", "e1", "f1"];
    let final_result = {};

    console.log(tier0, tier1, tier2, tier3);
    arr.forEach((obj) => {
      console.log(obj);
      let result = {
        [obj]: [refobj],
      };
      let result_arr = [];
      let index = qn_submit_list.findIndex((i) => {
        console.log(
          getRPTextFormat(i.reporting_period) === obj,
          dcfId === i.dcfId,
          i.tier0_id === tier0,
          i.tier1_id === tier1,
          i.tier2_id === tier2,
          i.tier3_id === tier3,
          i.id,
          i.tier3_id,
          tier3
        );
        return (
          i.dcfId === dcfId &&
          i.tier0_id === tier0 &&
          i.tier1_id === tier1 &&
          i.tier2_id === tier2 &&
          i.tier3_id === tier3 &&
          getRPTextFormat(i.reporting_period) === obj
        );
      });
      console.log(index, obj);
      if (index !== -1) {
        let index2 = qn_submit_list.findIndex((i) => {
          console.log(
            getPreviousPeriod(obj.includes("to") ? 2 : 1, obj),
            getRPTextFormat(i.reporting_period[i.reporting_period.length - 1]),
            obj
          );
          return (
            i.dcfId === dcfId &&
            (i.type == 0
              ? i.reject === 1 || i.reject === 2
                ? true
                : false
              : true) &&
            i.tier0_id === tier0 &&
            i.tier1_id === tier1 &&
            i.tier2_id === tier2 &&
            i.tier3_id === tier3 &&
            getRPTextFormat([
              i.reporting_period[i.reporting_period.length - 1],
            ]) === getPreviousPeriod(obj.includes("to") ? 2 : 1, obj)
          );
        });
        console.log(index2, obj);
        if (
          index2 !== -1 &&
          qn_submit_list[index2].response.length &&
          qn_submit_list[index].response.length
        ) {
          const { type1, reject1 } = qn_submit_list[index2];
          const { type, reject } = qn_submit_list[index];
          let value = null,
            value2 = null;
          result_arr = [];
          let list_arr = [];
          let list_arr2 = [];
          qn_submit_list[index2].response.forEach((i) => {
            let abc = {};

            abc["a1"] = i["DPAN613"];
            abc["b1"] = i["DPAN614"];
            abc["c1"] = i["DPAN615"];
            abc["d1"] = i["DPAN616"];
            abc["e1"] = i["DPAN617"];
            abc["f1"] = i["DPAN618"];
            value_arr.forEach((x) => {
              list_arr2.push({
                ...i,
                [x]: abc[x],
                remarks: qn_submit_list[index].return_remarks,
                col: getNumberOfColumn(obj),
                id: qn_submit_list[index].id,
                category: 1,
                form_type: 2,
                diff: false,
                current: abc[x],
                month: getRPTextFormat(qn_submit_list[index].reporting_period),
                percentage: "-",
                type,
                self,
                text:
                  type === 0 && (reject === 0 || reject === null)
                    ? "Drafted"
                    : type === 0 && (reject === 1 || reject === 2)
                    ? "Unlocked for Resubmission"
                    : type === 1 && reject === 2
                    ? "Unlocked for Review Again"
                    : type === 1
                    ? "Pending Review"
                    : type === 2
                    ? "Pending Approval"
                    : type === 3
                    ? "Approved & Locked"
                    : "Pending Submission",
                status:
                  type === 0 && (reject === 0 || reject === null)
                    ? 0
                    : type === 0 && (reject === 1 || reject === 2)
                    ? 1
                    : type === 1 && reject === 2
                    ? 2
                    : type === 1
                    ? 3
                    : type === 2
                    ? 4
                    : type === 3
                    ? 5
                    : null,
              });
            });
          });
          qn_submit_list[index].response.forEach((i) => {
            let abc = {};
            abc["a1"] = i["DPAN613"];
            abc["b1"] = i["DPAN614"];
            abc["c1"] = i["DPAN615"];
            abc["d1"] = i["DPAN616"];
            abc["e1"] = i["DPAN617"];
            abc["f1"] = i["DPAN618"];

            value_arr.forEach((x) => {
              console.log(list_arr2);
              let oldIndex = list_arr2.findIndex((j) => j[x] !== undefined);
              if (oldIndex !== -1) {
                value2 = list_arr2[oldIndex][x];

                list_arr.push({
                  ...i,
                  [x]: abc[x],
                  remarks: qn_submit_list[index].return_remarks,
                  col: getNumberOfColumn(obj),
                  id: qn_submit_list[index].id,
                  category: 2,
                  form_type: 2,
                  diff:
                    abc[x] === value2 ? null : abc[x] > value2 ? false : true,
                  current: abc[x],
                  month: getRPTextFormat(
                    qn_submit_list[index].reporting_period
                  ),
                  percentage: calculatePercentage(abc[x], value2),
                  type,
                  self,
                  text:
                    type === 0 && (reject === 0 || reject === null)
                      ? "Drafted"
                      : type === 0 && (reject === 1 || reject === 2)
                      ? "Unlocked for Resubmission"
                      : type === 1 && reject === 2
                      ? "Unlocked for Review Again"
                      : type === 1
                      ? "Pending Review"
                      : type === 2
                      ? "Pending Approval"
                      : type === 3
                      ? "Approved & Locked"
                      : "Pending Submission",
                  status:
                    type === 0 && (reject === 0 || reject === null)
                      ? 0
                      : type === 0 && (reject === 1 || reject === 2)
                      ? 1
                      : type === 1 && reject === 2
                      ? 2
                      : type === 1
                      ? 3
                      : type === 2
                      ? 4
                      : type === 3
                      ? 5
                      : null,
                });
              } else {
                list_arr.push({
                  ...i,
                  [x]: abc[x],
                  remarks: qn_submit_list[index].return_remarks,
                  col: getNumberOfColumn(obj),
                  id: qn_submit_list[index].id,
                  category: 2,
                  form_type: 2,
                  diff: false,
                  current: abc[x],
                  month: getRPTextFormat(
                    qn_submit_list[index].reporting_period
                  ),
                  percentage: "100%",
                  type,
                  self,
                  text:
                    type === 0 && (reject === 0 || reject === null)
                      ? "Drafted"
                      : type === 0 && (reject === 1 || reject === 2)
                      ? "Unlocked for Resubmission"
                      : type === 1 && reject === 2
                      ? "Unlocked for Review Again"
                      : type === 1
                      ? "Pending Review"
                      : type === 2
                      ? "Pending Approval"
                      : type === 3
                      ? "Approved & Locked"
                      : "Pending Submission",
                  status:
                    type === 0 && (reject === 0 || reject === null)
                      ? 0
                      : type === 0 && (reject === 1 || reject === 2)
                      ? 1
                      : type === 1 && reject === 2
                      ? 2
                      : type === 1
                      ? 3
                      : type === 2
                      ? 4
                      : type === 3
                      ? 5
                      : null,
                });
              }
            });
          });

          result = { [obj]: attachObj(list_arr, refobj) };
        } else {
          const { type, reject } = qn_submit_list[index];
          let value = null;
          result_arr = [];
          let list_arr = [];
          console.log(qn_submit_list[index]);
          if (qn_submit_list[index].response.length) {
            qn_submit_list[index].response.forEach((i) => {
              let abc = {};

              abc["a1"] = i["DPAN613"];
              abc["b1"] = i["DPAN614"];
              abc["c1"] = i["DPAN615"];
              abc["d1"] = i["DPAN616"];
              abc["e1"] = i["DPAN617"];
              abc["f1"] = i["DPAN618"];
              value_arr.forEach((x) => {
                list_arr.push({
                  ...i,
                  [x]: abc[x],
                  remarks: qn_submit_list[index].return_remarks,
                  col: getNumberOfColumn(obj),
                  id: qn_submit_list[index].id,
                  form_type: 2,
                  category: 1,
                  diff: false,
                  current: abc[x],
                  month: getRPTextFormat(
                    qn_submit_list[index].reporting_period
                  ),
                  percentage: "-",
                  type,
                  self,
                  text:
                    type === 0 && (reject === 0 || reject === null)
                      ? "Drafted"
                      : type === 0 && (reject === 1 || reject === 2)
                      ? "Unlocked for Resubmission"
                      : type === 1 && reject === 2
                      ? "Unlocked for Review Again"
                      : type === 1
                      ? "Pending Review"
                      : type === 2
                      ? "Pending Approval"
                      : type === 3
                      ? "Approved & Locked"
                      : "Pending Submission",
                  status:
                    type === 0 && (reject === 0 || reject === null)
                      ? 0
                      : type === 0 && (reject === 1 || reject === 2)
                      ? 1
                      : type === 1 && reject === 2
                      ? 2
                      : type === 1
                      ? 3
                      : type === 2
                      ? 4
                      : type === 3
                      ? 5
                      : null,
                });
              });
            });
          } else {
            list_arr.push({
              edit: 1,
              remarks: qn_submit_list[index].return_remarks,
              col: getNumberOfColumn(obj),
              id: qn_submit_list[index].id,
              form_type: 2,
              category: 1,
              diff: false,
              current: "-",
              month: getRPTextFormat(qn_submit_list[index].reporting_period),
              percentage: "-",
              type,
              self,
              text:
                type === 0 && (reject === 0 || reject === null)
                  ? "Drafted"
                  : type === 0 && (reject === 1 || reject === 2)
                  ? "Unlocked for Resubmission"
                  : type === 1 && reject === 2
                  ? "Unlocked for Review Again"
                  : type === 1
                  ? "Pending Review"
                  : type === 2
                  ? "Pending Approval"
                  : type === 3
                  ? "Approved & Locked"
                  : "Pending Submission",
              status:
                type === 0 && (reject === 0 || reject === null)
                  ? 0
                  : type === 0 && (reject === 1 || reject === 2)
                  ? 1
                  : type === 1 && reject === 2
                  ? 2
                  : type === 1
                  ? 3
                  : type === 2
                  ? 4
                  : type === 3
                  ? 5
                  : null,
            });
          }

          result = { [obj]: attachObj(list_arr, refobj) };
        }
      }

      final_result = { ...final_result, ...result };
    });

    Object.values(final_result).forEach((j) => {
      console.log(j, arr);
      let find = Object.values(j).filter((i) => i["a1"] !== undefined);

      Object.values(j).forEach((k) => {
        console.log(k);
        if (k.category !== null && find.length) {
          let name1 = "NA";
          if (k["a1"] || k["a1"] === 0) {
            name1 = "Senior Management (Male)";
          } else if (k["b1"] || k["b1"] === 0) {
            console.log("xyz");
            name1 = "Senior Management (Female)";
          } else if (k["c1"] || k["c1"] === 0) {
            name1 = "Middle Management (Male)";
          } else if (k["d1"] || k["d1"] === 0) {
            name1 = "Middle Management (Female)";
          } else if (k["e1"] || k["e1"] === 0) {
            name1 = "Non-Management (Male)";
          } else if (k["f1"] || k["f1"] === 0) {
            name1 = "Non-Management (Female)";
          }

          k.dp = name1 === undefined ? "NA" : name1 + " ^ " + unit;
        } else {
          k.dp = null;
        }
      });
    });
    console.log(final_result);
    return final_result;
  };
  const getValueByMonthlyEmpNewTurn284 = (
    dcfId,
    tier0,
    tier1,
    tier2,
    tier3,
    arr,
    qn_submit_list,
    unit,
    self,
    refobj
  ) => {
    // let months = ['Jan-' + yr, 'Feb-' + yr, 'Mar-' + yr, 'Apr-' + yr, 'May-' + yr, 'Jun-' + yr, 'Jul-' + yr, 'Aug-' + yr, 'Sep-' + yr, 'Oct-' + yr, 'Nov-' + yr, 'Dec-' + yr]
    let land_veh_list = [
      "Standard Taxi / Sedan",
      "Hybrid Vehicle",
      "Executive Taxi",
      "Dual Purpose 4 x 4 (SUV)",
      "Electric Vehicle (EV)",
      "Van/ Coach",
    ];
    const value_arr = ["a1", "b1", "c1", "d1"];
    let final_result = {};

    console.log(tier0, tier1, tier2, tier3);
    arr.forEach((obj) => {
      console.log(obj);
      let result = {
        [obj]: [refobj],
      };
      let result_arr = [];
      let index = qn_submit_list.findIndex((i) => {
        console.log(
          getRPTextFormat(i.reporting_period) === obj,
          dcfId === i.dcfId,
          i.tier0_id === tier0,
          i.tier1_id === tier1,
          i.tier2_id === tier2,
          i.tier3_id === tier3,
          i.id,
          i.tier3_id,
          tier3
        );
        return (
          i.dcfId === dcfId &&
          i.tier0_id === tier0 &&
          i.tier1_id === tier1 &&
          i.tier2_id === tier2 &&
          i.tier3_id === tier3 &&
          getRPTextFormat(i.reporting_period) === obj
        );
      });
      console.log(index, obj);
      if (index !== -1) {
        let index2 = qn_submit_list.findIndex((i) => {
          console.log(
            getPreviousPeriod(obj.includes("to") ? 2 : 1, obj),
            getRPTextFormat(i.reporting_period[i.reporting_period.length - 1]),
            obj
          );
          return (
            i.dcfId === dcfId &&
            (i.type == 0 ? i.reject === 1 : true) &&
            i.tier0_id === tier0 &&
            i.tier1_id === tier1 &&
            i.tier2_id === tier2 &&
            i.tier3_id === tier3 &&
            getRPTextFormat([
              i.reporting_period[i.reporting_period.length - 1],
            ]) === getPreviousPeriod(obj.includes("to") ? 2 : 1, obj)
          );
        });
        console.log(index2, obj);
        if (
          index2 !== -1 &&
          qn_submit_list[index2].response.length &&
          qn_submit_list[index].response.length
        ) {
          const { type1, reject1 } = qn_submit_list[index2];
          const { type, reject } = qn_submit_list[index];
          let value = null,
            value2 = null;
          result_arr = [];
          let list_arr = [];
          let list_arr2 = [];
          qn_submit_list[index2].response.forEach((i) => {
            let abc = {};

            abc["a1"] = i["DPAN641"] + i["DPAN642"] + i["DPAN643"];
            abc["b1"] = i["DPAN644"] + i["DPAN645"] + i["DPAN646"];
            abc["c1"] = i["DPAN672"] + i["DPAN673"] + i["DPAN674"];
            abc["d1"] = i["DPAN675"] + i["DPAN676"] + i["DPAN677"];

            value_arr.forEach((x) => {
              list_arr2.push({
                ...i,
                [x]: abc[x],
                remarks: qn_submit_list[index].return_remarks,
                col: getNumberOfColumn(obj),
                id: qn_submit_list[index].id,
                category: 1,
                form_type: 2,
                diff: false,
                current: abc[x],
                month: getRPTextFormat(qn_submit_list[index].reporting_period),
                percentage: "-",
                type,
                self,
                text:
                  type === 0 && (reject === 0 || reject === null)
                    ? "Drafted"
                    : type === 0 && (reject === 1 || reject === 2)
                    ? "Unlocked for Resubmission"
                    : type === 1 && reject === 2
                    ? "Unlocked for Review Again"
                    : type === 1
                    ? "Pending Review"
                    : type === 2
                    ? "Pending Approval"
                    : type === 3
                    ? "Approved & Locked"
                    : "Pending Submission",
                status:
                  type === 0 && (reject === 0 || reject === null)
                    ? 0
                    : type === 0 && (reject === 1 || reject === 2)
                    ? 1
                    : type === 1 && reject === 2
                    ? 2
                    : type === 1
                    ? 3
                    : type === 2
                    ? 4
                    : type === 3
                    ? 5
                    : null,
              });
            });
          });
          qn_submit_list[index].response.forEach((i) => {
            let abc = {};
            abc["a1"] = i["DPAN641"] + i["DPAN642"] + i["DPAN643"];
            abc["b1"] = i["DPAN644"] + i["DPAN645"] + i["DPAN646"];
            abc["c1"] = i["DPAN672"] + i["DPAN673"] + i["DPAN674"];
            abc["d1"] = i["DPAN675"] + i["DPAN676"] + i["DPAN677"];

            value_arr.forEach((x) => {
              console.log(list_arr2);
              let oldIndex = list_arr2.findIndex((j) => j[x] !== undefined);
              if (oldIndex !== -1) {
                value2 = list_arr2[oldIndex][x];

                list_arr.push({
                  ...i,
                  [x]: abc[x],
                  remarks: qn_submit_list[index].return_remarks,
                  col: getNumberOfColumn(obj),
                  id: qn_submit_list[index].id,
                  category: 2,
                  form_type: 2,
                  diff:
                    abc[x] === value2 ? null : abc[x] > value2 ? false : true,
                  current: abc[x],
                  month: getRPTextFormat(
                    qn_submit_list[index].reporting_period
                  ),
                  percentage: calculatePercentage(abc[x], value2),
                  type,
                  self,
                  text:
                    type === 0 && (reject === 0 || reject === null)
                      ? "Drafted"
                      : type === 0 && (reject === 1 || reject === 2)
                      ? "Unlocked for Resubmission"
                      : type === 1 && reject === 2
                      ? "Unlocked for Review Again"
                      : type === 1
                      ? "Pending Review"
                      : type === 2
                      ? "Pending Approval"
                      : type === 3
                      ? "Approved & Locked"
                      : "Pending Submission",
                  status:
                    type === 0 && (reject === 0 || reject === null)
                      ? 0
                      : type === 0 && (reject === 1 || reject === 2)
                      ? 1
                      : type === 1 && reject === 2
                      ? 2
                      : type === 1
                      ? 3
                      : type === 2
                      ? 4
                      : type === 3
                      ? 5
                      : null,
                });
              } else {
                list_arr.push({
                  ...i,
                  [x]: abc[x],
                  remarks: qn_submit_list[index].return_remarks,
                  col: getNumberOfColumn(obj),
                  id: qn_submit_list[index].id,
                  category: 2,
                  form_type: 2,
                  diff: false,
                  current: abc[x],
                  month: getRPTextFormat(
                    qn_submit_list[index].reporting_period
                  ),
                  percentage: "100%",
                  type,
                  self,
                  text:
                    type === 0 && (reject === 0 || reject === null)
                      ? "Drafted"
                      : type === 0 && (reject === 1 || reject === 2)
                      ? "Unlocked for Resubmission"
                      : type === 1 && reject === 2
                      ? "Unlocked for Review Again"
                      : type === 1
                      ? "Pending Review"
                      : type === 2
                      ? "Pending Approval"
                      : type === 3
                      ? "Approved & Locked"
                      : "Pending Submission",
                  status:
                    type === 0 && (reject === 0 || reject === null)
                      ? 0
                      : type === 0 && (reject === 1 || reject === 2)
                      ? 1
                      : type === 1 && reject === 2
                      ? 2
                      : type === 1
                      ? 3
                      : type === 2
                      ? 4
                      : type === 3
                      ? 5
                      : null,
                });
              }
            });
          });

          result = { [obj]: attachObj(list_arr, refobj) };
        } else {
          const { type, reject } = qn_submit_list[index];
          let value = null;
          result_arr = [];
          let list_arr = [];
          console.log(qn_submit_list[index]);
          if (qn_submit_list[index].response.length) {
            qn_submit_list[index].response.forEach((i) => {
              let abc = {};

              abc["a1"] = i["DPAN641"] + i["DPAN642"] + i["DPAN643"];
              abc["b1"] = i["DPAN644"] + i["DPAN645"] + i["DPAN646"];
              abc["c1"] = i["DPAN672"] + i["DPAN673"] + i["DPAN674"];
              abc["d1"] = i["DPAN675"] + i["DPAN676"] + i["DPAN677"];
              value_arr.forEach((x) => {
                list_arr.push({
                  ...i,
                  [x]: abc[x],
                  remarks: qn_submit_list[index].return_remarks,
                  col: getNumberOfColumn(obj),
                  id: qn_submit_list[index].id,
                  form_type: 2,
                  category: 1,
                  diff: false,
                  current: abc[x],
                  month: getRPTextFormat(
                    qn_submit_list[index].reporting_period
                  ),
                  percentage: "-",
                  type,
                  self,
                  text:
                    type === 0 && (reject === 0 || reject === null)
                      ? "Drafted"
                      : type === 0 && (reject === 1 || reject === 2)
                      ? "Unlocked for Resubmission"
                      : type === 1 && reject === 2
                      ? "Unlocked for Review Again"
                      : type === 1
                      ? "Pending Review"
                      : type === 2
                      ? "Pending Approval"
                      : type === 3
                      ? "Approved & Locked"
                      : "Pending Submission",
                  status:
                    type === 0 && (reject === 0 || reject === null)
                      ? 0
                      : type === 0 && (reject === 1 || reject === 2)
                      ? 1
                      : type === 1 && reject === 2
                      ? 2
                      : type === 1
                      ? 3
                      : type === 2
                      ? 4
                      : type === 3
                      ? 5
                      : null,
                });
              });
            });
          } else {
            list_arr.push({
              edit: 1,
              remarks: qn_submit_list[index].return_remarks,
              col: getNumberOfColumn(obj),
              id: qn_submit_list[index].id,
              form_type: 2,
              category: 1,
              diff: false,
              current: "-",
              month: getRPTextFormat(qn_submit_list[index].reporting_period),
              percentage: "-",
              type,
              self,
              text:
                type === 0 && (reject === 0 || reject === null)
                  ? "Drafted"
                  : type === 0 && (reject === 1 || reject === 2)
                  ? "Unlocked for Resubmission"
                  : type === 1 && reject === 2
                  ? "Unlocked for Review Again"
                  : type === 1
                  ? "Pending Review"
                  : type === 2
                  ? "Pending Approval"
                  : type === 3
                  ? "Approved & Locked"
                  : "Pending Submission",
              status:
                type === 0 && (reject === 0 || reject === null)
                  ? 0
                  : type === 0 && (reject === 1 || reject === 2)
                  ? 1
                  : type === 1 && reject === 2
                  ? 2
                  : type === 1
                  ? 3
                  : type === 2
                  ? 4
                  : type === 3
                  ? 5
                  : null,
            });
          }

          result = { [obj]: attachObj(list_arr, refobj) };
        }
      }

      final_result = { ...final_result, ...result };
    });

    Object.values(final_result).forEach((j) => {
      console.log(j, arr);
      let find = Object.values(j).filter((i) => i["a1"] !== undefined);

      Object.values(j).forEach((k) => {
        console.log(k);
        if (k.category !== null && find.length) {
          let name1 = "NA";
          if (k["a1"] || k["a1"] === 0) {
            name1 = "Total Number of Hires (Male)";
          } else if (k["b1"] || k["b1"] === 0) {
            console.log("xyz");
            name1 = "Total Number of Hires (Female)";
          } else if (k["c1"] || k["c1"] === 0) {
            name1 = "Total Employee Turnover (Male)";
          } else if (k["d1"] || k["d1"] === 0) {
            name1 = "Total Employee Turnover (Female)";
          }

          k.dp = name1 === undefined ? "NA" : name1 + " ^ " + unit;
        } else {
          k.dp = null;
        }
      });
    });
    // Object.values(final_result).forEach((y)=>{
    //     y.splice(1, 0, {dp:'Male'})
    //     y.splice(5, 0, {dp:'Female'})
    // })
    console.log(final_result);
    return final_result;
  };
  function arraysAreEqual(arr1, arr2) {
    // Check if the arrays have the same length
    if (arr1.length !== arr2.length) {
      return false;
    }

    // Check if each element in arr1 matches the corresponding element in arr2
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) {
        return false;
      }
    }

    // If all elements match and lengths are the same, arrays are equal
    return true;
  }
  function getUniqueYearsArray(array1, array2) {
    // Convert arrays to sets to ensure uniqueness
    const set1 = new Set(array1);
    const set2 = new Set(array2);

    // Merge the sets
    const mergedSet = new Set([...set1, ...set2]);

    // Convert the merged set back to an array
    const uniqueYearsArray = Array.from(mergedSet);

    return uniqueYearsArray;
  }

  function getYearsArray(start_date, end_date = null) {
    // Parse the start date
    const startDate = DateTime.fromISO(start_date);

    // Parse the end date or use current UTC local date if end_date is null
    const endDate = end_date ? DateTime.fromISO(end_date) : DateTime.utc();

    // Get the years between start and end dates
    const yearsArray = [];
    for (let year = startDate.year; year <= endDate.year; year++) {
      yearsArray.push(year.toString());
    }

    return yearsArray;
  }
  const checkEnity = (rowData, entity_list, rawsite, obj) => {
    let index = entity_list.findIndex((k) => k[obj] === rowData[obj]);
    if (index !== -1) {
      let entity = entity_list[index];
      console.log(entity);
      if (rowData.level === 0) {
        return entity.tier0_ids.includes(0);
      } else if (rowData.level === 1) {
        return (
          entity.tier1_ids.includes(rowData.locationId) &&
          getCoverageText(rowData, rawsite)
        );
      } else if (rowData.level === 2) {
        return (
          entity.tier2_ids.includes(rowData.locationId) &&
          getCoverageText(rowData, rawsite)
        );
      } else if (rowData.level === 3) {
        return (
          entity.tier3_ids.includes(rowData.locationId) &&
          getCoverageText(rowData, rawsite)
        );
      }
    } else {
      return false;
    }
  };
  const checkEntity = (rowData, entity_list, obj) => {
    let index = entity_list.findIndex((k) => k[obj] === rowData[obj]);
    if (index !== -1) {
      let entity = entity_list[index];
      console.log(entity);
      if (rowData.level === 0) {
        return entity.tier0_ids.includes(0);
      } else if (rowData.level === 1) {
        return entity.tier1_ids.includes(rowData.locationId);
      } else if (rowData.level === 2) {
        return entity.tier2_ids.includes(rowData.locationId);
      } else if (rowData.level === 3) {
        return entity.tier3_ids.includes(rowData.locationId);
      }
    } else {
      return false;
    }
  };
  const getDataPointUnit = (rowData, dcfId) => {
    console.log(rowData);
    let text = "Not Found";
    let filteredList = dplist
      .filter(
        (i) =>
          i.suffix !== null &&
          i.suffix.trim().toLowerCase() === rowData.name.trim().toLowerCase()
      )
      .findIndex((i) => Array.isArray(i.data1));
    console.log(
      rowData.dcfId,
      dplist.filter(
        (i) =>
          i.suffix !== null &&
          i.suffix.trim().toLowerCase() === rowData.name.trim().toLowerCase()
      )
    );
    if (filteredList !== -1) {
      text = dplist.filter(
        (i) =>
          i.suffix !== null &&
          i.suffix.trim().toLowerCase() === rowData.name.trim().toLowerCase()
      )[filteredList].data1[0].unit;
    }
    console.log(rowData, text);
    return text;
  };
  const getCoverageText = (rowData, rawsitelist) => {
    let text = "";
    console.log(rowData);
    if (rowData.level === 0) {
      text = "Corporate";
    } else if (rowData.level === 1) {
      let country_index = rawsitelist.findIndex(
        (i) => i.id === rowData.locationId
      );
      if (country_index !== -1) {
        text = rawsitelist[country_index].name;
      }
    } else if (rowData.level === 2) {
      let city_index = rawsitelist
        .flatMap((i) =>
          i.locationTwos.flatMap((j) =>
            j.locationThrees.map((k) => {
              return {
                site_id: k.id,
                site_name: k.name,
                city_id: j.id,
                city_name: j.name,
                country_id: i.id,
                country_name: i.name,
              };
            })
          )
        )
        .findIndex((i) => {
          return i.city_id === rowData.locationId;
        });
      if (city_index !== -1) {
        text = rawsitelist.flatMap((i) =>
          i.locationTwos.flatMap((j) =>
            j.locationThrees.map((k) => {
              return {
                site_id: k.id,
                site_name: k.name,
                city_id: j.id,
                city_name: j.name,
                country_id: i.id,
                country_name: i.name,
              };
            })
          )
        )[city_index].city_name;
      }
    } else if (rowData.level === 3) {
      let site_index = rawsitelist
        .flatMap((i) =>
          i.locationTwos.flatMap((j) =>
            j.locationThrees.map((k) => {
              return {
                site_id: k.id,
                site_name: k.name,
                city_id: j.id,
                city_name: j.name,
                country_id: i.id,
                country_name: i.name,
              };
            })
          )
        )
        .findIndex((i) => {
          return i.site_id === rowData.locationId;
        });
      if (site_index !== -1) {
        text = rawsitelist.flatMap((i) =>
          i.locationTwos.flatMap((j) =>
            j.locationThrees.map((k) => {
              return {
                site_id: k.id,
                site_name: k.name,
                city_id: j.id,
                city_name: j.name,
                country_id: i.id,
                country_name: i.name,
              };
            })
          )
        )[site_index].site_name;
      }
    }
    return text;
  };
  function getNumberOfColumn(monthString) {
    if (monthString.includes("to")) {
      const [startMonth, endMonth] = monthString.split(" to ");

      const startDate = DateTime.fromFormat(startMonth, "LLL-yyyy");
      let endDate = DateTime.local(); // Default to current month and year
      if (endMonth) {
        endDate = DateTime.fromFormat(endMonth, "LLL-yyyy");
      }

      // Calculate the difference in months
      const diffMonths = endDate.diff(startDate, "months").months + 1;

      return diffMonths;
    } else {
      return 1;
    }
  }

  const getCoverageTextById = (rowData, rawsitelist) => {
    let text = "";
    console.log(rowData);
    if (rowData.tier0_id !== null) {
      text = "Corporate";
    } else if (rowData.tier1_id !== null) {
      let country_index = rawsitelist.findIndex(
        (i) => i.id === rowData.tier1_id
      );
      if (country_index !== -1) {
        text = rawsitelist[country_index].name;
      }
    } else if (rowData.tier2_id !== null) {
      let city_index = rawsitelist
        .flatMap((i) =>
          i.locationTwos.flatMap((j) =>
            j.locationThrees.map((k) => {
              return {
                site_id: k.id,
                site_name: k.name,
                city_id: j.id,
                city_name: j.name,
                country_id: i.id,
                country_name: i.name,
              };
            })
          )
        )
        .findIndex((i) => {
          return i.city_id === rowData.tier2_id;
        });
      if (city_index !== -1) {
        text = rawsitelist.flatMap((i) =>
          i.locationTwos.flatMap((j) =>
            j.locationThrees.map((k) => {
              return {
                site_id: k.id,
                site_name: k.name,
                city_id: j.id,
                city_name: j.name,
                country_id: i.id,
                country_name: i.name,
              };
            })
          )
        )[city_index].city_name;
      }
    } else if (rowData.tier3_id !== null) {
      let site_index = rawsitelist
        .flatMap((i) =>
          i.locationTwos.flatMap((j) =>
            j.locationThrees.map((k) => {
              return {
                site_id: k.id,
                site_name: k.name,
                city_id: j.id,
                city_name: j.name,
                country_id: i.id,
                country_name: i.name,
              };
            })
          )
        )
        .findIndex((i) => {
          return i.site_id === rowData.tier3_id;
        });
      if (site_index !== -1) {
        text = rawsitelist.flatMap((i) =>
          i.locationTwos.flatMap((j) =>
            j.locationThrees.map((k) => {
              return {
                site_id: k.id,
                site_name: k.name,
                city_id: j.id,
                city_name: j.name,
                country_id: i.id,
                country_name: i.name,
              };
            })
          )
        )[site_index].site_name;
      }
    }
    return text;
  };

  const titleTemplate = (rowData) => {
    let text = "Not Found";
    let dcf_index = dcflist.findIndex((i) => i.id === rowData.dcfId);
    if (dcf_index !== -1) {
      text = dcflist[dcf_index].title;
    }
    return (
      <div
        className="text-underline cur-pointer clr-navy fw-5"
        onClick={() => {
          navigate.push({
            pathname:
              "/data_input_reviewer/" + rowData.dcfId + "/" + rowData.id,
          });
        }}
      >
        {text}
      </div>
    );
  };

  const getFormTitle = (rowData) => {
    let text = "Not Found";
    let dcf_index = dcflist.findIndex((i) => i.id === rowData.dcfId);
    if (dcf_index !== -1) {
      text = dcflist[dcf_index].title;
    }
    return text;
  };

  const dpNameTemplate = (rowData) => {
    console.log(rowData);
    return (
      <>
        {
          rowData.label
            .replace(/(<([^>]+)>)/gi, "")
            .replace(/\n/g, " ")
            .replace(/&nbsp;/g, " ")
            .replace("&amp;", "&")
            .split("^")[0]
        }
      </>
    );
  };

  const coverageTemplate = (rowData) => {
    return <>{getCoverageText(rowData, rawsitelist)}</>;
  };

  const getUser = (id) => {
    let user_name = "Not Found";
    let index = userList.findIndex((i) => i.id === id);
    if (index !== -1) {
      user_name = userList[index].information.empname;
    }
    return user_name;
  };
  const activeTab = (status) => {
    let filteredArray = refineddatabk.filter((i) =>
      i.status.some((x) => status.includes(x))
    );
    console.log(refineddatabk);
    setRefinedData(filteredArray);
    setActiveTab(status);
  };

  const getCountByStatus = (code) => {
    // let filtered = dt.current.getVirtualScroller().props.items.map(i => i.id)

    return refineddatabk?.filter((i) => i.status.includes(code)).length;
  };
  const submitterTemplate = (rowData) => {
    return <>{getUser(rowData.reporter_modified_by)}</>;
  };
  const submittedTemplate = (rowData) => {
    return (
      <>
        {DateTime.fromISO(rowData.last_modified_on, { zone: "utc" })
          .toLocal()
          .toFormat("dd-LLL-yyyy HH:mm")}
      </>
    );
  };
  const rpFrequencyTemplate = (rowData) => {
    return <span>{getFrequencyText(rowData.frequency)}</span>;
  };
  const janTemplate = (rowData) => {
    return <span>{rowData.locationId}</span>;
  };
  const getColumn = (rowData) => {
    console.log(rowData);
    return 1;
  };

  const getFrequencyText = (id) => {
    return frequency_list.find((i) => {
      return i.id === id;
    }).name;
  };
  const sortRP = (e) => {
    console.log(e.data);
    if (e.order === 1) {
      return e.data.sort((a, b) => {
        let dateA = DateTime.fromFormat(a.reporting_period, "MMM-yyyy");
        let dateB = DateTime.fromFormat(b.reporting_period, "MMM-yyyy");
        if (a.reporting_period.includes("to")) {
          dateA = DateTime.fromFormat(
            a.reporting_period.split("to")[0].trim(),
            "MMM-yyyy"
          );
        }
        if (b.reporting_period.includes("to")) {
          dateB = DateTime.fromFormat(
            b.reporting_period.split("to")[0].trim(),
            "MMM-yyyy"
          );
        }

        // Compare the parsed dates
        if (dateA < dateB) return -1;
        if (dateA > dateB) return 1;
        return 0;
      });
    } else {
      return e.data.sort((a, b) => {
        let dateA = DateTime.fromFormat(a.reporting_period, "MMM-yyyy");
        let dateB = DateTime.fromFormat(b.reporting_period, "MMM-yyyy");
        if (a.reporting_period.includes("to")) {
          dateA = DateTime.fromFormat(
            a.reporting_period.split("to")[0].trim(),
            "MMM-yyyy"
          );
        }
        if (b.reporting_period.includes("to")) {
          dateB = DateTime.fromFormat(
            b.reporting_period.split("to")[0].trim(),
            "MMM-yyyy"
          );
        }
        // Compare the parsed dates in descending order
        if (dateA > dateB) return -1;
        if (dateA < dateB) return 1;
        return 0;
      });
    }
  };
  const getStatusText = (val, rowData) => {
    let text = "NOT SUBMITTED";
    console.log(rowData);
    if (rowData !== undefined) {
      if (rowData.type === 1) {
        text = "Not Reviewed";
      } else if (rowData.type === 2) {
        if (rowData.self) {
          text = "Self Reviewed";
        } else {
          text = "Reviewed";
        }
      } else if (rowData.type === 3) {
        text = "APPROVED";
      }
    }
    return text;
  };
  const sortStatus_ = (e) => {
    if (e.order === 1) {
      return e.data.sort((a, b) => {
        const dateA = getStatusText("", a);
        const dateB = getStatusText("", b);
        console.log(dateA, dateB);
        // Compare the parsed dates
        if (dateA < dateB) return -1;
        if (dateA > dateB) return 1;
        return 0;
      });
    } else {
      return e.data.sort((a, b) => {
        const dateA = getStatusText("", a);
        const dateB = getStatusText("", b);

        // Compare the parsed dates in descending order
        if (dateA > dateB) return -1;
        if (dateA < dateB) return 1;
        return 0;
      });
    }
  };
  const statusTemplate = (rowData) => {
    let type = 0;
    let text = "NOT SUBMITTED";
    let classtext = "status-tag-gray";
    if (rowData !== undefined) {
      if (rowData.type === 0) {
        if (rowData.reject === 1) {
          type = 1;
          text = "RETURNED";
          classtext = "status-tag-red";
        } else {
          type = 2;
          text = "DRAFT";
          classtext = "status-tag-orange";
        }
      } else if (rowData.type === 1) {
        if (rowData.reject === 1) {
          type = 1;
          text = "RETURNED";
          classtext = "status-tag-red";
        } else {
          type = 3;
          text = "SUBMITTED";
          classtext = "status-tag-green";
        }
      } else if (rowData.type === 2) {
        type = 3;
        if (rowData.self) {
          text = "Self Reviewed";
        } else {
          text = "Reviewed";
        }

        classtext = "status-tag-green";
      } else if (rowData.type === 3) {
        type = 4;
        text = "APPROVED";
        classtext = "status-tag-green";
      }
    }
    return (
      <Tag style={{ padding: "4px 8px" }} className={classtext}>
        {text}
      </Tag>
    );
  };
  const extractDPFromForms = (list) => {
    let form = JSON.parse(JSON.stringify(list));

    const updatedResponses = form.flatMap((submission) => {
      return submission.response
        .filter((response) => response.type === "number")
        .map((response) => ({
          ...response,
          colSpan: submission.reporting_period.length,
          ...submission,
        }));
    });

    // setRefinedData(updatedResponses)
  };

  const updateSelectedItem = (item, index) => {
    let list_loc = JSON.parse(JSON.stringify(refineddata));
    list_loc
      .filter((x) => x.dp_array.length > 1)
      .forEach((item, j) => {
        if (index === j) {
          item.expanded = item.expanded === undefined ? true : !item.expanded;
        }
      });
    setRefinedData(list_loc);
    console.log(list_loc);
    forceUpdate();
  };

  const getMonthKey = (key) => {
    return key;
    if (key.includes("to")) {
      const [from, to] = key.split(" to ");

      return from.split("-")[0] + " to " + to.split("-")[0];
    } else {
      return key.split("-")[0];
    }
  };
  const updateRefindedDataByFilter = (val) => {
    let loc = JSON.parse(JSON.stringify(refineddatabk));
    if (val) {
      setFilter((prev) => ({ ...prev, [val]: [] }));
      if (val === "entity") {
        setRefinedData(
          loc.filter((i) => {
            return (
              (i.year === filter.year || filter.year === null) &&
              (filter.form.includes(i.dcfId) || filter.form.length === 0) &&
              (i.status.some((status) => filter.status.includes(status)) ||
                filter.status.length === 0)
            );
          })
        );
      } else if (val === "form") {
        setRefinedData(
          loc.filter((i) => {
            return (
              (i.year === filter.year || filter.year === null) &&
              (filter.entity.includes(getCoverageTextById(i, rawsitelist)) ||
                filter.entity.length === 0) &&
              (i.status.some((status) => filter.status.includes(status)) ||
                filter.status.length === 0)
            );
          })
        );
      } else {
        setRefinedData(
          loc.filter((i) => {
            return (
              (i.year === filter.year || filter.year === null) &&
              (filter.entity.includes(getCoverageTextById(i, rawsitelist)) ||
                filter.entity.length === 0) &&
              (filter.form.includes(i.dcfId) || filter.form.length === 0)
            );
          })
        );
      }
    } else {
      setRefinedData(
        loc.filter((i) => {
          return (
            (i.year === filter.year || filter.year === null) &&
            (filter.entity.includes(getCoverageTextById(i, rawsitelist)) ||
              filter.entity.length === 0) &&
            (filter.form.includes(i.dcfId) || filter.form.length === 0) &&
            (i.status.some((status) => filter.status.includes(status)) ||
              filter.status.length === 0)
          );
        })
      );
    }
  };
  const onFilterChange = (obj, val) => {
    setFilter((prev) => ({ ...prev, [obj]: val }));
  };
  const onSelectedChangeIntial = (obj, val, refloc) => {
    let loc = JSON.parse(JSON.stringify(refloc));
    console.log(val);
    if (obj === "year") {
      setRefinedData(
        loc.filter((i) => {
          return (
            (i.year === val || val === null) &&
            (filter.entity.includes(getCoverageTextById(i, rawsitelist)) ||
              filter.entity.length === 0) &&
            (filter.form.includes(i.dcfId) || filter.form.length === 0) &&
            i.status.some((status) => filter.status.includes(status))
          );
        })
      );
    }

    setLoading(true);

    setFilter((prev) => ({ ...prev, [obj]: val }));
  };
  const updateFilter = (obj, val) => {
    let loc = JSON.parse(JSON.stringify(refineddatabk));
    let locusb = JSON.parse(JSON.stringify(quantitativesubmission));
    console.log(val);
    setLoading(false);
    let item = { ...filter, [obj]: val };
    let selected_item = { country: 0, city: 0, location: 0 };
    let country_list = [{ name: "Enterprise-wide", id: 0 }];
    let city_list = [{ name: "All Regions", id: 0 }];
    let location_list = [{ name: "All Business Unit", id: 0 }];
    rawsitelist.forEach((country) => {
      country_list.push({ name: country.name, id: country.id });
      if (country.id === item.country || item.country === 0) {
        if (country.locationTwos) {
          country.locationTwos.forEach((city) => {
            city_list.push({ name: city.name, id: city.id });
            if (city.id === item.city || item.city === 0) {
              if (city.locationThrees) {
                city.locationThrees.forEach((site) => {
                  location_list.push({ name: site.name, id: site.id });
                });
              }
            }
          });
        }
      }
    });
    setTimeout(() => {
      if (obj === "year") {
        let filteredQnAssByYear = filterAssignmentsByFiscalYear(
          assignedlist,
          val
        );
        console.log(assignedlist);
        calculateAndCreateYearData(
          filteredQnAssByYear,
          dcflist,
          locusb.filter((i) =>
            isDateInFiscalYear(val, getRPTextFormat(i.reporting_period))
          ),
          stdlist,
          { cat1: subcat1, cat2: subcat2, cat3: subcat3, cat4: subcat4 },
          val
        ).then((res) => {
          let entity_option = Array.from(
            new Set(res.map((i) => getCoverageTextById(i, rawsitelist)))
          ).map((i) => ({ title: i, id: i }));
          setFormOption(
            dcflist.filter((i) => res.map((i) => i.dcfId).includes(i.id))
          );
          setEntityOption(entity_option);
          console.log(res);
          if (res.length) {
            onSelectedChangeIntial("year", val, res);
          } else {
            setFilter((prev) => ({ ...prev, year: val }));
            setLoading(true);
          }
          // setRefinedData(res);
          setRefinedDataBk(res);
        });
      } else if (obj === "section") {
        setRefinedData(
          loc.filter(
            (i) =>
              (i.section === val || val === 0) &&
              activetab.some((stat) => i.status.includes(stat))
          )
        );
        console.log(val, loc);
        setFilter((prev) => ({ ...prev, [obj]: val }));
        setLoading(true);
        forceUpdate();
      } else if (obj === "country") {
        item.city = val === 0 ? null : 0;
        item.location = null;
        setFilter((prev) => ({ ...prev, ...item }));
        setLocList((prev) => ({
          ...prev,
          country: country_list,
          city: city_list,
          location: location_list,
        }));
        setLoading(true);
      } else if (obj === "city") {
        item.location = val === 0 ? null : 0;
        setFilter((prev) => ({ ...prev, ...item }));

        setLocList((prev) => ({
          ...prev,
          country: country_list,
          city: city_list,
          location: location_list,
        }));
        setLoading(true);
      } else if (obj === "location") {
        item.location = val;
        setFilter((prev) => ({ ...prev, ...item }));
        setLoading(true);
      }
    }, 500);

    // setFilter((prev) => ({ ...prev, [obj]: val }));
  };
  function parseDateString(dateString) {
    if (dateString.includes("to")) {
      const [start, end] = dateString.split("to");
      return { start, end };
    } else {
      return { start: dateString, end: dateString };
    }
  }

  function isDateInFiscalYear(year, dateString) {
    const { start, end } = parseDateString(dateString);
    let curYear = year;
    if (fymonth !== 1) {
      curYear = year - 1;
    }
    const startDate = DateTime.fromFormat(start.trim(), "MMM-yyyy");
    const endDate = DateTime.fromFormat(end.trim(), "MMM-yyyy");

    const fiscalYearStart = DateTime.fromObject({
      year: curYear,
      month: fymonth,
      day: 1,
    }); // April 1 of the previous year
    const fiscalYearEnd = DateTime.fromObject({
      year: year,
      month: fymonth - 1 || 12,
      day: DateTime.fromObject({ year: year, month: fymonth - 1 || 12 })
        .daysInMonth,
    }); // March 31 of the given year
    console.log(
      (startDate >= fiscalYearStart && startDate <= fiscalYearEnd) ||
        (endDate >= fiscalYearStart && endDate <= fiscalYearEnd) ||
        (startDate <= fiscalYearStart && endDate >= fiscalYearEnd),
      dateString,
      curYear,
      year
    );
    return (
      (startDate >= fiscalYearStart && startDate <= fiscalYearEnd) ||
      (endDate >= fiscalYearStart && endDate <= fiscalYearEnd) ||
      (startDate <= fiscalYearStart && endDate >= fiscalYearEnd)
    );
  }
  const getRPLuxon = (months) => {
    if (months.includes("to")) {
      let startDate = DateTime.fromFormat(
        months.split("to")[0].trim(),
        "LLL-yyyy"
      );
      let endDate = DateTime.fromFormat(
        months.split("to")[1].trim(),
        "LLL-yyyy"
      );
      let rp = [];
      while (startDate <= endDate) {
        rp.push(startDate.toFormat("LL-yyyy"));
        startDate = startDate.plus({ months: 1 });
      }
      return rp;
    } else {
      return [DateTime.fromFormat(months, "LLL-yyyy").toFormat("LL-yyyy")];
    }
  };
  // const sortStatus = (a, b) => {
  //   if (filter.status === 0) {
  //     return a.status - b.status;
  //   } else if (filter.status === 1) {
  //     return b.status - a.status;
  //   } else {
  //     console.log("sort");
  //     return true;
  //   }
  // };

  // const getRoles = () => {
  //   if (login_data.role === "clientadmin") {
  //     return "Enterprise Admin";
  //   } else if (allRoles.length) {
  //     return allRoles
  //       .filter((id) => approles.map((i) => i.id).includes(id))
  //       .map((id) => approles.find((i) => i.id === id).title)
  //       .join(",");
  //   } else {
  //     return "";
  //   }
  // };

  return (
    <div className="bg-smoke font-lato">
      <div className="col-12">
        <div>
        <div className="col-12 flex align-items-center" >
             <span className="text-big-one"> Hello  {login_data?.role === 'clientadmin' ?  login_data?.information?.companyname : login_data?.information?.empname} ! </span>   <span className="ml-1">{`<${login_data.email}>`} </span>   
                    </div>

          <div
            className="flex col-12 flex-start"
            style={{ flexDirection: "column" }}
          >
            <span className="text-big-one">
              Sustainability performance dashboard
            </span>
            <p className="ml-1">Detailed progress report of ESG Indicators</p>
            {/* <Tag className="ml-3 p-tag-blue">
              {" "}
              {login_data.role === "clientadmin"
                ? "Enterprise Admin"
                : getRoles(login_data.information)}{" "}
            </Tag> */}
          </div>

          <div
            className="col-12 "
            style={{
              justifyContent: "center",
            }}
          ></div>

          <div className="grid col-12">
            {/* <div className="grid m-0 mr-4 col-3 align-items-center">
              <div className="col-6">
                <p className="fs-16 fw-5">Select Year</p>
              </div>
              <div className="col-3">
                <Dropdown
                  disabled={!load}
                  optionLabel="title"
                  optionValue="value"
                  options={yearoption.sort((a, b) => {
                    return a.value - b.value;
                  }, 0)}
                  style={{
                    borderRadius: "0px",
                  }}
                  value={filter.year}
                  onChange={(e) => {
                    updateFilter("year", e.value);
                  }}
                />
              </div>
            </div> */}

            <div className="grid m-0 col-6 align-items-center">
              <div className="col-2">Location :</div>
              <div className="grid m-0 col-10 justify-content-start">
                <div className="col-4">
                  <Dropdown
                    value={filter.country}
                    options={locList.country}
                    onChange={(e) => {
                      updateFilter("country", e.value);
                    }}
                    optionLabel="name"
                    optionValue="id"
                    placeholder="Select Country"
                    style={{
                      borderRadius: "0px",
                      width: "100%",
                    }}
                  />
                </div>
                {filter.country !== 0 &&
                  (filter.city !== null || filter.city === 0) && (
                    <div className="col-4">
                      <Dropdown
                        value={filter.city}
                        options={locList.city}
                        onChange={(e) => {
                          updateFilter("city", e.value);
                        }}
                        optionLabel="name"
                        optionValue="id"
                        placeholder="Select Region"
                        disabled={!locList.city.length}
                        style={{
                          borderRadius: "0px",
                          width: "100%",
                        }}
                      />
                    </div>
                  )}
                {filter.city !== 0 && filter.location !== null && (
                  <div className="col-4">
                    <Dropdown
                      value={filter.location}
                      options={locList.location}
                      onChange={(e) => {
                        updateFilter("location", e.value);
                      }}
                      optionLabel="name"
                      optionValue="id"
                      placeholder="Select Business Unit"
                      disabled={!locList.location.length}
                      style={{
                        borderRadius: "0px",
                        width: "100%",
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12">
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="incident report table"
          >
            <Tab
              label={
                <Typography variant="body1" style={customFontStyle}>
                  Overview
                </Typography>
              }
              value={TABS.OVERVIEW}
            />
            <Tab
              label={
                <Typography variant="body1" style={customFontStyle}>
                  Environment
                </Typography>
              }
              value={TABS.ENVIRONMENT}
            />
            <Tab
              label={
                <Typography variant="body1" style={customFontStyle}>
                  Safety Operations
                </Typography>
              }
              value={TABS.SAFETY_OPERATIONS}
            />
            <Tab
              label={
                <Typography variant="body1" style={customFontStyle}>
                  Safety Central
                </Typography>
              }
              value={TABS.SAFETY_CENTRAL}
            />
            <Tab
              label={
                <Typography variant="body1" style={customFontStyle}>
                  Health
                </Typography>
              }
              value={TABS.HEALTH}
            />
            <Tab
              label={
                <Typography variant="body1" style={customFontStyle}>
                  Sustainability
                </Typography>
              }
              value={TABS.SUSTAINABILITY}
            />
            <Tab
              label={
                <Typography variant="body1" style={customFontStyle}>
                  Suppy Chain
                </Typography>
              }
              value={TABS.SUPPLY_CHAIN}
            />
            <Tab
              label={
                <Typography variant="body1" style={customFontStyle}>
                  Dealers
                </Typography>
              }
              value={TABS.DEALERS}
            />
            <Tab
              label={
                <Typography variant="body1" style={customFontStyle}>
                  Social
                </Typography>
              }
              value={TABS.SOCIAL}
            />
            <Tab
              label={
                <Typography variant="body1" style={customFontStyle}>
                  Governance
                </Typography>
              }
              value={TABS.GOVERNANCE}
            />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} tabValue={TABS.OVERVIEW}>
          <Dashboard />
        </CustomTabPanel>
        <CustomTabPanel value={value} tabValue={TABS.ENVIRONMENT}>
          <div
            style={{
              fontFamily: "Lato",
              fontSize: "16px",
              fontWeight: 700,
              lineHeight: "19.2px",
              textAlign: "left",
              margin: "18px 10px 18px 10px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            Environment
            <div
              style={{
                fontFamily: "Lato",
                fontSize: "12px",
                fontWeight: 300,
                lineHeight: "12.2px",
                textAlign: "left",
                margin: "18px 10px 18px 0px",
              }}
            >
              Environment Indicators including energy, emissions, waste, and
              water management.
            </div>
          </div>
          <ScopeEmissionChart />
          <EnergyConsumptionChart />
          <FuelConsumptionChart />
          <Scope1Chart />
          <CombustionChart />
          <RefrigerantChart />
          <Scope2Chart />
          <Scope3Chart />
          <div
            style={{
              fontFamily: "Lato",
              fontSize: "16px",
              fontWeight: 700,
              lineHeight: "19.2px",
              textAlign: "left",
              margin: "18px 10px 18px 10px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            Water Management
            <div
              style={{
                fontFamily: "Lato",
                fontSize: "12px",
                fontWeight: 300,
                lineHeight: "12.2px",
                textAlign: "left",
                margin: "18px 10px 18px 0px",
              }}
            >
              Management of water withdrawal, consumption and discharge
            </div>
          </div>
          <WaterGeneratedChart />
          <WaterDischargedChart />
          <div
            style={{
              fontFamily: "Lato",
              fontSize: "16px",
              fontWeight: 700,
              lineHeight: "19.2px",
              textAlign: "left",
              margin: "18px 10px 18px 10px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            Waste Management
            <div
              style={{
                fontFamily: "Lato",
                fontSize: "12px",
                fontWeight: 300,
                lineHeight: "12.2px",
                textAlign: "left",
                margin: "18px 10px 18px 0px",
              }}
            >
              Management of Hazardous and Non-Hazardous wastes in the
              organization
            </div>
          </div>
          <HazardousAndNonHazardousChart />
          <WasteManagement />
          <NonHazardousChart />
        </CustomTabPanel>
        <CustomTabPanel value={value} tabValue={TABS.SAFETY_OPERATIONS}>
          <div
            style={{
              fontFamily: "Lato",
              fontSize: "16px",
              fontWeight: 700,
              lineHeight: "19.2px",
              textAlign: "left",
              margin: "18px 10px 18px 10px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            Safety Operations
            <div
              style={{
                fontFamily: "Lato",
                fontSize: "12px",
                fontWeight: 300,
                lineHeight: "12.2px",
                textAlign: "left",
                margin: "18px 10px 18px 0px",
              }}
            >
              Employee safety performance across the organization
            </div>
          </div>
          <LtifMonthChart />
          <TrirMonthChart />
          <FatalityChart />
        </CustomTabPanel>
        <CustomTabPanel value={value} tabValue={TABS.SAFETY_CENTRAL}>
          <div
            style={{
              fontFamily: "Lato",
              fontSize: "16px",
              fontWeight: 700,
              lineHeight: "19.2px",
              textAlign: "left",
              margin: "18px 10px 18px 10px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            Safety Central
            <div
              style={{
                fontFamily: "Lato",
                fontSize: "12px",
                fontWeight: 300,
                lineHeight: "12.2px",
                textAlign: "left",
                margin: "18px 10px 18px 0px",
              }}
            >
              Safety performance at organizational level
            </div>
          </div>
          <SafetyTrainingChart />
        </CustomTabPanel>
        <CustomTabPanel value={value} tabValue={TABS.HEALTH}>
          <HealthSafetyChart />
        </CustomTabPanel>
        <CustomTabPanel value={value} tabValue={TABS.SUSTAINABILITY}>
          <UnderDevelopment />
        </CustomTabPanel>
        <CustomTabPanel value={value} tabValue={TABS.SUPPLY_CHAIN}>
          <SupplierMSIDashboard />
          <SupplyChainChart />
          <SupplyChainPieChart />
        </CustomTabPanel>
        <CustomTabPanel value={value} tabValue={TABS.DEALERS}>
          <UnderDevelopment />
        </CustomTabPanel>
        <CustomTabPanel value={value} tabValue={TABS.SOCIAL}>
          <SocialChart1 />
          <SocialChart2 />
          <SocialChart3 />
          <SocialChart4 />
          <SocialChart5 />
        </CustomTabPanel>
        <CustomTabPanel value={value} tabValue={TABS.GOVERNANCE}>
          <AntiCorruptionTrainingChart />
          <GovernanceChart2 />
          <GovernanceChart3 />
        </CustomTabPanel>
      </div>

      <Dialog
        visible={remarksdialog}
        modal
        className="p-fluid"
        onHide={() => {
          setRemarksDialog(false);
        }}
        style={{ width: "65%" }}
        header={"Remarks"}
      >
        <div>
          {remarksdata.map((cmnt) => {
            return (
              <div
                className="grid col-12 "
                style={{ marginBottom: 10, borderBottom: "1px solid gray" }}
              >
                <div className="col-5">
                  <div>
                    {" "}
                    {cmnt.user_id === login_data.id
                      ? "You"
                      : getUser(cmnt.user_id)}
                  </div>
                  <div className="mt-2">
                    {" "}
                    {DateTime.fromISO(cmnt.created_on, { zone: "utc" })
                      .toLocal()
                      .toFormat("dd-LLL-yyyy")}{" "}
                  </div>
                </div>
                <div className="col-5">{cmnt.remarks}</div>
              </div>
            );
          })}
        </div>
      </Dialog>
      <Dialog
        visible={assigneedialog}
        modal
        className="p-fluid"
        onHide={() => {
          setAssigneeDialog(false);
        }}
        style={{ width: "65%" }}
        header={"Assignees "}
      >
        <div>
          <Accordion multiple activeIndex={[0]}>
            <AccordionTab
              header={
                "Submitter " +
                (assigneeobj.reporter_ids
                  ? `(${assigneeobj.reporter_ids.length})`
                  : "")
              }
            >
              {userList
                .filter((i) => assigneeobj.reporter_ids.includes(i.id))
                .map((i, index) => {
                  return (
                    <p>
                      {index + 1} . {i.information.empname}{" "}
                    </p>
                  );
                })}
            </AccordionTab>
            {assigneeobj.reviewer_ids &&
              assigneeobj.reviewer_ids.length !== 0 && (
                <AccordionTab
                  header={
                    "Reviewer " +
                    (assigneeobj.reviewer_ids
                      ? `(${assigneeobj.reviewer_ids.length})`
                      : "")
                  }
                >
                  {userList
                    .filter((i) => assigneeobj.reviewer_ids.includes(i.id))
                    .map((i, index) => {
                      return (
                        <p>
                          {index + 1} . {i.information.empname}{" "}
                        </p>
                      );
                    })}
                </AccordionTab>
              )}
          </Accordion>
        </div>
      </Dialog>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(QuantitativeReporterOverallNew, comparisonFn);
