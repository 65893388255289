import React, { useEffect, useState } from "react";
import APIServices from "../../service/APIService";
import { API } from "../../constants/api_url";
import { useSelector } from "react-redux";
import { DateTime } from "luxon";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { Button } from "primereact/button";
import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import FileSaver from "file-saver";

const OverdueMailReport = () => {
    const userList = useSelector(state => state.userlist.userList)
    const login_data = useSelector((state) => state.user.userdetail)
    const admin_data = useSelector((state) => state.user.admindetail)
    const [past, setPast] = useState([])
    const [show, setShow] = useState(null)
    const navigate = useHistory()
    const frequency_list = [{ name: 'Monthly', id: 1 }, { name: 'Bi-Monthly', id: 2 }, { name: 'Quartely', id: 3 }, { name: 'Annually', id: 4 }, { name: 'Bi-Annually', id: 5 }, { name: 'Undefined', id: 6 }]
    useEffect(() => {
        console.log(localStorage.getItem('token'))
        if (localStorage.getItem('token') === null) {
            navigate.push({ pathname: '/', state: { from: { pathname: '/reminder' } } })
        } else {
            let uriString = {
                "include": [{ "relation": "locationTwos", "scope": { "include": [{ "relation": "locationThrees" }] } }]

            }
            let uriString2 = {
                "include": [{ "relation": "newTopics", "scope": { "include": [{ "relation": "newMetrics", "scope": { "include": [{ "relation": "newDataPoints" }] } }] } }]


            }
            let site_url = API.LocationOne_UP(admin_data.id) + `?filter=${encodeURIComponent(JSON.stringify(uriString))}`;
            const promise0 = APIServices.get(API.AssignDCFUser_UP(admin_data.id))
            const promise1 = APIServices.get(API.AssignDCFClient_UP(admin_data.id))
            const promise2 = APIServices.get(API.LocationOne_UP(admin_data.id))
            const promise3 = APIServices.get(API.DCF)
            const promise4 = APIServices.get(API.DCF_Submit_UP(admin_data.id))

            let Overall = API.Categories + `?filter=${encodeURIComponent(JSON.stringify(uriString2))}`;
            const promise5 = APIServices.get(Overall)
            const promise6 = APIServices.get(site_url)
            Promise.all([promise0, promise1, promise2, promise3, promise4, promise5, promise6]).then(function (values) {
                let dcf_list = values[3].data, ass_dcf = [], dcf_submitted = values[4].data
                let site_list = values[6].data.map(item => {
                    if (item.locationTwos) {
                        item.locationTwos = item.locationTwos.filter(locationTwo =>
                            locationTwo.locationThrees && locationTwo.locationThrees.length > 0
                        );
                    }
                    return item;
                }).filter(item => item.locationTwos && item.locationTwos.length > 0)
                let site_ids = site_list.flatMap(i => i.locationTwos.flatMap(j => j.locationThrees.map(k => k.id)))
                let all_site_list = site_list.flatMap(i => i.locationTwos.flatMap(j => j.locationThrees.map(k => k)))
                if (Array.isArray(values[1].data)) {
                    values[5].data.forEach((cat) => {
                        if (cat.newTopics !== undefined && cat.newTopics.length) {
                            cat.newTopics.forEach((top) => {
                                if (top.newMetrics !== undefined && top.newMetrics.length) {
                                    top.newMetrics.forEach((met) => {
                                        if (values[1].data[0].metric_ids.includes(met.id) && met.newDataPoints !== undefined && met.newDataPoints.length && Array.isArray(met.data1) && met.data1[0] !== undefined && met.data1[0].type === 0 && met.data1[0].source === 1) {
                                            met.newDataPoints.forEach((ndp) => {
                                                if (Array.isArray(ndp.data1) && ndp.data1[0] !== undefined && ndp.data1[0].datasource !== undefined && ndp.data1[0].datasource !== null && dcf_list.map(i => i.id).includes(ndp.data1[0].datasource)) {
                                                    if (!ass_dcf.includes(ndp.data1[0].datasource)) {
                                                        ass_dcf.push(ndp.data1[0].datasource)
                                                    }
                                                }
                                            })

                                        }
                                    })
                                }
                            })
                        }
                    })


                    values[0].data.forEach((item) => {
                        if (item.type === 0 && ass_dcf.includes(item.dcfId) && site_ids.includes(item.site) && item.user_id === login_data.id) {
                            item.dcf_ = dcf_list.filter((k) => { return k.id === item.dcfId })[0]
                            item.site_ = all_site_list.filter((k) => { return k.id === item.site })[0]
                            let reviewer_index = userList.findIndex((k) => { return k.id === item.reviewer_id })
                            if (reviewer_index === -1) {
                                item.reviewer = 'Not Found'
                            } else {
                                item.reviewer = userList[reviewer_index].information.empname
                            }
                            item.frequency_ = frequency_list.filter((k) => { return k.id === item.frequency })[0]

                            let st_date = DateTime.fromISO(item.start_date, { zone: 'utc' }).toLocal(), ed_date = DateTime.utc().toLocal()
                            if (typeof item.end_date === 'string') {
                                ed_date = DateTime.fromISO(item.end_date, { zone: 'utc' }).toLocal()
                            }


                            getMonthsLuxon(st_date, ed_date, item.frequency, item, dcf_submitted)

                        }
                        if (item.type === 0 && ass_dcf.includes(item.dcfId) && site_ids.includes(item.site) && item.reviewer_id === login_data.id && item.user_id !== login_data.id) {
                            let data = dcf_submitted.filter(i => { return i.type === 1 && i.dcf === item.dcfId && i.site === item.site })
                            data.forEach((item) => {
                                item.dcf = dcf_list.filter((k) => { return k.id === item.dcfId })[0]
                                item.site_ = all_site_list.filter((k) => { return k.id === item.site })[0]
                                item.rp = getRPMonth(item.reporting_period)
                                item.duedate = getDueMonth(item.reporting_period).month
                                item.overdue = getDueMonth(item.reporting_period).due
                            })
                        }
                    })
                }

            })
            setShow(true)
        }

    }, [])
    useEffect(() => {
        console.log(past)
    }, [past])
    const getMonthsLuxon = (startDate, endDate, frequency, item, old) => {

        var betweenMonths = [];
        let type = 0, past_ = [], present_ = [], future_ = []

        past_ = JSON.parse(JSON.stringify(past))


        if (Math.round(startDate.diff(DateTime.utc().startOf('month'), 'days').days).toFixed(0) === 0) {
            let endDate_ = startDate

            let endDate__ = DateTime.utc().toLocal()
            if (frequency === 1) {

            } else if (frequency === 6) {
                let check = checkSubmission(item.dcf_, item.site, endDate__.toFormat('LLL-yyyy'), old)

                if (check.result) {
                    past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, site_: item.site_, reviewer: item.reviewer, dcftype: item.dcf_.type, rp: endDate__.toFormat('LLL-yyyy'), duedate: endDate__.toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(endDate__, 'days').days), site: item.site, company_id: admin_data.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                } else {
                    if (check.data) {
                        past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, site_: item.site_, reviewer: item.reviewer, dcftype: item.dcf_.type, rp: endDate__.toFormat('LLL-yyyy'), duedate: endDate__.toFormat('LLL-yyyy'), overdue: 0, site: item.site, type: 6, company_id: admin_data.id, frequency })
                    }

                }


            }

        }
        else if (Math.round(startDate.diff(DateTime.utc().startOf('month'), 'days').days) < 31) {
            var date = startDate
            console.log(startDate.diff(endDate.startOf('month'), 'days').days)


            let endDate__ = DateTime.utc().toLocal()
            while (date <= endDate) {

                betweenMonths.push(date.toFormat('LLL-yyyy'));
                date = date.plus({ months: 1 })

            }
            console.log(betweenMonths, item.id)
            if (frequency === 1) {
                console.log(splitArray(betweenMonths, 1), 'SP')
                splitArray(betweenMonths, 1).forEach((months, ind) => {
                    console.log(endDate__.month === DateTime.fromFormat(months[0], 'LLL-yyyy').month && endDate__.year === DateTime.fromFormat(months[0], 'LLL-yyyy').year)
                    if (endDate__.month === DateTime.fromFormat(months[0], 'LLL-yyyy').month && endDate__.year === DateTime.fromFormat(months[0], 'LLL-yyyy').year) {

                    } else {

                        if (Math.round(endDate__.diff(DateTime.fromFormat(months[0], 'LLL-yyyy'), 'months').months) === 1) {

                            // if (endDate__ <= moment(months[0]).add(1, 'month').add(4, 'days')) {
                            let check = checkSubmission(item.dcf_, item.site, months[0], old)


                            if (check.result) {


                                past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, site_: item.site_, reviewer: item.reviewer, dcftype: item.dcf_.type, rp: months[0], duedate: DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: admin_data.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                            } else {
                                if (check.data) {
                                    past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, site_: item.site_, reviewer: item.reviewer, dcftype: item.dcf_.type, rp: months[0], duedate: DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: admin_data.id, frequency })

                                }

                            }


                        } else {
                            let check = checkSubmission(item.dcf_, item.site, months[0], old)
                            console.log(check, months[0])
                            if (check.result) {


                                past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, site_: item.site_, reviewer: item.reviewer, dcftype: item.dcf_.type, rp: months[0], duedate: DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: admin_data.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                            } else {
                                if (check.data) {

                                    past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, site_: item.site_, reviewer: item.reviewer, dcftype: item.dcf_.type, rp: months[0], duedate: DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: admin_data.id, frequency })

                                }

                            }
                        }
                    }

                })
            } else if (frequency === 2) {

                splitArray(betweenMonths, 2).forEach((months) => {
                    if (months.length === 2) {
                        if (endDate__.month === DateTime.fromFormat(months[1], 'LLL-yyyy').month && endDate__.year === DateTime.fromFormat(months[1], 'LLL-yyyy').year) {



                        } else {
                            if (Math.round(endDate__.diff(DateTime.fromFormat(months[1], 'LLL-yyyy'), 'months').months) === 1) {

                                // if (endDate__ <= moment(months[1]).add(1, 'month').add(4, 'days')) {
                                let check = checkSubmission(item.dcf_, item.site, months[0] + ' to ' + months[1], old)
                                if (check.result) {

                                    past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, site_: item.site_, reviewer: item.reviewer, dcftype: item.dcf_.type, rp: months[0] + ' to ' + months[1], duedate: DateTime.fromFormat(months[1], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[1], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: admin_data.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })

                                } else {
                                    if (check.data) {
                                        past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, site_: item.site_, reviewer: item.reviewer, dcftype: item.dcf_.type, rp: months[0] + ' to ' + months[1], duedate: DateTime.fromFormat(months[1], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[1], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: admin_data.id, frequency })


                                    }

                                }


                            } else {
                                let check = checkSubmission(item.dcf_, item.site, months[0] + ' to ' + months[1], old)

                                if (check.result) {
                                    past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, site_: item.site_, reviewer: item.reviewer, dcftype: item.dcf_.type, rp: months[0] + ' to ' + months[1], duedate: DateTime.fromFormat(months[1], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[1], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: admin_data.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })

                                } else {
                                    if (check.data) {
                                        past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, site_: item.site_, reviewer: item.reviewer, dcftype: item.dcf_.type, rp: months[0] + ' to ' + months[1], duedate: DateTime.fromFormat(months[1], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[1], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: admin_data.id, frequency })

                                    }

                                }
                            }
                        }
                    }
                })
            } else if (frequency === 3) {

                splitArray(betweenMonths, 3).forEach((months) => {
                    if (months.length === 3) {

                        if (endDate__.month === DateTime.fromFormat(months[2], 'LLL-yyyy').month && endDate__.year === DateTime.fromFormat(months[2], 'LLL-yyyy').year) {





                        } else {
                            if (Math.round(endDate__.diff(DateTime.fromFormat(months[2], 'LLL-yyyy'), 'months').months) === 1) {

                                // if (endDate__ <= moment(months[2]).add(1, 'month').add(4, 'days')) {
                                let check = checkSubmission(item.dcf_, item.site, months[0] + ' to ' + months[2], old)

                                if (check.result) {
                                    past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, site_: item.site_, reviewer: item.reviewer, dcftype: item.dcf_.type, rp: months[0] + ' to ' + months[2], duedate: DateTime.fromFormat(months[2], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[2], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: admin_data.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                                } else {
                                    if (check.data) {
                                        past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, site_: item.site_, reviewer: item.reviewer, dcftype: item.dcf_.type, rp: months[0] + ' to ' + months[2], duedate: DateTime.fromFormat(months[2], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[2], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: admin_data.id, frequency })

                                    }

                                }



                            } else {

                                let check = checkSubmission(item.dcf_, item.site, months[0] + ' to ' + months[2], old)

                                if (check.result) {
                                    past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, site_: item.site_, reviewer: item.reviewer, dcftype: item.dcf_.type, rp: months[0] + ' to ' + months[2], duedate: DateTime.fromFormat(months[2], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[2], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: admin_data.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                                } else {
                                    if (check.data) {
                                        console.log(item.site, item.dcf_, months[0] + ' to ' + months[2])
                                        past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, site_: item.site_, reviewer: item.reviewer, dcftype: item.dcf_.type, rp: months[0] + ' to ' + months[2], duedate: DateTime.fromFormat(months[2], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[2], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: admin_data.id, frequency })

                                    }

                                }
                            }
                        }
                    }
                })
            } else if (frequency === 4) {
                splitArray(betweenMonths, 12).forEach((months) => {
                    if (months.length === 12) {
                        if (endDate__.month === DateTime.fromFormat(months[11], 'LLL-yyyy').month && endDate__.year === DateTime.fromFormat(months[11], 'LLL-yyyy').year) {





                        } else {
                            if (Math.round(endDate__.diff(DateTime.fromFormat(months[11], 'LLL-yyyy'), 'months').months) === 1) {

                                // if (endDate__ <= moment(months[11]).add(1, 'month').add(4, 'days')) {

                                let check = checkSubmission(item.dcf_, item.site, months[0] + ' to ' + months[11], old)

                                if (check.result) {

                                    past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, site_: item.site_, reviewer: item.reviewer, dcftype: item.dcf_.type, rp: months[0] + ' to ' + months[11], duedate: DateTime.fromFormat(months[11], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[11], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: admin_data.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })

                                } else {
                                    if (check.data) {
                                        past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, site_: item.site_, reviewer: item.reviewer, dcftype: item.dcf_.type, rp: months[0] + ' to ' + months[11], duedate: DateTime.fromFormat(months[11], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[11], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: admin_data.id, frequency })

                                    }

                                }


                            } else {
                                let check = checkSubmission(item.dcf_, item.site, months[0] + ' to ' + months[11], old)

                                if (check.result) {

                                    past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, site_: item.site_, reviewer: item.reviewer, dcftype: item.dcf_.type, rp: months[0] + ' to ' + months[11], duedate: DateTime.fromFormat(months[11], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[11], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: admin_data.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })

                                } else {
                                    if (check.data) {
                                        past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, site_: item.site_, reviewer: item.reviewer, dcftype: item.dcf_.type, rp: months[0] + ' to ' + months[11], duedate: DateTime.fromFormat(months[11], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[11], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: admin_data.id, frequency })

                                    }

                                }
                            }
                        }
                    }
                })
            } else if (frequency === 5) {
                splitArray(betweenMonths, 6).forEach((months) => {
                    if (months.length === 6) {
                        if (endDate__.month === DateTime.fromFormat(months[5], 'LLL-yyyy').month && endDate__.year === DateTime.fromFormat(months[5], 'LLL-yyyy').year) {



                        } else {
                            if (Math.round(endDate__.diff(DateTime.fromFormat(months[5], 'LLL-yyyy'), 'months').months) === 1) {

                                // if (endDate__ <= moment(months[5]).add(1, 'month').add(4, 'days')) {
                                let check = checkSubmission(item.dcf_, item.site, months[0] + ' to ' + months[5], old)

                                if (check.result) {


                                    past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, site_: item.site_, reviewer: item.reviewer, dcftype: item.dcf_.type, rp: months[0] + ' to ' + months[5], duedate: DateTime.fromFormat(months[5], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[5], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: admin_data.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                                } else {
                                    if (check.data) {
                                        past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, site_: item.site_, reviewer: item.reviewer, dcftype: item.dcf_.type, rp: months[0] + ' to ' + months[5], duedate: DateTime.fromFormat(months[5], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[5], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: admin_data.id, frequency })

                                    }

                                }


                            } else {
                                let check = checkSubmission(item.dcf_, item.site, months[0] + ' to ' + months[5], old)

                                if (check.result) {


                                    past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, site_: item.site_, reviewer: item.reviewer, dcftype: item.dcf_.type, rp: months[0] + ' to ' + months[5], duedate: DateTime.fromFormat(months[5], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[5], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: admin_data.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                                } else {
                                    if (check.data) {
                                        past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, site_: item.site_, reviewer: item.reviewer, dcftype: item.dcf_.type, rp: months[0] + ' to ' + months[5], duedate: DateTime.fromFormat(months[5], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[5], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: admin_data.id, frequency })

                                    }

                                }
                            }
                        }
                    }
                })
            } else if (frequency === 6) {
                splitArray(betweenMonths, 1).forEach((months, ind) => {

                    if (endDate__.month === DateTime.fromFormat(months[0], 'LLL-yyyy').month && endDate__.year === DateTime.fromFormat(months[0], 'LLL-yyyy').year) {
                        let check = checkSubmission(item.dcf_, item.site, months[0], old)

                        if (check.result) {
                            past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, site_: item.site_, reviewer: item.reviewer, dcftype: item.dcf_.type, dcftype: item.dcf_.type, rp: months[0], duedate: DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[0], 'LLL-yyyy'), 'days').days), site: item.site, company_id: admin_data.id, frequency })

                            check.list.forEach((list) => {
                                past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, site_: item.site_, reviewer: item.reviewer, dcftype: item.dcf_.type, dcftype: item.dcf_.type, rp: months[0], duedate: DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[0], 'LLL-yyyy'), 'days').days), site: item.site, company_id: admin_data.id, frequency, response: list.response, id: list.id, reject: list.reject, draft: list })

                            })

                        } else {
                            if (check.data) {
                                past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, site_: item.site_, reviewer: item.reviewer, dcftype: item.dcf_.type, dcftype: item.dcf_.type, rp: months[0], duedate: DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[0], 'LLL-yyyy'), 'days').days), site: item.site, company_id: admin_data.id, frequency })

                            }

                        }


                    } else {
                        let check = checkSubmission(item.dcf_, item.site, months[0], old)

                        if (check.result) {

                            check.list.forEach((list) => {
                                past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, site_: item.site_, reviewer: item.reviewer, dcftype: item.dcf_.type, rp: months[0], duedate: DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[0], 'LLL-yyyy'), 'days').days), site: item.site, company_id: admin_data.id, frequency, response: list.response, id: list.id })

                            })


                        } else {
                            if (check.data) {
                                past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, site_: item.site_, reviewer: item.reviewer, dcftype: item.dcf_.type, rp: months[0], duedate: months[0], overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[0], 'LLL-yyyy'), 'days').days), site: item.site, company_id: admin_data.id, frequency })

                            }

                        }


                    }

                })
            }

        }


        setPast((prev) => ([...prev, ...past_]))




        return { type, months: betweenMonths }
    }
    const getRPMonth = (item) => {

        if (item.length !== 0) {
            if (item.length >= 2) {
                const startDate = DateTime.fromFormat(item[0], 'MM-yyyy').toFormat('MMM-yyyy');
                const endDate = DateTime.fromFormat(item[item.length - 1], 'MM-yyyy').toFormat('MMM-yyyy');
                return `${startDate} to ${endDate}`;
            } else {
                return DateTime.fromFormat(item[0], 'MM-yyyy').toFormat('MMM-yyyy');
            }
        }
    }
    const getDueMonth = (item) => {

        if (item.length !== 0) {
            if (item.length >= 2) {
                const startDate = DateTime.fromFormat(item[0], 'MM-yyyy').toFormat('MMM-yyyy');
                const endDate = DateTime.fromFormat(item[item.length - 1], 'MM-yyyy').plus(1, 'month').toFormat('MMM-yyyy');
                return { due: DateTime.utc().diff(endDate).days, month: endDate }
            } else {
                return { due: DateTime.utc().diff(DateTime.fromFormat(item[0], 'MM-yyyy').plus(1, 'month').toFormat('MMM-yyyy')).days, month: DateTime.fromFormat(item[0], 'MM-yyyy').plus(1, 'month').toFormat('MMM-yyyy') }
            }
        }
    }
    const checkSubmission = (dcf, site, rp, old) => {
        // let rps = getRP(rp)
        let rps = getRPLuxon(rp)

        let result = {}
        let loc = JSON.parse(JSON.stringify(old))

        let index = loc.findIndex((i) => {
            return compareArrays(i.reporting_period, rps) && i.site === site && i.dcf === dcf.id && i.type === 0
        })
        let index2 = loc.findIndex((i) => {
            return compareArrays(i.reporting_period, rps) && i.site === site && i.dcf === dcf.id && (i.type === 1 || i.type === 2 || i.type === 3)
        })

        if (index === -1) {

            result = { result: false, data: index2 === -1 ? true : false }
        } else {
            result = {
                result: true, data: loc[index], list: loc.filter((i) => {
                    return compareArrays(i.reporting_period, rps) && i.site === site && i.dcf === dcf.id && i.type === 0
                })
            }
        }
        console.log(old, rps, dcf, site, result)
        return result
    }
    const getRPLuxon = (months) => {
        if (months.includes('to')) {
            let startDate = DateTime.fromFormat(months.split('to')[0].trim(), 'LLL-yyyy')
            let endDate = DateTime.fromFormat(months.split('to')[1].trim(), 'LLL-yyyy')
            let rp = []
            while (startDate <= endDate) {
                rp.push(startDate.toFormat('LL-yyyy'));
                startDate = startDate.plus({ months: 1 })
            }
            return rp
        } else {
            return [DateTime.fromFormat(months, 'LLL-yyyy').toFormat('LL-yyyy')]
        }
    }
    const compareArrays = (a, b) => {


        return JSON.stringify(a) === JSON.stringify(b);
    };
    const splitArray = (array, chunkSize) => {


        return array.reduce((resultArray, item, index) => {
            const chunkIndex = Math.floor(index / chunkSize)

            if (!resultArray[chunkIndex]) {
                resultArray[chunkIndex] = [] // start a new chunk
            }

            resultArray[chunkIndex].push(item)

            return resultArray
        }, [])
    }
    const exportOverdueReport = () => {
        let loc = [];
        let obj = { Form: '' };

        past.forEach((l) => {
            obj = {};
            obj['Form'] = l.dcf.title
            obj['Site'] = l.site_.name
            obj['Reporting Period'] = l.rp
            obj['Due Month'] = l.duedate
            obj['Overdue Days'] = l.overdue
            obj['Reviewer'] = l.reviewer
            loc.push(obj);

        });

        const path = require('path')
        const os = require('os')
        const homedir = os.homedir()
        const downloadFolder = path.join(homedir, 'Downloads')
        const filename = path.join(downloadFolder, 'hellow.xlsx')
        const ws = XLSX.utils.json_to_sheet(loc);
        // const range = { s: { c: 0, r: 0 }, e: { c: sitelist.length, r: dcflist.length } }

        const wb = {
            Sheets: { ["Overdue"]: ws },
            SheetNames: ["Overdue"],
        };
        const worksheet = wb.Sheets['Overdue']
        // {style:{type:'pattern', patternType: 'solid', fgColor: { rgb: 'FFFF0000' } }}
        const fill = { type: 'pattern', patternType: 'solid', fgColor: { rgb: 'FFFF0000' } }
        let style = []
        // for (let row = range.s.r; row <= range.e.r; row++) {
        //     for (let col = range.s.c; col <= range.e.c; col++) {

        //         let cellAddress = XLSX.utils.encode_cell({ r: row, c: col })
        //         if (worksheet[cellAddress].v === 'Unassigned') {
        //             style.push({ range: XLSX.utils.encode_range({ s: cellAddress, e: cellAddress }) + ':' + XLSX.utils.encode_range({ s: cellAddress, e: cellAddress }), color: 'FFFF0000' })

        //         } else if (worksheet[cellAddress].v.toLowerCase().includes('justified -')) {
        //             style.push({ range: XLSX.utils.encode_range({ s: cellAddress, e: cellAddress }) + ':' + XLSX.utils.encode_range({ s: cellAddress, e: cellAddress }), color: '097969' })

        //         }
        //     }
        // }
        //  XLSX.writeFile(wb,filename)

        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

        const data = new Blob([excelBuffer], {
            type:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
        });
        XlsxPopulate.fromDataAsync(data).then((workbook) => {
            // workbook.sheets().forEach((sheet) => {

            //     sheet.range(`A1:${XLSX.utils.encode_col(sitelist.length)}1`).style({

            //         fontColor: 'ffffff',
            //         fill: '6082B6'

            //     });
            // style.forEach((cell) => {

            //     sheet.range(cell.range).style({

            //         fontColor: cell.color,
            //         bold: cell.color === 'FFFF0000' ? false : true

            //     });
            // })


            // })
            workbook
                .outputAsync()
                .then((workbookBlob) =>
                    FileSaver.saveAs(URL.createObjectURL(workbookBlob), DateTime.utc().toFormat('dd_LL_yyyy') + "_Overdue_Report_.xlsx")
                )


        })

    }
    return (<div style={{ height: 'calc(100vh - 100px' }} className="flex justify-content-center align-items-center" >
        {show ?
            <div  >
                {past.length ?
                    <div className="flex justify-content-center" style={{flexDirection:'column'}}>
                        <Button style={{ width: 'auto' }} label="Click to Download Overdue Report" onClick={exportOverdueReport} />
                        <Button style={{ width: 'auto' }} className="mt-3" label="Go to Home" onClick={()=>{ navigate.push({ pathname: '/client_user/homescreen'})}} />
                    </div>
                    :
                    <div>No Overdue Submissions </div>

                }
            </div>
            :
            <div className="flex align-items-center">
                <i className="pi pi-spinner pi-spin " style={{ fontSize: 20 }} />
                <label className="col-12 flex ">loading ...</label>
            </div>

        }
    </div>)
}

export default OverdueMailReport