import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

const waterData = {
    "columns": [
        { "header": "Water (million m3)", "field": "category" },
        { "header": "2019", "field": "2019" },
        { "header": "2020", "field": "2020" },
        { "header": "2021", "field": "2021" },
        { "header": "2022", "field": "2022" },
        { "header": "2023", "field": "2023" },
        { "header": "2021-2022", "field": "2021_2022" },
        { "header": "2019-2022", "field": "2019_2022" },
        { "header": "GRI Indicator", "field": "gri_indicator" }
    ],
    "data": [
        { "category": "Water withdrawal excluding non-contact cooling water", "2019": 13.5, "2020": 12.9, "2021": 12.7, "2022": 12.3, "2023": 12.3, "2021_2022": "↑ 3%", "2019_2022": "↓ 9%", "gri_indicator": "303-3" },
        { "category": "Water withdrawal including non-contact cooling water", "2019": 34.8, "2020": 32.4, "2021": 27.5, "2022": 28.8, "2023": 28.8, "2021_2022": "↑ 5%", "2019_2022": "↓ 17%", "gri_indicator": "303-3" },
        { "category": "Third party water", "2019": 8.7, "2020": 8.2, "2021": 8.0, "2022": 8.0, "2023": 8.0, "2021_2022": "• 0%", "2019_2022": "↓ 8%", "gri_indicator": "303-3" },
        { "category": "Surface water withdrawal", "2019": 0.8, "2020": 0.8, "2021": 0.9, "2022": 0.9, "2023": 0.9, "2021_2022": "↑ 4%", "2019_2022": "↑ 10%", "gri_indicator": "303-3" },
        { "category": "Groundwater withdrawal", "2019": 25.3, "2020": 23.3, "2021": 18.6, "2022": 19.9, "2023": 19.9, "2021_2022": "↑ 7%", "2019_2022": "↓ 21%", "gri_indicator": "303-3" },
        { "category": "Brackish/ seawater withdrawal", "2019": 0, "2020": 0, "2021": 0, "2022": 0, "2023": 0, "2021_2022": "↑ 4%", "2019_2022": "↓ 9%", "gri_indicator": "303-4" },
        { "category": "Water discharged", "2019": 31.7, "2020": 29.4, "2021": 24.6, "2022": 25.8, "2023": 25.8, "2021_2022": "↑ 5%", "2019_2022": "↓ 19%", "gri_indicator": "303-4" },
        { "category": "Water consumed", "2019": 3.0, "2020": 3.0, "2021": 2.9, "2022": 3.0, "2023": 3.0, "2021_2022": "↑ 2%", "2019_2022": "%", "gri_indicator": "303-4" }
    ]
};

const wasteData = {
    "columns": [
        { "header": "Waste", "field": "category" },
        { "header": "2021", "field": "2021" },
        { "header": "2022", "field": "2022" },
        { "header": "2020", "field": "2020" },
        { "header": "2020", "field": "2020_2" },
        { "header": "2020", "field": "2020_3" },
        { "header": "2020", "field": "2020_4" },
        { "header": "2020", "field": "2020_5" },
        { "header": "2021-2022", "field": "2021_2022" },
        { "header": "2019-2022", "field": "2019_2022" },
        { "header": "GRI Indicator", "field": "gri_indicator" }
    ],
    "data": [
        { "category": "Hazardous waste generated (million kg)", "2021": 86.4, "2022": 83.1, "2020": 75.1, "2020_2": 76.7, "2020_3": 76.7, "2020_4": 76.7, "2020_5": 76.7, "2021_2022": "↑ 2%", "2019_2022": "↓ 11%", "gri_indicator": "306-3" },
        { "category": "Hazardous waste diverted from disposal (million kg)", "2021": 16.8, "2022": 11.6, "2020": 11.9, "2020_2": 7.5, "2020_3": 7.5, "2020_4": 7.5, "2020_5": 7.5, "2021_2022": "↑ 37%", "2019_2022": "↓ 55%", "gri_indicator": "306-4" },
        { "category": "Hazardous waste incinerated with energy recovery (million kg)", "2021": 39.8, "2022": 43.6, "2020": 41.6, "2020_2": 47.6, "2020_3": 47.6, "2020_4": 47.6, "2020_5": 47.6, "2021_2022": "↑ 14%", "2019_2022": "↓ 20.8%", "gri_indicator": "306-5" },
        { "category": "Hazardous waste incinerated without energy recovery (million kg)", "2021": 22.2, "2022": 23.1, "2020": 17.7, "2020_2": 17.1, "2020_3": 17.1, "2020_4": 17.1, "2020_5": 17.1, "2021_2022": "↑ 4%", "2019_2022": "↓ 23%", "gri_indicator": "306-5" },
        { "category": "Hazardous waste landfilled (million kg)", "2021": 0.2, "2022": 0.1, "2020": 0.1, "2020_2": 0, "2020_3": 0, "2020_4": 0, "2020_5": 0, "2021_2022": "↑ 28%", "2019_2022": "↓ 76%", "gri_indicator": "306-5" },
        { "category": "Hazardous waste other treatment (million kg)", "2021": 7.4, "2022": 4.7, "2020": 3.9, "2020_2": 4.5, "2020_3": 4.5, "2020_4": 4.5, "2020_5": 4.5, "2021_2022": "↑ 18%", "2019_2022": "↓ 40%", "gri_indicator": "306-5" },
        { "category": "Hazardous waste diverted from disposal (%)", "2021": 19, "2022": 14, "2020": 16, "2020_2": 10, "2020_3": 10, "2020_4": 10, "2020_5": 10, "2021_2022": "-", "2019_2022": "-", "gri_indicator": "306-4" },
        { "category": "Non-hazardous waste generated (million kg)", "2021": 37.7, "2022": 36.9, "2020": 38.5, "2020_2": 35, "2020_3": 35, "2020_4": 35, "2020_5": 35, "2021_2022": "↑ 9%", "2019_2022": "↓ 7%", "gri_indicator": "306-3" },
        { "category": "Non-hazardous waste diverted from disposal (million kg)", "2021": 17.2, "2022": 17.4, "2020": 22.5, "2020_2": 19, "2020_3": 19, "2020_4": 19, "2020_5": 19, "2021_2022": "↑ 16%", "2019_2022": "↓ 11%", "gri_indicator": "306-4" },
        { "category": "Non-hazardous waste incinerated with energy recovery (million kg)", "2021": 4.4, "2022": 4.4, "2020": 4.8, "2020_2": 5, "2020_3": 5, "2020_4": 5, "2020_5": 5, "2021_2022": "↑ 5%", "2019_2022": "↓ 15%", "gri_indicator": "306-5" },
        { "category": "Non-hazardous waste incinerated without energy recovery (million kg)", "2021": 0.9, "2022": 1.3, "2020": 0.6, "2020_2": 0.6, "2020_3": 0.6, "2020_4": 0.6, "2020_5": 0.6, "2021_2022": "↑ 10%", "2019_2022": "↓ 32%", "gri_indicator": "306-5" },
        { "category": "Non-hazardous waste landfilled (million kg)", "2021": 15.1, "2022": 13.7, "2020": 10.2, "2020_2": 10.1, "2020_3": 10.1, "2020_4": 10.1, "2020_5": 10.1, "2021_2022": "↑ 2%", "2019_2022": "↓ 33%", "gri_indicator": "306-5" },
        { "category": "Non-hazardous waste other treatment (million kg)", "2021": 0.1, "2022": 0.2, "2020": 0.2, "2020_2": 0.2, "2020_3": 0.2, "2020_4": 0.2, "2020_5": 0.2, "2021_2022": "↑ 3%", "2019_2022": "↑ 78%", "gri_indicator": "306-5" },
        { "category": "Hazardous waste diverted from disposal (%)", "2021": 46, "2022": 47, "2020": 59, "2020_2": 54, "2020_3": 54, "2020_4": 54, "2020_5": 54, "2021_2022": "-", "2019_2022": "-", "gri_indicator": "306-4" }
    ]
}

const airEmissionData = {
    "columns": [
        { "header": "Air Emissions", "field": "category" },
        { "header": "2021", "field": "2021" },
        { "header": "2022", "field": "2022" },
        { "header": "2020", "field": "2020" },
        { "header": "2020", "field": "2020_2" },
        { "header": "2020", "field": "2020_3" },
        { "header": "2020", "field": "2020_4" },
        { "header": "2020", "field": "2020_5" },
        { "header": "2021-2022", "field": "2021_2022" },
        { "header": "2019-2022", "field": "2019_2022" },
        { "header": "GRI Indicator", "field": "gri_indicator" }
    ],
    "data": [
        { "category": "Refrigerant losses (metric ton (mt)) CO2e", "2021": "-", "2022": "-", "2020": "-20,175", "2020_2": "20,175", "2020_3": "20,175", "2020_4": "20,175", "2020_5": "20,175", "2021_2022": "-", "2019_2022": "-", "gri_indicator": "305-6" },
        { "category": "Volatile organics (thousand kg)", "2021": 371.6, "2022": 379.6, "2020": 295.8, "2020_2": 308.3, "2020_3": 308.3, "2020_4": 308.3, "2020_5": 308.3, "2021_2022": "↑ 4%", "2019_2022": "↓ 17%", "gri_indicator": "305-7" }
    ]
}


const laborData = {
    "columns": [
        { "header": "Labor", "field": "category" },
        { "header": "2019", "field": "2019" },
        { "header": "2020", "field": "2020" },
        { "header": "2021", "field": "2021" },
        { "header": "2022", "field": "2022" }
    ],
    "data": [
        { "category": "Freedom Of Association", "2019": 100.8, "2020": 100.8, "2021": 100.8, "2022": 93.7, "2021_2022": "↓ 7%" },
        { "category": "Freely Chosen Employment", "2019": 2021, "2020": 2021, "2021": 2021, "2022": 2022, "2021_2022": "YOY%" },
        { "category": "Humane Treatment", "2019": 100.8, "2020": 100.8, "2021": 100.8, "2022": 93.7, "2021_2022": "↓ 7%" },
        { "category": "Nondiscrimination", "2019": 100.8, "2020": 100.8, "2021": 100.8, "2022": 93.7, "2021_2022": "↓ 7%" },
        { "category": "Sub Contractor And Next-Tier Supplier Responsibility", "2019": 100.8, "2020": 100.8, "2021": 100.8, "2022": 93.7, "2021_2022": "↓ 7%" },
        { "category": "Wages And Benefits", "2019": 100.8, "2020": 100.8, "2021": 100.8, "2022": 93.7, "2021_2022": "↓ 7%" },
        { "category": "Worker Grievance/ Complaint Mechanism", "2019": 100.8, "2020": 100.8, "2021": 100.8, "2022": 93.7, "2021_2022": "↓ 7%" },
        { "category": "Working Hours", "2019": 100.8, "2020": 100.8, "2021": 100.8, "2022": 93.7, "2021_2022": "↓ 7%" },
        { "category": "Young Workers", "2019": 100.8, "2020": 100.8, "2021": 100.8, "2022": 93.7, "2021_2022": "↓ 7%" }
    ]
}

const ethicalData = {
    "columns": [
        { "header": "Ethical Behavior", "field": "category" },
        { "header": "2020", "field": "2020" },
        { "header": "2021", "field": "2021" },
        { "header": "2022", "field": "2022" }
    ],
    "data": [
        { "category": "Business Integrity", "2020": 100.8, "2021": 100.8, "2022": 93.7, "2021_2022": "↓ 7%" },
        { "category": "Transparency", "2020": 2021, "2021": 2021, "2022": 2022, "2021_2022": "YOY%" }
    ]
}

const healthData = {
    "columns": [
        { "header": "Health And Safety", "field": "category" },
        { "header": "2020", "field": "2020" },
        { "header": "2021", "field": "2021" },
        { "header": "2022", "field": "2022" }
    ],
    "data": [
        { "category": "Emergency Preparedness And Response", "2020": 100.8, "2021": 100.8, "2022": 93.7, "2021_2022": "↓ 7%" },
        { "category": "Industrial Hygiene", "2020": 2021, "2021": 2021, "2022": 2022, "2021_2022": "YOY%" },
        { "category": "Machine Safeguarding", "2020": 100.8, "2021": 100.8, "2022": 93.7, "2021_2022": "↓ 7%" },
        { "category": "Sanitation, Dormitory, And Canteen", "2020": 100.8, "2021": 100.8, "2022": 93.7, "2021_2022": "↓ 7%" }
    ]
}


const arrowTemplate = (rowData, column) => {
    let value = rowData[column.field];
    let color;
    if (value.includes('↑')) {
        color = 'green';
    } else if (value.includes('↓')) {
        color = 'red';
    } else if (value.includes('•')) {
        color = 'palegoldenrod';
    }

    return (
        <span style={{ color: color }}>
            {value}
        </span>
    );
};

const EnvironmentMetrics = () => {
    const Legend = () => {
        return (
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                <div style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
                    <i className="pi pi-arrow-up" style={{ color: 'green', marginRight: '5px' }}></i> Increase
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
                    <i className="pi pi-arrow-down" style={{ color: 'red', marginRight: '5px' }}></i> Decrease
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <i className="pi pi-circle-on" style={{ color: 'palegoldenrod', marginRight: '5px' }}></i> No Change
                </div>
            </div>
        );
    };

    return (
        <div className="">
            <Legend />
            <DataTable value={waterData.data}>
                {waterData.columns.map((col, index) => (
                    <Column
                        key={index}
                        field={col.field}
                        header={col.header}
                        body={col.field.includes('2021_2022') || col.field.includes('2019_2022') ? arrowTemplate : null}
                    />
                ))}
            </DataTable>
            <br />
            <DataTable value={wasteData.data}>
                {wasteData.columns.map((col, index) => (
                    <Column
                        key={index}
                        field={col.field}
                        header={col.header}
                        body={col.field.includes('2021_2022') || col.field.includes('2019_2022') ? arrowTemplate : null}
                    />
                ))}
            </DataTable>

            <br />
            <DataTable value={airEmissionData.data}>
                {airEmissionData.columns.map((col, index) => (
                    <Column
                        key={index}
                        field={col.field}
                        header={col.header}
                        body={col.field.includes('2021_2022') || col.field.includes('2019_2022') ? arrowTemplate : null}
                    />
                ))}
            </DataTable>

            <br />
            <DataTable value={laborData.data}>
                {laborData.columns.map((col, index) => (
                    <Column
                        key={index}
                        field={col.field}
                        header={col.header}
                        body={col.field.includes('2021_2022') || col.field.includes('2019_2022') ? arrowTemplate : null}
                    />
                ))}
            </DataTable>

            <br />
            <DataTable value={ethicalData.data}>
                {ethicalData.columns.map((col, index) => (
                    <Column
                        key={index}
                        field={col.field}
                        header={col.header}
                        body={col.field.includes('2021_2022') || col.field.includes('2019_2022') ? arrowTemplate : null}
                    />
                ))}
            </DataTable>

            <br />
            <DataTable value={healthData.data}>
                {healthData.columns.map((col, index) => (
                    <Column
                        key={index}
                        field={col.field}
                        header={col.header}
                        body={col.field.includes('2021_2022') || col.field.includes('2019_2022') ? arrowTemplate : null}
                    />
                ))}
            </DataTable>
        </div>
    );
};

export default EnvironmentMetrics;
