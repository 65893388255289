

import userProfile from "./Login/userProfile";
import { configureStore } from "@reduxjs/toolkit";
import { createLogger } from "redux-logger";
import { combineReducers } from 'redux'
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import pendingData from "./Background/pendingData";
import userProfileList from "./Background/userProfileList";
import siteList from "./Background/siteList";

import RFDCFLibrary from "./Background/RFDCFLibrary";
const persistConfig = {
  key: 'root',
  version: 1,
  storage,
}
const rootReducer = combineReducers({
  user: userProfile, userlist: userProfileList, sitelist: siteList,library: RFDCFLibrary, pendingdata: pendingData

})

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }).concat(createLogger())
})


export default store