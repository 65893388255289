import React, { useEffect, useState } from "react";
import { useLocation, useHistory, useParams } from "react-router-dom/cjs/react-router-dom";
import { motion } from "framer-motion";
import useForceUpdate from "use-force-update";
import LazyView from "../../components/LazyView";
import { DateTime } from "luxon";
import moment from "moment";
import { API } from "../../constants/api_url";
import APIServices from "../../service/APIService";
import { useSelector } from "react-redux";
import { Dropdown } from "primereact/dropdown";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { Tooltip } from "primereact/tooltip";

let activeRowData = {}
const QualitativeApprover = () => {

    const { id } = useParams();
    const navigate = useHistory()
    const [dfuserasslist, setDfUserAssList] = useState([])
    const [dfentityasslist, setDfEntityAssList] = useState([])
    const [done, setDone] = useState(false)
    const userList = useSelector(state => state.userlist.userList)
    const [ass, setAss] = useState([])
    const frequency_list = [{ name: 'Monthly', id: 1 }, { name: 'Bi-Monthly', id: 2 }, { name: 'Quartely', id: 3 }, { name: 'Annually', id: 4 }, { name: 'Bi-Annually', id: 5 }, { name: 'Undefined', id: 6 }]

    const [list, setList] = useState({ metric: [], category: [], topic: [] })

    const [rawsitelist, setRawSiteList] = useState([])
    const selector = useSelector((state) => state.user.userdetail)
    const client_info = useSelector((state) => state.userlist.admindetail)
    const [rfass, setRFAss] = useState([])
    const [rfentityass, setRFEntityAss] = useState([])

    const [historydata, setHistoryData] = useState([])
    const [historydialog, setHistoryDialog] = useState(false)
    const [selectedsite, setSelectedSite] = useState(null)
    const [selected, setSelected] = useState({ category: [], topic: [], framework: [], metric: [] })


    const [rfresponse, setRFResponse] = useState([])

    const forceUpdate = useForceUpdate()

    useEffect(() => {

        if (Object.keys(client_info).length !== 0 && done === false) {

            setDone(true)

            let uriString = {
                "include": [{ "relation": "locationTwos", "scope": { "include": [{ "relation": "locationThrees" }] } }]

            }
            let uriString3 = {
                "include": [{ "relation": "newDataPoints" }]

            }
            let uriString2 = {
                "include": [{ "relation": "newTopics", "scope": { "include": [{ "relation": "newMetrics", "scope": { "include": [{ "relation": "newDataPoints" }] } }] } }]


            }
            APIServices.get(API.AssignDCFUser_UP(selector.clientId)).then((res) => {
                setAss(res.data)
            })
            let Overall = API.Categories + `?filter=${encodeURIComponent(JSON.stringify(uriString2))}`
            let required_rf = [], optional_rf = []
            let site_url = API.LocationOne_UP(selector.clientId) + `?filter=${encodeURIComponent(JSON.stringify(uriString))}`;
            let promise0 = APIServices.get(site_url)
            let promise1 = APIServices.get(Overall)
            let promise2 = APIServices.get(API.RF)
            let promise3 = APIServices.get(API.QL_Submit_UP(selector.clientId))
            let promise4 = APIServices.get(API.Report_Name_Twos)
            let promise5 = APIServices.get(API.QL_Listing_Filter_UP(selector.clientId))
            let promise6 = APIServices.get(API.DF_Entity_User_UP(selector.clientId))
            let promise7 = APIServices.get(API.AssignDCFClient_UP(selector.clientId))
            let promise8 = APIServices.get(API.DF_Entity_UP(selector.clientId))
            Promise.all([promise0, promise1, promise2, promise3, promise4, promise5, promise6, promise7, promise8]).then(function (values) {
                let topic_ids = [], rf_list = values[2].data, qualitative_ass_list = values[6].data, qualitative_entity_list = values[8].data
                console.log(values)
                setDfEntityAssList(qualitative_ass_list)
                setRFResponse(values[3].data)
                if (values[7].data.length !== 0) {

                    topic_ids = values[7].data[0].topic_ids

                }
                if (topic_ids.length) {
                    const shapedSite = values[0].data
                        .map((item) => {
                            if (item.locationTwos) {
                                item.locationTwos = item.locationTwos.filter(
                                    (locationTwo) =>
                                        locationTwo.locationThrees &&
                                        locationTwo.locationThrees.length > 0
                                );
                            }
                            return item;
                        })
                        .filter((item) => item.locationTwos && item.locationTwos.length > 0);
                    console.log(shapedSite)
                    setRawSiteList(shapedSite)
                    const shapedCategory = values[1].data.map(item => {
                        if (item.newTopics) {
                            item.newTopics = item.newTopics.filter(topics =>
                                topics.newMetrics && topics.newMetrics.length > 0
                            );
                        }
                        return item;
                    }).filter(item => item.newTopics && item.newTopics.length > 0)
                    shapedCategory.forEach((cat) => {
                        if (cat.newTopics) {
                            cat.newTopics.forEach((topic) => {
                                if (topic_ids.includes(topic.id) && (topic.tag === null || parseFloat(topic.tag) === selector.id) && topic.newMetrics) {
                                    topic.newMetrics.forEach((metric) => {
                                        if (Array.isArray(metric.data1) && metric.data1[0] !== undefined && metric.data1[0].type === 1 && values[2].data.map(i => i.id).includes(metric.data1[0].rf) && (metric.tag === null || parseFloat(metric.tag) === selector.id)) {
                                            let rf_index = values[2].data.findIndex(i => i.id === metric.data1[0].rf)
                                            if (rf_index !== -1 && (values[2].data[rf_index].tags === null || values[2].data[rf_index].tags.includes(selector.id))) {
                                                required_rf.push({ dfTitle: values[2].data[rf_index].title, dfId: values[2].data[rf_index].id, cat_id: cat.id, top_id: topic.id, overallTags: [metric.data1[0].tags1, metric.data1[0].tags2, metric.data1[0].tags3], cat_title: cat.title, top_title: topic.title, title: metric.title, ...metric })
                                            }


                                        }
                                    })
                                } else if (!topic_ids.includes(topic.id) && topic.newMetrics && (topic.tag === null || parseFloat(topic.tag) === selector.id)) {
                                    topic.newMetrics.forEach((metric) => {
                                        if (Array.isArray(metric.data1) && metric.data1[0] !== undefined && metric.data1[0].type === 1 && values[2].data.map(i => i.id).includes(metric.data1[0].rf) && (metric.tag === null || parseFloat(metric.tag) === selector.id)) {
                                            let rf_index = values[2].data.findIndex(i => i.id === metric.data1[0].rf)
                                            if (rf_index !== -1 && values[2].data[rf_index].tags === null || values[2].data[rf_index].tags.includes(selector.id)) {
                                                optional_rf.push({ dfTitle: values[2].data[rf_index].title, dfId: values[2].data[rf_index].id, cat_id: cat.id, top_id: topic.id, overallTags: [metric.data1[0].tags1, metric.data1[0].tags2, metric.data1[0].tags3], cat_title: cat.title, top_title: topic.title, title: metric.title, ...metric })
                                            }
                                        }
                                    })
                                }
                            }
                            )
                        }
                    })
                    let filtered_qn_ass = qualitative_ass_list.filter(
                        (i) =>
                            (required_rf.map(i => i.dfId).includes(i.dfId) || optional_rf.map(i => i.id).includes(i.dfId)) &&
                            checkEnity(i, qualitative_entity_list.filter(i => i.approver_ids && i.approver_ids.includes(selector.id) ), shapedSite, "dfId")
                    )
                    let rf = [...required_rf, ...optional_rf]

                    let asslist = []
                    console.log(filtered_qn_ass, filtered_qn_ass.filter(i => i.reporter_ids.includes(selector.id)))
                    for (var k = 0; k < filtered_qn_ass.length; k++) {
                        let fd = filtered_qn_ass[k]
                        getMonthsBetween(fd.start_date, fd.end_date, fd.frequency === 4 ? 12 : fd.frequency === 5 ? 6 : fd.frequency).forEach((x) => {
                            asslist.push({ ...fd, reporting_period: x, df: rf.find(x => x.dfId === fd.dfId) })
                        })
                    }
                    console.log(asslist)
                    setDfUserAssList(asslist)


                }


            })


        }
    }, [client_info])


    const checkEnity = (rowData, entity_list, rawsite, obj) => {
        let index = entity_list.findIndex((k) => k[obj] === rowData[obj]);
        if (index !== -1) {
            let entity = entity_list[index];
            console.log(entity);
            if (rowData.level === 0) {
                return entity.tier0_ids.includes(0);
            } else if (rowData.level === 1) {
                return (
                    entity.tier1_ids.includes(rowData.locationId) &&
                    getCoverageText(rowData, rawsite)
                );
            } else if (rowData.level === 2) {
                return (
                    entity.tier2_ids.includes(rowData.locationId) &&
                    getCoverageText(rowData, rawsite)
                );
            } else if (rowData.level === 3) {
                return (
                    entity.tier3_ids.includes(rowData.locationId) &&
                    getCoverageText(rowData, rawsite)
                );
            }
        } else {
            return false;
        }
    };
    function getMonthsBetween(start_date, end_date, frequency) {
        const startDate = DateTime.fromISO(start_date, { zone: 'utc' }).plus({ months: 1 });
        const endDate = end_date ? DateTime.fromISO(end_date, { zone: 'utc' }).plus({ months: 1 }) : DateTime.utc().plus({ months: 1 });
        console.log(DateTime.fromISO(start_date, { zone: 'utc' }).zoneName)
        const months = [];
        let currentMonth = startDate;

        while (currentMonth <= endDate) {
            if (frequency === 1) {
                console.log(currentMonth.startOf('month').diff(DateTime.utc(), 'days').days, currentMonth.toFormat("LLL-yyyy"))
                if (currentMonth.startOf('month').diff(DateTime.utc(), 'days').days <= 0) {
                    months.push(currentMonth.toFormat("LLL-yyyy"));
                }
            } else {

                if (currentMonth.startOf('month').diff(DateTime.utc(), 'days').days <= 0) {
                    console.log(currentMonth.plus({ months: frequency - 1 }).toFormat("LLL-yyyy"), currentMonth.plus({ months: frequency - 1 }).toLocal().startOf('month').diff(endDate.toLocal(), 'days').days)
                    months.push(
                        currentMonth.toFormat("LLL-yyyy") +
                        " to " +
                        currentMonth.plus({ months: frequency - 1 }).toFormat("LLL-yyyy")
                    );
                }


            }

            currentMonth = currentMonth.plus({ months: frequency });
        }

        return months;
    }
    const getCoverageText = (rowData, rawsitelist) => {
        let text = "";
        console.log(rowData);
        if (rowData.level === 0) {
            text = "Corporate";
        } else if (rowData.level === 1) {
            let country_index = rawsitelist.findIndex(
                (i) => i.id === rowData.locationId
            );
            if (country_index !== -1) {
                text = rawsitelist[country_index].name;
            }
        } else if (rowData.level === 2) {
            let city_index = rawsitelist
                .flatMap((i) =>
                    i.locationTwos.flatMap((j) =>
                        j.locationThrees.map((k) => {
                            return {
                                site_id: k.id,
                                site_name: k.name,
                                city_id: j.id,
                                city_name: j.name,
                                country_id: i.id,
                                country_name: i.name,
                            };
                        })
                    )
                )
                .findIndex((i) => {
                    return i.city_id === rowData.locationId;
                });
            if (city_index !== -1) {
                text = rawsitelist.flatMap((i) =>
                    i.locationTwos.flatMap((j) =>
                        j.locationThrees.map((k) => {
                            return {
                                site_id: k.id,
                                site_name: k.name,
                                city_id: j.id,
                                city_name: j.name,
                                country_id: i.id,
                                country_name: i.name,
                            };
                        })
                    )
                )[city_index].city_name;
            }
        } else if (rowData.level === 3) {
            let site_index = rawsitelist
                .flatMap((i) =>
                    i.locationTwos.flatMap((j) =>
                        j.locationThrees.map((k) => {
                            return {
                                site_id: k.id,
                                site_name: k.name,
                                city_id: j.id,
                                city_name: j.name,
                                country_id: i.id,
                                country_name: i.name,
                            };
                        })
                    )
                )
                .findIndex((i) => {
                    return i.site_id === rowData.locationId;
                });
            if (site_index !== -1) {
                text = rawsitelist.flatMap((i) =>
                    i.locationTwos.flatMap((j) =>
                        j.locationThrees.map((k) => {
                            return {
                                site_id: k.id,
                                site_name: k.name,
                                city_id: j.id,
                                city_name: j.name,
                                country_id: i.id,
                                country_name: i.name,
                            };
                        })
                    )
                )[site_index].site_name;
            }
        }
        return text;
    };
    const titleTemplate = (rowData) => {
        console.log(rowData)
        return (
            < >
                <Tooltip className="tag-tooltip" target={".tags" + rowData.id} position={'top'} autoHide={true}> {rowData.df.overallTags !== undefined && rowData.df.overallTags.map((i, j) => {
                    if (i.length !== 0) {
                        return (
                            <>
                                <label style={{ color: 'black', display: 'flex' }}> {
                                    j === 0 ? 'Must Have' : j === 1 ? 'Progressive' : 'Advanced'

                                }
                                </label>
                                {
                                    i.map((tag, k) => {

                                        return (
                                            <label style={{ color: 'green' }}>{tag}{k !== i.length - 1 && ','}</label>
                                        )

                                    })
                                }
                                <div style={{ marginBottom: 10 }} />
                            </>
                        )
                    }
                })} </Tooltip>
                <div style={{ alignItems: 'center' }} >{rowData.title} <i className={"material-icons " + "tags" + rowData.id} style={{ fontSize: 14, cursor: 'pointer' }}>info</i>  </div>

            </>
        )
    }
    const renderResponseTable = (id, category, topic, frameworks, resdata, listdata, frameList) => {

        let loc = selected, topicList = [], framework = [], categoryList = []
        let loclist = list
        loc['id'] = id;
        loc.category = category
        loc.topic = topic
        loc.framework = frameworks
        resdata.forEach((cat) => {
            if (cat.newTopics !== undefined) {
                categoryList.push(cat)
                if (loc.category.includes(cat.id)) {
                    cat.newTopics.forEach((topic) => {
                        topic.newMetrics !== undefined && topicList.push({ name: topic.title, title: topic.title + "(" + cat.title + ")", id: topic.id })
                        if (topic.newMetrics !== undefined && loc.topic.includes(topic.id)) {

                            topic.newMetrics.forEach((metric) => {
                                if (Array.isArray(metric.data1) && metric.data1.length !== 0) {
                                    if (Object.keys(metric.data1[0]).length > 2) {
                                        if (metric.data1[0].type === 1 && metric.data1[0].rf !== null) {
                                            console.log(metric.id, 640)
                                            // let index = framework.findIndex((k) => { return k.cat_id === cat.id && k.top_id === topic.id })
                                            // if (index !== -1) {
                                            //     framework[index].metric.push(metric)
                                            // } else {
                                            let tag = []
                                            let tags = JSON.parse(JSON.stringify(loc.framework)).filter(i => listdata.framework.map(j => j.id).includes(i)).map((fw) => { return listdata.framework.filter((k) => { return k.id === fw })[0].title.trim().toLowerCase() })
                                            let frame = JSON.parse(JSON.stringify(loc.framework)).filter(i => listdata.framework.map(j => j.id).includes(i)).map((fw) => { return listdata.framework.filter((k) => { return k.id === fw })[0] })


                                            let one = metric.data1[0].tags1.some((str) => tags.some((fruit) => str.toLowerCase().includes(fruit.toLowerCase())))
                                            let two = metric.data1[0].tags2.some((str) => tags.some((fruit) => str.toLowerCase().includes(fruit.toLowerCase())))
                                            let three = metric.data1[0].tags3.some((str) => tags.some((fruit) => str.toLowerCase().includes(fruit.toLowerCase())))
                                            const matchedTag1 = metric.data1[0].tags1.filter((str) => tags.some((fruit) => str.toLowerCase().includes(fruit.toLowerCase())));
                                            const matchedTag2 = metric.data1[0].tags2.filter((str) => tags.some((fruit) => str.toLowerCase().includes(fruit.toLowerCase())));
                                            const matchedTag3 = metric.data1[0].tags3.filter((str) => tags.some((fruit) => str.toLowerCase().includes(fruit.toLowerCase())));

                                            frame.forEach((t) => {
                                                matchedTag1.forEach((i) => {

                                                    if (i.trim().toLowerCase().includes(t.title.trim().toLowerCase())) {
                                                        !tag.includes(t.id) && tag.push(t.id)
                                                    }


                                                })
                                                matchedTag2.forEach((i) => {

                                                    if (i.trim().toLowerCase().includes(t.title.trim().toLowerCase())) {
                                                        !tag.includes(t.id) && tag.push(t.id)
                                                    }


                                                })
                                                matchedTag3.forEach((i) => {

                                                    if (i.trim().toLowerCase().includes(t.title.trim().toLowerCase())) {
                                                        !tag.includes(t.id) && tag.push(t.id)
                                                    }


                                                })
                                            })
                                            console.log(matchedTag1, matchedTag2, matchedTag3, tag)
                                            if (one || two || three) {
                                                framework.push({ tags: tag, overallTags: [metric.data1[0].tags1, metric.data1[0].tags2, metric.data1[0].tags3], cat_id: cat.id, top_id: topic.id, cat_title: cat.title, top_title: topic.title, ...metric })
                                            }
                                            // }

                                        }
                                    }
                                }

                            })
                        }
                    })
                }
            }


        })
        loclist.metric = framework
        loclist.category = categoryList
        loclist.topic = topicList
        loc.category = categoryList.filter((i) => { return loc.category.includes(i.id) }).map((k) => { return k.id })
        loc['topic'] = topicList.filter((i) => { return loc.topic.includes(i.id) }).map((k) => { return k.id })
        loc.framework = frameList.filter((i) => { return loc.framework.includes(i.id) }).map((k) => { return k.id })
        console.log(loc)
        setSelected(loc)

        setList(loclist)
        forceUpdate()
    }
    const lastResponse = (rowData) => {

        let text = 'Not Responded'
        let data = rowData

        let index = rfresponse.findLastIndex((i) => { return i.dfId === data.dfId && rowData.df.top_id === i.topicId && rowData.df.id === i.indicatorId && rowData.df.cat_id === i.categoryId && rowData.locationId === i.locationId && rowData.level === i.level && rpText(i.reporting_period) === rowData.reporting_period })
        if (index !== -1) {
            text = moment.utc(rfresponse[index].last_modified_on).format('DD MMMM YYYY')

        }
        return (
            <>
                {text}
            </>
        )
    }
    const rrTemplate = (rowData) => {
        let data = rowData, oldData = [], id = 0, show = true
        let locass = JSON.parse(JSON.stringify(dfuserasslist))
        let locassindex = locass.findIndex((i) => { return i.dfId === data.dfId && i.locationId === rowData.locationId && i.level === rowData.level && i.reporting_period === rowData.reporting_period })
    let locentassindex = dfentityasslist.findIndex(i => i.dfId === rowData.dfId)
        if (locassindex !== -1) {


            let index = rfresponse.findLastIndex((i) => { return i.dfId === data.dfId && rowData.df.top_id === i.topicId && rowData.df.id === i.indicatorId && rowData.df.cat_id === i.categoryId && i.locationId === rowData.locationId && i.level === rowData.level && rpText(i.reporting_period) === rowData.reporting_period })
            console.log(locassindex, index)
            if (index !== -1) {
                oldData = rfresponse[index]

            }
        }
        // <a style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => { navigate.push({ pathname: '/rf_input_entry/' + rowData.data1[0].rf, state: { data: rowData, oldData } }) }}>       {rowData.df.title} </a>

        console.log(rowData)
        return (
            < >
                <a style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => { window.open(window.origin + '/qualitative_consolidate').sessionStorage.setItem('dfconsol', JSON.stringify({ dfId: rowData.dfId, data: rowData , ass:dfentityasslist[locentassindex]  })) }} >       {rowData.df.title} </a>

            </>
        )
    }
    const coverageTemplate = (rowData) => {
        let text = 'Not Found'
        console.log(rowData)
        if (rowData.level === 0) {
            text = 'Corporate'
        } else if (rowData.level === 1) {
            let country_index = rawsitelist.findIndex(i => i.id === rowData.tier1_id)
            if (country_index !== -1) {
                text = rawsitelist[country_index].name
            }
        } else if (rowData.level === 2) {
            let city_index = rawsitelist.flatMap(i => i.locationTwos.flatMap(j => j.locationThrees.map((k) => { return { site_id: k.id, site_name: k.name, city_id: j.id, city_name: j.name, country_id: i.id, country_name: i.name } }))).findIndex((i) => { return i.city_id === rowData.tier2_id })
            if (city_index !== -1) {
                text = rawsitelist.flatMap(i => i.locationTwos.flatMap(j => j.locationThrees.map((k) => { return { site_id: k.id, site_name: k.name, city_id: j.id, city_name: j.name, country_id: i.id, country_name: i.name } })))[city_index].city_name
            }

        } else if (rowData.level === 3) {
            let site_index = rawsitelist.flatMap(i => i.locationTwos.flatMap(j => j.locationThrees.map((k) => { return { site_id: k.id, site_name: k.name, city_id: j.id, city_name: j.name, country_id: i.id, country_name: i.name } }))).findIndex((i) => { return i.site_id === rowData.tier3_id })
            if (site_index !== -1) {
                text = rawsitelist.flatMap(i => i.locationTwos.flatMap(j => j.locationThrees.map((k) => { return { site_id: k.id, site_name: k.name, city_id: j.id, city_name: j.name, country_id: i.id, country_name: i.name } })))[site_index].site_name
            }
        }
        console.log(text)
        rowData.coverage_ = text
        return (
            <>{text}</>
        )
    }
    const checkResponsibility = (rfid) => {
        console.log(rfass, rfid)
        return rfass.findIndex((k) => { return k.reporter_ids !== null && k.reporter_ids.includes(selector.id) && k.rfid === rfid }) === -1 ? false : true
    }
    const getMetric = (rf) => {

        let loc = list.metric.findIndex((i) => { return i.data1 !== null && i.data1[0].rf !== undefined && i.data1[0].rf === rf.rfid })
        if (loc !== -1) {
            return { ...list.metric[loc], type: rf.type, level: rf.level, coverage: rf.coverage }
        } else {
            return null
        }
    }
    const historyTemplate = (rowData) => {
        let text = true
        let data = rowData
        let mergeData = []
        console.log(rowData)
        let index = rfresponse.findLastIndex((i) => { return i.dfId === data.dfId && rowData.df.top_id === i.topicId && rowData.df.id === i.indicatorId && rowData.df.cat_id === i.categoryId && rowData.locationId === i.locationId && rowData.level === i.level && rpText(i.reporting_period) === rowData.reporting_period })

        if (index !== -1) {
            text = false
            mergeData = rfresponse.filter((i) => { return i.dfId === data.dfId && rowData.df.top_id === i.topicId && rowData.df.id === i.indicatorId && rowData.df.cat_id === i.categoryId && rowData.locationId === i.locationId && rowData.level === i.level && rpText(i.reporting_period) === rowData.reporting_period })
            mergeData.forEach((i) => {
                i.top_title = rowData.top_title
                i.cat_title = rowData.cat_title
            })
            console.log(mergeData)
        }
        return (
            <>
                {text ?
                    <span>NA</span> :
                    <a className="cur-pointer" onClick={() => { setHistoryData(mergeData); activeRowData = rowData; setHistoryDialog(true) }}>View History</a>
                }

            </>
        )
    }
    const getAssignedList = (rfassdata) => {
        let assdata = rfassdata.filter((i) => i.reporter_ids !== null && i.reporter_ids.includes(selector.id))

        return assdata.filter(i => checkEntityAssigned(i))
    }
    function rpText(dates) {
        if (dates.length === 0) return "";

        const startDate = DateTime.fromFormat(dates[0], "MM-yyyy");
        const endDate = DateTime.fromFormat(dates[dates.length - 1], "MM-yyyy");

        const startMonthYear = startDate.toFormat('MMM-yyyy');
        const endMonthYear = endDate.toFormat('MMM-yyyy');

        return `${startMonthYear} to ${endMonthYear}`;
    }
    const checkEntityAssigned = (rfuser) => {
        let assentitydata = JSON.parse(JSON.stringify(rfentityass))
        let index = assentitydata.findIndex((i) => { return i.rfid === rfuser.rfid })
        if (index !== -1) {
            if (rfuser.level === 0) {

                return assentitydata[index].other_ids.includes(rfuser.coverage)

            } else if (rfuser.level === 1) {
                if (getCoverageText(rfuser) !== null) {
                    return assentitydata[index].country_ids.includes(rfuser.coverage)
                } else {
                    return false
                }


            } else if (rfuser.level === 2) {
                if (getCoverageText(rfuser) !== null) {
                    return assentitydata[index].city_ids.includes(rfuser.coverage)
                } else {
                    return false
                }

            } else if (rfuser.level === 3) {
                if (getCoverageText(rfuser) !== null) {

                    return assentitydata[index].site_ids.includes(rfuser.coverage)
                } else {
                    return false
                }
            }

        } else {
            return false
        }
    }
    return (
        <div className="bg-smoke font-lato" >
            <div className="col-12">
                <div className="p-3" id='divsize'>
                    <div className="sticky-div">
                        <div className="m-1 fw-7 fs-20 flex justify-content-center mt-2">
                            Qualitative Consolidation  ({dfuserasslist.length})
                        </div>

                    </div>

                    {selectedsite === null ? <div >
                        <div >

                            <DataTable className="user-data-table-height fw-4 fs-16" value={dfuserasslist}
                                paginator
                                rows={20}
                                rowsPerPageOptions={[10, 20, 50, 100]}
                                scrollable
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                                // globalFilterFields={["month", "location", "city", "country"]}
                                // globalFilter={filter}
                                emptyMessage="No records found."
                                // header={header}
                                responsiveLayout="scroll"
                            >
                                <Column field='df.cat_title' header='Category' />
                                <Column field='df.top_title' header='Topic' />
                                <Column field='reporting_period' header='Reporting Period' />
                                <Column field='title' body={titleTemplate} header='Aspect' />
                                <Column body={coverageTemplate} header='Coverage' />
                                <Column body={rrTemplate} header='Requirement' />
                                <Column body={lastResponse} header='Response Date' />

                            </DataTable>

                            <Dialog
                                visible={historydialog}
                                style={{
                                    width: "30%",
                                }}
                                header={"Response History"}
                                modal
                                className="p-fluid"

                                onHide={() => { setHistoryDialog(false) }}
                            >
                                <div>
                                    {historydata.sort((a, b) => { return moment(b.last_modified_on).toDate() - moment(a.last_modified_on).toDate() }).map((i) => {
                                        return (
                                            <div style={{ flexDirection: 'column', alignItems: 'center', display: 'flex', padding: 5, borderRadius: 10, margin: 5, boxShadow: 'rgb(0 0 0 / 24%) 0px 3px 8px' }}>

                                                <text style={{ color: 'black', borderRadius: 10, padding: 5, fontStyle: 'italic', cursor: 'pointer' }} onClick={() => { navigate.push({ pathname: '/rf_submission_preview/' + i.dfid, state: { data: activeRowData, oldData: i } }) }}>{moment(i.last_modified_on).local().format('DD MMM YYYY, hh:mm A')} by <span style={{ color: 'green' }}>{i.submitted_by === selector.id ? 'you' : i.submitted_by === client_info.id ? 'Admin' : !userList.findIndex((j) => { return j.id === i.submitted_by }) === -1 ? 'User Deleted' : userList.find((j) => { return j.id === i.submitted_by }).information.empname}</span> </text>

                                            </div>
                                        )
                                    })

                                    }
                                </div>
                            </Dialog>
                        </div>



                    </div>
                        :
                        <label style={{
                            justifyContent: 'center',
                            display: 'flex',
                            marginTop: '50px'
                        }}>
                            {selectedsite === null ? <i className="pi pi-spin pi-spinner" style={{ fontSize: 50 }}></i> :
                                'No Qualitative Reponse found'
                            }
                        </label>}
                </div>
            </div>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(QualitativeApprover, comparisonFn);
