import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { resetLoggedUserDetail } from './RTK/Login/userProfile';
import { motion } from "framer-motion";
import { resetOverallPendingData } from './RTK/Background/pendingData';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import useForceUpdate from 'use-force-update';
export const AppTopbar = (props) => {
    const selector = useSelector(state => state.user.userdetail)
    const [rotation, setRotation] = useState(true)
    const history = useHistory()
    const dispatch = useDispatch()
    const login_data = useSelector((state) => state.user.userdetail)
    const location = useLocation()
    const navigate = useHistory()
    const forceUpdate = useForceUpdate()
    const client_info = useSelector((state) => state.userlist.admindetail)
    const [pathname,setPathname] = useState(null)
    useEffect(()=>{
setPathname(location.pathname)

    },[location.pathname])
    return (
        <div className="layout-topbar justify-content-between" style={{flexDirection:'row-reverse'}}>
            <div className=' flex align-items-center '>

               
       <Link to={login_data.role === 'clientadmin' ? "/client/indicators" : '/add_new_client'} style={{ width: 180 }} className="justify-content-center layout-topbar-logo mr-2">
                    <img id='logo' width={'100%'} height={'auto'} src={props.layoutColorMode === 'light' ? require('../src/assets/images/eisqr_logo_final_v1.png').default : require('../src/assets/images/eisqr_logo.png').default} alt="logo" />
           
                </Link>



            </div>
            
            <div className='grid'>
                <div className='flex justify-content-end align-items-center mr-3'  style={{flexDirection:'column'}}>
                {Object.keys(selector).length !== 0 && (selector.role === 'clientadmin' || selector.role === 'eisqradmin') ?
                        <img id='clientlogo' src={selector.information.companylogo} style={{
                            width: '100%', maxWidth: 70, padding: 2

                        }} /> :
                        Object.keys(client_info).length !== 0 &&       <img id='clientlogo' src={client_info.information.companylogo} style={{
                            width: '100%', maxWidth: 70, padding: 2

                        }} />


                    }

                    {/* <label className='clr-navy text-bold'> {Object.keys(selector).length !== 0 &&  'USER'}</label> */}
             
                </div>
                
            <>
            
            {/* {Object.keys(selector).length !== 0 &&
                    <>              
                       
                        <button style={{paddingRight:40}} onClick={() => { dispatch(resetLoggedUserDetail()); dispatch(resetOverallPendingData([])); localStorage.clear(); sessionStorage.clear(); history.push('/') }} className='p-link'><i className='pi pi-sign-out clr-delete' style={{ fontSize: 20 }} ></i> </button>

                    </>

                } */}
                </>
                   
                </div>

            <ul style={{ listStyle: 'none' }} className={props.mobileTopbarMenuActive ? 'custom-layout-topbar-menu lg:flex origin-top layout-topbar-menu-mobile-active' : 'layout-topbar-menu lg:flex origin-top d-none'}>
                <li >
                    <button style={{ width: '100%' }} className="p-link layout-topbar-button justify-content-start " >
                        <i className="pi pi-key " style={{ fontSize: 14 }} />
                        <span className='ml-3'>Change Password</span>
                    </button>
                </li>
                <li >

                    <button style={{ width: '100%' }} onClick={() => { dispatch(resetLoggedUserDetail()); dispatch(resetOverallPendingData([]));  localStorage.removeItem('token'); sessionStorage.clear(); history.push('/') }} className="p-link layout-topbar-button justify-content-start">
                        <i className="pi pi-sign-out clr-delete" style={{ fontSize: 14 }} />
                        <span className='ml-3'>Logout</span>
                    </button>
                </li>

            </ul>
        </div>
    );
}
