import React from "react";
import { Chart } from "primereact/chart";
import EmissionsCards from "./EmissionsCard";
import OverviewScope1 from "./OverviewScope1";
import OverviewScope3 from "./OverviewScope3";
import OverviewWaste from "./OverviewWaste";
import OverviewHazardous from "./OverviewHazardous";
import OverviewNonHazardous from "./OverviewNonHazardous";
import OverviewWater from "./OverviewWater";
import OverviewWaterDischarge from "./OverviewWaterDischarge";
import LTIFChart from "./Ltif";

export const CustomLegend = (props) => {
  const { payload } = props;
  return (
    <ul
      style={{
        display: "flex",
        listStyleType: "none",
        justifyContent: "center",
        padding: 0,
      }}
    >
      {payload.map((entry, index) => (
        <li
          key={`item-${index}`}
          style={{ color: entry.color, marginRight: 20 }}
        >
          <span
            style={{
              color: entry.color,
              marginRight: 4,
              fontSize: "20px",
            }}
          >
            ■
          </span>
          <span style={{ color: "#555", fontSize: "14px" }}>{entry.value}</span>
        </li>
      ))}
    </ul>
  );
};

export const Dashboard = () => {
  return (
    <div>
      <EmissionsCards />
      <div
        style={{
          fontFamily: "Lato",
          fontSize: "16px",
          fontWeight: 700,
          lineHeight: "19.2px",
          textAlign: "left",
          margin: "18px 10px 18px 10px",
        }}
      >
        Energy and Emissions
        <div style={{ fontWeight: 200, fontSize: "14px" }}>
          Energy mix and Carbon emissions across the organization
        </div>
      </div>
      <OverviewScope1 />
      <OverviewScope3 />
      <div
        style={{
          fontFamily: "Lato",
          fontSize: "16px",
          fontWeight: 700,
          lineHeight: "19.2px",
          textAlign: "left",
          margin: "18px 10px 18px 10px",
        }}
      >
        Waste Management
        <div style={{ fontWeight: 200, fontSize: "14px" }}>
          Managing the collection and disposal of waste across the organization
        </div>
      </div>
      <OverviewWaste />
      <OverviewHazardous />
      <OverviewNonHazardous />
      <div
        style={{
          fontFamily: "Lato",
          fontSize: "16px",
          fontWeight: 700,
          lineHeight: "19.2px",
          textAlign: "left",
          margin: "18px 10px 18px 10px",
        }}
      >
        Water Stewardship
        <div style={{ fontWeight: 200, fontSize: "14px" }}>
          Management of water withdrawal and disposal
        </div>
      </div>
      <OverviewWater />
      <OverviewWaterDischarge />
      <div
        style={{
          fontFamily: "Lato",
          fontSize: "16px",
          fontWeight: 700,
          lineHeight: "19.2px",
          textAlign: "left",
          margin: "18px 10px 18px 10px",
        }}
      >
        Safety
        <div style={{ fontWeight: 200, fontSize: "14px" }}>
          Employee safety performance across the organization
        </div>
      </div>
      <LTIFChart />
    </div>
  );
};
