import React, { useState, useEffect, useRef } from "react";
import './componentstyle.css';

export function ReadMoreComponent({
  title = null,
  content = '',
  percent = 40, // Percentage of container width to determine visible text
  containerStyle = {}, containerClassname='',titleClassname='',contentClassname='',
  titleStyle = {},
  contentStyle = {},
  readMoreComponent = 'read more',
  readLessComponent = 'read less'
}) {
  const [expanded, setExpanded] = useState(false);
  const [visibleChars, setVisibleChars] = useState(content.length); // Initial state to show full text
  const containerRef = useRef(null);


  // Function to calculate visible characters based on container width
  const calculateVisibleChars = () => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.getBoundingClientRect().width;
      const targetWidth = containerWidth * ((percent - 5) / 100);

      const tempSpan = document.createElement("span");
      tempSpan.style.visibility = "hidden";
      tempSpan.style.position = "absolute";
      tempSpan.style.whiteSpace = "nowrap";
      document.body.appendChild(tempSpan);

      let charCount = 0;
      let currentText = '';

      while (charCount < content.length) {
        currentText += content[charCount];
        tempSpan.innerText = currentText;
        if (tempSpan.offsetWidth > targetWidth) break;
        charCount++;
      }

      setVisibleChars(charCount);
      document.body.removeChild(tempSpan);
    }
  };
  useEffect(() => {
    calculateVisibleChars();

    window.addEventListener("resize", calculateVisibleChars);
    return () => window.removeEventListener("resize", calculateVisibleChars);
  }, [content, percent]);

  const toggleExpand = () => setExpanded(!expanded);

  return (
    <div className={"expandable-container "+containerClassname} style={containerStyle}>
      {title && (
        <label className={"flex fs-16 fw-7 "+ titleClassname} style={{ fontWeight: 900, marginBottom: 10, ...titleStyle }}>{title}</label>
      )}
      <div ref={containerRef} className={"expandable-content "+ contentClassname} style={contentStyle}>
        {expanded ? content : content.slice(0, visibleChars)}
        {!expanded && content.length > visibleChars && (
          <small className="expand-toggle-label" onClick={toggleExpand}>... {readMoreComponent}</small>
        )}
        {expanded && (
          <small className="expand-toggle-label less" onClick={toggleExpand}> {readLessComponent}</small>
        )}
      </div>
    </div>
  );
}
