import Axios from "axios";
import moment from "moment";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import useForceUpdate from "use-force-update";
import { API } from "../constants/api_url";
import LazyView from "../components/LazyView";
import { useHistory } from "react-router-dom";
import { TabMenu } from 'primereact/tabmenu';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column"
import { Tooltip } from "primereact/tooltip";
import Swal from 'sweetalert2'
import APIServices from "../service/APIService";
import { Tag } from "primereact/tag";
import { Button } from "primereact/button";
const { DateTime } = require('luxon')
let activeRowData = {}
const UserHomeScreenOld = () => {

    const navigate = useHistory()

    const userList = useSelector(state => state.userlist.userList)
    const [update, setUpdate] = useState(false)
    const [render, setRender] = useState(false)
    const [filterdcf, setFilterDCF] = useState([])
    const [activetab, setActiveTab] = useState(10)
    const login_data = useSelector((state) => state.user.userdetail)
    const client_info = useSelector((state) => state.userlist.admindetail)
    const [rfass, setRFAss] = useState([])
    const [present, setPresent] = useState([])
    const [past, setPast] = useState([])
    const [selectedsite, setSelectedSite] = useState(0)
    const [data, setData] = useState([])
    const [future, setFuture] = useState([])
    const [dcflist, setDcfList] = useState([])
    const [sitelist, setSiteList] = useState([])
    const [sitelistall, setSiteListAll] = useState([])
    const [dcfassign, setDCFAssign] = useState([])
    const [submitteddcf, setSubmittedDCF] = useState([])
    const [pendingap, setPendingAP] = useState([])
    const [pendingapbk, setPendingAPBK] = useState([])

    // RF
    const [selected, setSelected] = useState({ category: [], topic: [], framework: [], metric: [] })
    const [list, setList] = useState({ category: null, topic: null, metric: null, framework: null })
    const [response, setResponse] = useState([])
    const [rfresponse, setRFResponse] = useState([])
    const [overallmetric, setOverallMetric] = useState([])
    const [historydata, setHistoryData] = useState([])
    const [historydialog, setHistoryDialog] = useState(false)
    const [rawrf, setRawRF] = useState([])
    const [selectedlist, setSelectedList] = useState({ title: '', data: [] })
    const [prevdialog, setPrevDialog] = useState(false);

    const [show, setShow] = useState({ entry: false, approver: false, loaded: false })
    const frequency_list = [{ name: 'Monthly', id: 1 }, { name: 'Bi-Monthly', id: 2 }, { name: 'Quartely', id: 3 }, { name: 'Annually', id: 4 }, { name: 'Bi-Annually', id: 5 }, { name: 'Undefined', id: 6 }]
    const month_list = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    const forceUpdate = useForceUpdate()
    const [done, setDone] = useState(false)
    useEffect(() => {

        if (Object.keys(client_info).length !== 0 && done === false) {
            setDone(true)
            console.log('its working')
            let uriString = {
                "include": [{ "relation": "locationTwos", "scope": { "include": [{ "relation": "locationThrees" }] } }]

            }
            let uriString3 = {
                "include": [{ "relation": "newDataPoints" }]

            }
            let uriString2 = {
                "include": [{ "relation": "newTopics", "scope": { "include": [{ "relation": "newMetrics", "scope": { "include": [{ "relation": "newDataPoints" }] } }] } }]


            }
            let dcf_list = [], dcf_submitted = [], locloc = []
            let site_url = API.LocationOne_UP(login_data.information.cid) + `?filter=${encodeURIComponent(JSON.stringify(uriString))}`;
            const promise1 = APIServices.get(API.DCF)
            const promise2 = APIServices.get(API.DCF_Submit_UP(login_data.information.cid))
            const promise3 = APIServices.get(API.LocationThree)
            let promise4 = APIServices.get(API.RF)
            let promise5 = APIServices.get(API.RF_Submit_UP(login_data.information.cid))
            let promise6 = APIServices.get(API.Report_Name_Twos)
            let promise7 = APIServices.get(API.QL_Listing_Filter_UP(login_data.information.cid))

            let Overall = API.Categories + `?filter=${encodeURIComponent(JSON.stringify(uriString2))}`;

            let url = API.Metric + `?filter=${encodeURIComponent(JSON.stringify(uriString3))}`;
            let promise8 = APIServices.get(Overall)
            let promise9 = APIServices.get(API.RF_User_UP(login_data.information.cid))
            let promise10 = APIServices.get(API.AssignDCFClient_UP(login_data.information.cid))
            Promise.all([promise1, promise2, promise3, promise4, promise5, promise6, promise7, promise8, promise9, promise10]).then(function (values) {

                setRawRF(values[3].data)
                setRFResponse(values[4].data)
                let locs = list

                setRFAss(values[8].data)
                locs.framework = values[5].data.filter((i) => { return client_info.information.report.includes(i.id) })
                setList(locs)
                setResponse(values[7].data)
                let categoryList = [], metricList = [], topicList = [], filter_dcf = []
                let required_rf = [], loc = list
                values[7].data.forEach((cat) => {
                    if (cat.newTopics) {
                        cat.newTopics.forEach((topic) => {
                            if (topic.newMetrics) {
                                topic.newMetrics.forEach((metric) => {
                                    if (Array.isArray(metric.data1) && metric.data1[0] !== undefined && metric.data1[0].type === 1 && values[3].data.map(i => i.id).includes(metric.data1[0].rf)) {
                                        if (values[8].data.findIndex(i => i.rfid === metric.data1[0].rf) !== -1 && required_rf.findIndex(j => j.data1[0].rf === metric.data1[0].rf) === -1) {
                                            required_rf.push({ cat_id: cat.id, top_id: topic.id, overallTags: [metric.data1[0].tags1, metric.data1[0].tags2, metric.data1[0].tags3], cat_title: cat.title, top_title: topic.title, title: metric.title, ...metric })
                                        }

                                    }
                                })
                            }
                        }
                        )
                    }
                })
                console.log(required_rf)
                if (required_rf.length !== 0) {
                    loc.metric = required_rf
                }
                values[7].data.forEach((cat) => {
                    if (cat.newTopics !== undefined) {

                        cat.newTopics.forEach((topic) => {

                            if (topic.newMetrics !== undefined) {

                                topic.newMetrics.forEach((metric) => {
                                    metricList.push(metric)
                                })
                            }
                        })
                    }


                })
                if (values[9].data.length !== 0) {
                    metricList.forEach((j) => {
                        if (values[9].data[0].metric_ids.includes(j.id)) {
                            if (j.newDataPoints) {
                                j.newDataPoints.forEach((k) => {
                                    console.log(k)
                                    if (Array.isArray(k.data1) && k.data1[0].datasource && typeof k.data1[0].datasource === 'number') {
                                        let dcfindex = values[0].data.findIndex((m) => { return m.id === k.data1[0].datasource })
                                        let dcfindex2 = filter_dcf.findIndex((m) => { return m.id === k.data1[0].datasource })

                                        if (dcfindex !== -1 && dcfindex2 === -1) {

                                            filter_dcf.push(values[0].data[dcfindex])
                                        }
                                    }
                                })
                            }
                        }

                    })
                    if (values[9].data[0].cf_ids !== null && values[9].data[0].cf_ids.length !== 0) {
                        values[9].data[0].cf_ids.forEach((id) => {
                            let dcfindex = values[0].data.findIndex((m) => { return m.id === id })
                            if (dcfindex !== -1) {
                                filter_dcf.push(values[0].data[dcfindex])
                            }
                        })
                    }

                }
                setFilterDCF(filter_dcf)
                setList(loc)
                setOverallMetric(JSON.parse(JSON.stringify(metricList)))




                dcf_list = values[0].data; setDcfList(values[0].data)

                dcf_submitted = values[1].data;
                locloc = values[2].data

                forceUpdate()

                APIServices.get(API.AssignDCFUser_UP(login_data.information.cid)).then((res) => {
                    setDCFAssign(JSON.parse(JSON.stringify(res.data)))
                    let filter = [], locationFilter = [0], rawData = [], pending = []
                    let show_ = { entry: res.data.filter((i) => { return i.type === 0 && i.user_id === login_data.id }).length === 0 ? false : true, approver: res.data.filter((i) => { return i.type === 0 && i.reviewer_id === login_data.id }).length === 0 ? false : true }
                    setShow((prev) => ({ ...prev, 'entry': show_.entry, 'approver': show_.approver, loaded: true }))

                    res.data.forEach((item) => {
                       
                        if (item.type === 0 && item.user_id !== login_data.id &&  item.reviewer_id === login_data.id && filter_dcf.findIndex(i => { return i.id === item.dcfId }) !== -1) {
                   
                            if (dcf_list.filter((k) => { return k.id === item.dcfId }).length !== 0 && locloc.findIndex((k) => { return k.id === item.site }) !== -1) {
                              console.log(dcf_submitted)
                                if (dcf_submitted.filter((k) => { return k.dcf === item.dcfId && k.site === item.site && (k.type === 1 || k.type === 2 || k.type === 3 || k.reject === 1) }).length !== 0) {
                                    if (pending.findIndex((k) => { return k.id === dcf_submitted.filter((k) => { return k.dcf === item.dcfId && k.site === item.site && (k.type === 1 || k.type === 2 || k.type === 3 || k.reject === 1) })[0].id }) === -1) {
                                        pending.push(...dcf_submitted.filter((k) => { return k.dcf === item.dcfId && k.site === item.site && (k.type === 1 || k.type === 2 || k.type === 3 || k.reject === 1) }))
                                        console.log(pending)
                                        if (locationFilter.findIndex((k) => { return k === item.site }) === -1) {

                                            locationFilter.push(item.site)
                                        }
                                    }
                                }
                            }
                        }

                        if (item.type === 0 && item.user_id === login_data.id && filter_dcf.findIndex(i => { return i.id === item.dcfId }) !== -1) {
                            console.log(item.user_id)
                            if (dcf_list.filter((k) => { return k.id === item.dcfId }).length !== 0 && locloc.findIndex((k) => { return k.id === item.site }) !== -1) {

                                item.dcf_ = dcf_list.filter((k) => { return k.id === item.dcfId })[0]
                                item.frequency_ = frequency_list.filter((k) => { return k.id === item.frequency })[0]

                                // item.start_date = moment.utc(item.start_date).toDate()
                                filter.push(item)
                                if (locationFilter.findIndex((k) => { return k === item.site }) === -1) {
                                    locationFilter.push(item.site)
                                }
                                console.log(DateTime.fromISO(item.start_date, { zone: 'utc' }), DateTime.local(), 'dcf')
                                let st_date = DateTime.fromISO(item.start_date, { zone: 'utc' }).toLocal(), ed_date = DateTime.utc().toLocal()
                                if (typeof item.end_date === 'string') {
                                    ed_date = DateTime.fromISO(item.end_date, { zone: 'utc' }).toLocal()
                                }
                                console.log(st_date, ed_date, typeof item.end_date)
                                console.log(moment(moment.utc(item.start_date).toDate()).diff(moment.utc(moment.utc()), 'days'))
                                //  getMonths(moment(moment.utc(item.start_date).toDate()), moment.utc(moment.utc()), item.frequency, item, dcf_submitted)

                                getMonthsLuxon(st_date, ed_date, item.frequency, item, dcf_submitted)

                                rawData.push(item)
                            }

                        }
                    })
console.log(pending)
                    setPendingAPBK(pending.filter(i => { return locationFilter.includes(i.site) }))
                    setPendingAP(pending.filter(i => { return locationFilter.includes(i.site) }))
                    setSubmittedDCF(values[1].data.filter(i => { return locationFilter.includes(i.site) }))
                    setData(rawData)
                    APIServices.get(site_url).then((rest) => {
                        let site_list = [{ name: 'All', id: 0 }]
                        let site_list_all = []
                        rest.data.forEach((country) => {
                            if (country.locationTwos !== undefined) {
                                country.locationTwos.forEach((city) => {
                                    if (city.locationThrees !== undefined) {
                                        city.locationThrees.forEach((site) => {
                                            filter.forEach((item) => { if (site.id === item.site) { item.site = site } })
                                            site_list.push({ name: site.name + ' (' + city.name + ')', id: site.id, country: { id: country.id, name: country.name }, city: { id: city.id, name: city.name } })
                                            site_list_all.push({ name: site.name + ' (' + city.name + ')', id: site.id, country: { id: country.id, name: country.name }, city: { id: city.id, name: city.name } })

                                        })
                                    }
                                })
                            }
                        })
                        setSiteListAll(site_list_all)
                        setSiteList(site_list.filter((k) => { return locationFilter.includes(k.id) }))
                    })

                })

            })
        }
    }, [client_info])


    useEffect(() => {

        if (past.length === 0 && present.length === 0 && future.length === 0) {

            let loc = JSON.parse(JSON.stringify(data))
            loc.forEach((i, j) => {
                if (i.site.id === selectedsite || selectedsite === 0) {
                    i.site = [i.site.id]
                    let ed_date = DateTime.utc().toLocal()
                    let st_date = DateTime.fromISO(i.start_date, { zone: 'utc' }).toLocal()
                    if (typeof i.end_date === 'string') {
                        ed_date = DateTime.fromISO(i.end_date, { zone: 'utc' }).toLocal()
                    }
                    // getMonths(moment(i.start_date), moment(moment.utc()), i.frequency, i, submitteddcf)
                    getMonthsLuxon(st_date, ed_date, i.frequency, i, submitteddcf)


                }
            })
            console.log(pendingapbk)
            setPendingAP(pendingapbk.filter((i) => { return (i.site === selectedsite || selectedsite === 0) }))
            forceUpdate()
        }
    }, [selectedsite])

    const renderResponseTable = (id, category, topic, frameworks, resdata, listdata, frameList) => {

        let loc = selected, topicList = [], framework = [], categoryList = []
        let loclist = list
        loc['id'] = id;
        loc.category = category
        loc.topic = topic
        loc.framework = frameworks
        resdata.forEach((cat) => {
            if (cat.newTopics !== undefined) {
                categoryList.push(cat)
                if (loc.category.includes(cat.id)) {
                    cat.newTopics.forEach((topic) => {
                        topic.newMetrics !== undefined && topicList.push({ name: topic.title, title: topic.title + "(" + cat.title + ")", id: topic.id })
                        if (topic.newMetrics !== undefined && loc.topic.includes(topic.id)) {

                            topic.newMetrics.forEach((metric) => {
                                if (Array.isArray(metric.data1) && metric.data1.length !== 0) {
                                    if (Object.keys(metric.data1[0]).length > 2) {
                                        if (metric.data1[0].type === 1 && metric.data1[0].rf !== null) {
                                            console.log(listdata.framework, loc.framework, JSON.parse(JSON.stringify(loc.framework)).filter(i => listdata.framework.map(j => j.id).includes(i)))
                                            // let index = framework.findIndex((k) => { return k.cat_id === cat.id && k.top_id === topic.id })
                                            // if (index !== -1) {
                                            //     framework[index].metric.push(metric)
                                            // } else {
                                            let tag = []
                                            let tags = JSON.parse(JSON.stringify(loc.framework)).filter(i => listdata.framework.map(j => j.id).includes(i)).map((fw) => { return listdata.framework.filter((k) => { return k.id === fw })[0].title.trim().toLowerCase() })
                                            let frame = JSON.parse(JSON.stringify(loc.framework)).filter(i => listdata.framework.map(j => j.id).includes(i)).map((fw) => { return listdata.framework.filter((k) => { return k.id === fw })[0] })
                                            let one = metric.data1[0].tags1.some((str) => tags.some((fruit) => str.toLowerCase().includes(fruit.toLowerCase())))
                                            let two = metric.data1[0].tags2.some((str) => tags.some((fruit) => str.toLowerCase().includes(fruit.toLowerCase())))
                                            let three = metric.data1[0].tags3.some((str) => tags.some((fruit) => str.toLowerCase().includes(fruit.toLowerCase())))
                                            const matchedTag1 = metric.data1[0].tags1.filter((str) => tags.some((fruit) => str.toLowerCase().includes(fruit.toLowerCase())));
                                            const matchedTag2 = metric.data1[0].tags2.filter((str) => tags.some((fruit) => str.toLowerCase().includes(fruit.toLowerCase())));
                                            const matchedTag3 = metric.data1[0].tags3.filter((str) => tags.some((fruit) => str.toLowerCase().includes(fruit.toLowerCase())));

                                            frame.forEach((t) => {
                                                matchedTag1.forEach((i) => {

                                                    if (i.trim().toLowerCase().includes(t.title.trim().toLowerCase())) {
                                                        !tag.includes(t.id) && tag.push(t.id)
                                                    }


                                                })
                                                matchedTag2.forEach((i) => {

                                                    if (i.trim().toLowerCase().includes(t.title.trim().toLowerCase())) {
                                                        !tag.includes(t.id) && tag.push(t.id)
                                                    }


                                                })
                                                matchedTag3.forEach((i) => {

                                                    if (i.trim().toLowerCase().includes(t.title.trim().toLowerCase())) {
                                                        !tag.includes(t.id) && tag.push(t.id)
                                                    }


                                                })
                                            })
                                            console.log(matchedTag1, matchedTag2, matchedTag3, tag)
                                            if (one || two || three) {
                                                framework.push({ tags: tag, overallTags: [metric.data1[0].tags1, metric.data1[0].tags2, metric.data1[0].tags3], cat_id: cat.id, top_id: topic.id, cat_title: cat.title, top_title: topic.title, ...metric })
                                            }
                                            // }

                                        }
                                    }
                                }

                            })
                        }
                    })
                }
            }


        })
        loclist.metric = framework
        loclist.category = categoryList
        loclist.topic = topicList
        loc.category = categoryList.filter((i) => { return loc.category.includes(i.id) }).map((k) => { return k.id })
        loc['topic'] = topicList.filter((i) => { return loc.topic.includes(i.id) }).map((k) => { return k.id })
        loc.framework = frameList.filter((i) => { return loc.framework.includes(i.id) }).map((k) => { return k.id })
        console.log(loc)
        setSelected(loc)

        setList(loclist)
        forceUpdate()
    }

    const getMonthsLuxon = (startDate, endDate, frequency, item, old) => {

        var betweenMonths = [];
        let type = 0, past_ = [], present_ = [], future_ = []

        past_ = JSON.parse(JSON.stringify(past))
        present_ = JSON.parse(JSON.stringify(present))
        future_ = JSON.parse(JSON.stringify(future))

        if (Math.round(startDate.diff(DateTime.utc().startOf('month'), 'days').days).toFixed(0) === 0) {
            let endDate_ = startDate

            let endDate__ = DateTime.utc().toLocal()
            if (frequency === 1) {

                let check = checkSubmission(item.dcf_, item.site, endDate__.toFormat('LLL-yyyy'), old)

                if (check.result) {

                    future_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: endDate__.toFormat('LLL-yyyy'), duedate: endDate__.plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: 0, site: item.site, company_id: login_data.information.cid, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })

                } else {
                    if (check.data) {

                        future_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: endDate__.toFormat('LLL-yyyy'), duedate: endDate__.plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: 0, site: item.site, company_id: login_data.information.cid, frequency })
                    }

                }
            } else if (frequency === 6) {
                let check = checkSubmission(item.dcf_, item.site, endDate__.toFormat('LLL-yyyy'), old)

                if (check.result) {


                    past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: endDate__.toFormat('LLL-yyyy'), duedate: endDate__.toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(endDate__, 'days').days), site: item.site, company_id: login_data.information.cid, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                } else {
                    if (check.data) {

                        past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: endDate__.toFormat('LLL-yyyy'), duedate: endDate__.toFormat('LLL-yyyy'), overdue: 0, site: item.site, type: 6, company_id: login_data.information.cid, frequency })
                    }

                }


            }

        }
        else if (Math.round(startDate.diff(DateTime.utc().startOf('month'), 'days').days) < 31) {
            var date = startDate
            console.log(startDate.diff(endDate.startOf('month'), 'days').days)


            let endDate__ = DateTime.utc().toLocal()
            while (date <= endDate) {

                betweenMonths.push(date.toFormat('LLL-yyyy'));
                date = date.plus({ months: 1 })

            }
            console.log(betweenMonths, item.id)
            if (frequency === 1) {
                console.log(splitArray(betweenMonths, 1), 'SP')
                splitArray(betweenMonths, 1).forEach((months, ind) => {
                    console.log(endDate__.month === DateTime.fromFormat(months[0], 'LLL-yyyy').month && endDate__.year === DateTime.fromFormat(months[0], 'LLL-yyyy').year)
                    if (endDate__.month === DateTime.fromFormat(months[0], 'LLL-yyyy').month && endDate__.year === DateTime.fromFormat(months[0], 'LLL-yyyy').year) {
                        let check = checkSubmission(item.dcf_, item.site, months[0], old)

                        if (check.result) {


                            future_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: months[0], duedate: DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: login_data.information.cid, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                        } else {
                            if (check.data) {
                                future_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: months[0], duedate: DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: login_data.information.cid, frequency })

                            }

                        }


                    } else {

                        if (Math.round(endDate__.diff(DateTime.fromFormat(months[0], 'LLL-yyyy'), 'months').months) === 1) {

                            // if (endDate__ <= moment(months[0]).add(1, 'month').add(4, 'days')) {
                            let check = checkSubmission(item.dcf_, item.site, months[0], old)


                            if (check.result) {


                                past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: months[0], duedate: DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: login_data.information.cid, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                            } else {
                                if (check.data) {
                                    past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: months[0], duedate: DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: login_data.information.cid, frequency })

                                }

                            }


                        } else {
                            let check = checkSubmission(item.dcf_, item.site, months[0], old)
                            console.log(check, months[0])
                            if (check.result) {


                                past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: months[0], duedate: DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: login_data.information.cid, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                            } else {
                                if (check.data) {

                                    past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: months[0], duedate: DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: login_data.information.cid, frequency })

                                }

                            }
                        }
                    }

                })
            } else if (frequency === 2) {

                splitArray(betweenMonths, 2).forEach((months) => {
                    if (months.length === 2) {
                        if (endDate__.month === DateTime.fromFormat(months[1], 'LLL-yyyy').month && endDate__.year === DateTime.fromFormat(months[1], 'LLL-yyyy').year) {
                            let check = checkSubmission(item.dcf_, item.site, months[0] + ' to ' + months[1], old)
                            if (check.result) {

                                future_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: months[0] + ' to ' + months[1], duedate: DateTime.fromFormat(months[1], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[1], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: login_data.information.cid, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                            } else {
                                if (check.data) {
                                    future_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: months[0] + ' to ' + months[1], duedate: DateTime.fromFormat(months[1], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[1], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: login_data.information.cid, frequency })


                                }

                            }


                        } else {
                            if (Math.round(endDate__.diff(DateTime.fromFormat(months[1], 'LLL-yyyy'), 'months').months) === 1) {

                                // if (endDate__ <= moment(months[1]).add(1, 'month').add(4, 'days')) {
                                let check = checkSubmission(item.dcf_, item.site, months[0] + ' to ' + months[1], old)
                                if (check.result) {

                                    past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: months[0] + ' to ' + months[1], duedate: DateTime.fromFormat(months[1], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[1], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: login_data.information.cid, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })

                                } else {
                                    if (check.data) {
                                        past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: months[0] + ' to ' + months[1], duedate: DateTime.fromFormat(months[1], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[1], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: login_data.information.cid, frequency })


                                    }

                                }


                            } else {
                                let check = checkSubmission(item.dcf_, item.site, months[0] + ' to ' + months[1], old)

                                if (check.result) {
                                    past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: months[0] + ' to ' + months[1], duedate: DateTime.fromFormat(months[1], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[1], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: login_data.information.cid, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })

                                } else {
                                    if (check.data) {
                                        past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: months[0] + ' to ' + months[1], duedate: DateTime.fromFormat(months[1], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[1], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: login_data.information.cid, frequency })

                                    }

                                }
                            }
                        }
                    }
                })
            } else if (frequency === 3) {

                splitArray(betweenMonths, 3).forEach((months) => {
                    if (months.length === 3) {

                        if (endDate__.month === DateTime.fromFormat(months[2], 'LLL-yyyy').month && endDate__.year === DateTime.fromFormat(months[2], 'LLL-yyyy').year) {
                            let check = checkSubmission(item.dcf_, item.site, months[0] + ' to ' + months[2], old)

                            if (check.result) {

                                future_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: months[0] + ' to ' + months[2], duedate: DateTime.fromFormat(months[2], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[2], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: login_data.information.cid, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                            } else {
                                if (check.data) {
                                    future_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: months[0] + ' to ' + months[2], duedate: DateTime.fromFormat(months[2], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[2], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: login_data.information.cid, frequency })

                                }

                            }


                        } else {
                            if (Math.round(endDate__.diff(DateTime.fromFormat(months[2], 'LLL-yyyy'), 'months').months) === 1) {

                                // if (endDate__ <= moment(months[2]).add(1, 'month').add(4, 'days')) {
                                let check = checkSubmission(item.dcf_, item.site, months[0] + ' to ' + months[2], old)

                                if (check.result) {
                                    past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: months[0] + ' to ' + months[2], duedate: DateTime.fromFormat(months[2], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[2], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: login_data.information.cid, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                                } else {
                                    if (check.data) {
                                        past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: months[0] + ' to ' + months[2], duedate: DateTime.fromFormat(months[2], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[2], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: login_data.information.cid, frequency })

                                    }

                                }



                            } else {

                                let check = checkSubmission(item.dcf_, item.site, months[0] + ' to ' + months[2], old)

                                if (check.result) {
                                    past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: months[0] + ' to ' + months[2], duedate: DateTime.fromFormat(months[2], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[2], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: login_data.information.cid, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                                } else {
                                    if (check.data) {
                                        console.log(item.site, item.dcf_, months[0] + ' to ' + months[2])
                                        past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: months[0] + ' to ' + months[2], duedate: DateTime.fromFormat(months[2], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[2], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: login_data.information.cid, frequency })

                                    }

                                }
                            }
                        }
                    }
                })
            } else if (frequency === 4) {
                splitArray(betweenMonths, 12).forEach((months) => {
                    if (months.length === 12) {
                        if (endDate__.month === DateTime.fromFormat(months[11], 'LLL-yyyy').month && endDate__.year === DateTime.fromFormat(months[11], 'LLL-yyyy').year) {
                            let check = checkSubmission(item.dcf_, item.site, months[0] + ' to ' + months[11], old)

                            if (check.result) {

                                future_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: months[0] + ' to ' + months[11], duedate: DateTime.fromFormat(months[11], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[11], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: login_data.information.cid, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })

                            } else {
                                if (check.data) {
                                    future_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: months[0] + ' to ' + months[11], duedate: DateTime.fromFormat(months[11], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[11], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: login_data.information.cid, frequency })

                                }

                            }


                        } else {
                            if (Math.round(endDate__.diff(DateTime.fromFormat(months[11], 'LLL-yyyy'), 'months').months) === 1) {

                                // if (endDate__ <= moment(months[11]).add(1, 'month').add(4, 'days')) {

                                let check = checkSubmission(item.dcf_, item.site, months[0] + ' to ' + months[11], old)

                                if (check.result) {

                                    past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: months[0] + ' to ' + months[11], duedate: DateTime.fromFormat(months[11], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[11], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: login_data.information.cid, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })

                                } else {
                                    if (check.data) {
                                        past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: months[0] + ' to ' + months[11], duedate: DateTime.fromFormat(months[11], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[11], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: login_data.information.cid, frequency })

                                    }

                                }


                            } else {
                                let check = checkSubmission(item.dcf_, item.site, months[0] + ' to ' + months[11], old)

                                if (check.result) {

                                    past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: months[0] + ' to ' + months[11], duedate: DateTime.fromFormat(months[11], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[11], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: login_data.information.cid, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })

                                } else {
                                    if (check.data) {
                                        past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: months[0] + ' to ' + months[11], duedate: DateTime.fromFormat(months[11], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[11], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: login_data.information.cid, frequency })

                                    }

                                }
                            }
                        }
                    }
                })
            } else if (frequency === 5) {
                splitArray(betweenMonths, 6).forEach((months) => {
                    if (months.length === 6) {
                        if (endDate__.month === DateTime.fromFormat(months[5], 'LLL-yyyy').month && endDate__.year === DateTime.fromFormat(months[5], 'LLL-yyyy').year) {
                            let check = checkSubmission(item.dcf_, item.site, months[0] + ' to ' + months[5], old)

                            if (check.result) {


                                future_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: months[0] + ' to ' + months[5], duedate: DateTime.fromFormat(months[5], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[5], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: login_data.information.cid, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                            } else {
                                if (check.data) {
                                    future_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: months[0] + ' to ' + months[5], duedate: DateTime.fromFormat(months[5], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[5], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: login_data.information.cid, frequency })

                                }

                            }


                        } else {
                            if (Math.round(endDate__.diff(DateTime.fromFormat(months[5], 'LLL-yyyy'), 'months').months) === 1) {

                                // if (endDate__ <= moment(months[5]).add(1, 'month').add(4, 'days')) {
                                let check = checkSubmission(item.dcf_, item.site, months[0] + ' to ' + months[5], old)

                                if (check.result) {


                                    past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: months[0] + ' to ' + months[5], duedate: DateTime.fromFormat(months[5], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[5], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: login_data.information.cid, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                                } else {
                                    if (check.data) {
                                        past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: months[0] + ' to ' + months[5], duedate: DateTime.fromFormat(months[5], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[5], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: login_data.information.cid, frequency })

                                    }

                                }


                                // } else {
                                //     let check = checkSubmission(item.dcf_, item.site, moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[5]).format('MMM-YYYY'), old)

                                //     if (check.result) {


                                //         past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[5]).format('MMM-YYYY'), duedate: moment(months[5]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[5]).add(1, 'month'), 'days'), site: item.site, company_id: login_data.information.cid, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                                //     } else {
                                //         if (check.data) {
                                //             past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[5]).format('MMM-YYYY'), duedate: moment(months[5]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[5]).add(1, 'month'), 'days'), site: item.site, company_id: login_data.information.cid, frequency })

                                //         }

                                //     }
                                // }
                            } else {
                                let check = checkSubmission(item.dcf_, item.site, months[0] + ' to ' + months[5], old)

                                if (check.result) {


                                    past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: months[0] + ' to ' + months[5], duedate: DateTime.fromFormat(months[5], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[5], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: login_data.information.cid, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                                } else {
                                    if (check.data) {
                                        past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: months[0] + ' to ' + months[5], duedate: DateTime.fromFormat(months[5], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[5], 'LLL-yyyy').plus({ months: 1 }), 'days').days), site: item.site, company_id: login_data.information.cid, frequency })

                                    }

                                }
                            }
                        }
                    }
                })
            } else if (frequency === 6) {
                splitArray(betweenMonths, 1).forEach((months, ind) => {

                    if (endDate__.month === DateTime.fromFormat(months[0], 'LLL-yyyy').month && endDate__.year === DateTime.fromFormat(months[0], 'LLL-yyyy').year) {
                        let check = checkSubmission(item.dcf_, item.site, months[0], old)

                        if (check.result) {
                            past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, dcftype: item.dcf_.type, rp: months[0], duedate: DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[0], 'LLL-yyyy'), 'days').days), site: item.site, company_id: login_data.information.cid, frequency })

                            check.list.forEach((list) => {
                                past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, dcftype: item.dcf_.type, rp: months[0], duedate: DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[0], 'LLL-yyyy'), 'days').days), site: item.site, company_id: login_data.information.cid, frequency, response: list.response, id: list.id, reject: list.reject, draft: list })

                            })

                        } else {
                            if (check.data) {
                                past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, dcftype: item.dcf_.type, rp: months[0], duedate: DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[0], 'LLL-yyyy'), 'days').days), site: item.site, company_id: login_data.information.cid, frequency })

                            }

                        }


                    } else {
                        let check = checkSubmission(item.dcf_, item.site, months[0], old)

                        if (check.result) {

                            check.list.forEach((list) => {
                                past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: months[0], duedate: DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[0], 'LLL-yyyy'), 'days').days), site: item.site, company_id: login_data.information.cid, frequency, response: list.response, id: list.id })

                            })


                        } else {
                            if (check.data) {
                                past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, dcftype: item.dcf_.type, rp: months[0], duedate: months[0], overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[0], 'LLL-yyyy'), 'days').days), site: item.site, company_id: login_data.information.cid, frequency })

                            }

                        }


                    }

                })
            }

        }
        console.log(future_)

        setPast((prev) => ([...prev, ...past_]))
        setPresent((prev) => ([...prev, ...present_]))
        setFuture((prev) => ([...prev, ...future_]))



        return { type, months: betweenMonths }
    }
    const checkSubmission = (dcf, site, rp, old) => {
        // let rps = getRP(rp)
        let rps = getRPLuxon(rp)

        let result = {}
        let loc = JSON.parse(JSON.stringify(old))

        let index = loc.findIndex((i) => {
            return compareArrays(i.reporting_period, rps) && i.site === site && i.dcf === dcf.id && i.type === 0
        })
        let index2 = loc.findIndex((i) => {
            return compareArrays(i.reporting_period, rps) && i.site === site && i.dcf === dcf.id && (i.type === 1 || i.type === 2 || i.type === 3)
        })

        if (index === -1) {

            result = { result: false, data: index2 === -1 ? true : false }
        } else {
            result = {
                result: true, data: loc[index], list: loc.filter((i) => {
                    return compareArrays(i.reporting_period, rps) && i.site === site && i.dcf === dcf.id && i.type === 0
                })
            }
        }
        console.log(old, rps, dcf, site, result)
        return result
    }
    const compareArrays = (a, b) => {


        return JSON.stringify(a) === JSON.stringify(b);
    };
    const splitArray = (array, chunkSize) => {


        return array.reduce((resultArray, item, index) => {
            const chunkIndex = Math.floor(index / chunkSize)

            if (!resultArray[chunkIndex]) {
                resultArray[chunkIndex] = [] // start a new chunk
            }

            resultArray[chunkIndex].push(item)

            return resultArray
        }, [])
    }
    const getRP = (months) => {
        if (months.includes('to')) {
            let startDate = moment(months.split('to')[0].trim())
            let endDate = moment(months.split('to')[1].trim())
            let rp = []
            while (startDate <= endDate) {

                rp.push(startDate.format('MM-YYYY'));
                startDate.add(1, 'month');


            }
            return rp
        } else {
            console.log(months, 'months')
            return [moment(months).format('MM-YYYY')]
        }
    }
    const getRPLuxon = (months) => {
        if (months.includes('to')) {
            let startDate = DateTime.fromFormat(months.split('to')[0].trim(), 'LLL-yyyy')
            let endDate = DateTime.fromFormat(months.split('to')[1].trim(), 'LLL-yyyy')
            let rp = []
            while (startDate <= endDate) {
                rp.push(startDate.toFormat('LL-yyyy'));
                startDate = startDate.plus({ months: 1 })
            }
            return rp
        } else {
            return [DateTime.fromFormat(months, 'LLL-yyyy').toFormat('LL-yyyy')]
        }
    }
    const onSiteSelected = (val) => {

        setPast((prev) => [])
        setPresent((prev) => ([]))
        setFuture((prev) => ([]))
        forceUpdate()
        setSelectedSite(val)
    }
    const updateItemHover = (ind, val, item) => {

        if (val === 1) {
            item.map((i, j) => {
                if (j === ind) {
                    item.hover = 1
                } else {
                    item.hover = 0
                }
            })
        } else {
            item.map((i, j) => {

                item.hover = 0

            })
        }
        setPast(item)
        forceUpdate()
    }
    const tab1HeaderTemplate = (options) => {
        return (
            <button type="button" onClick={options.onClick} className={options.className}>
                <i className="pi pi-pencil mr-2" />
                {options.titleElement}
            </button>
        );
    };
    const tab2HeaderTemplate = (options) => {
        return (
            <button type="button" onClick={options.onClick} className={options.className}>
                <i className="pi pi-book mr-2" />
                {options.titleElement}
            </button>
        );
    };
    const tab3HeaderTemplate = (options) => {
        return (
            <button type="button" onClick={options.onClick} className={options.className}>
                <i className="pi pi-book mr-2" />
                {options.titleElement}
            </button>
        );
    };
    const formTemplate = (rowData) => {
        let index = dcflist.findIndex((k) => { return k.id === rowData.dcf })
        return (
            <span>{dcflist[index] !== undefined ? dcflist[index].title : 'Invalid DCF Found'}</span>
        )
    }
    const siteTemplate = (rowData) => {
        let index = sitelist.findIndex((k) => { return k.id === rowData.site })

        return (
            <span>{sitelist[index] !== undefined ? sitelist[index].name : 'Invalid Site Found'}</span>
        )
    }
    const siteTemplate_ = (rowData) => {

        let index = sitelistall.findIndex((k) => { return k.id === rowData.site })

        return (
            <span>{sitelistall[index] !== undefined ? sitelistall[index].name : 'Invalid Site Found'}</span>
        )
    }
    const rpTemplate = (rowData) => {
        let rp = ''
        if (rowData.reporting_period.length === 1) {

            rp = moment(rowData.reporting_period[0], 'MM-YYYY').format('MMM-YYYY')

        } else {
            rp = moment(rowData.reporting_period[0], 'MM-YYYY').format('MMM-YYYY') + ' to ' + moment(rowData.reporting_period[rowData.reporting_period.length - 1], 'MM-YYYY').format('MMM-YYYY')
        }
        return (
            <span>{rp}</span>
        )
    }
    const statusTemplate = (rowData) => {

        return (
            <>
                {rowData.reject === 1 && rowData.type === 0 && <Tooltip position="top" target={".tooltip" + rowData.id} />}
                <span style={{ cursor: 'pointer' }} className={`tooltip` + rowData.id} data-pr-tooltip={rowData.data2}>
                    <Tag style={{ padding: '4px 8px' }} className={(rowData.type !== 1 && rowData.type !== 3) ? "status-tag-red" : "status-tag-green"}>{rowData.type === 1 ? 'Submitted' : rowData.type === 3 ? 'Approved' : 'Rejected'}</Tag>
                </span>
            </>
        )
    }
    const recall = async (rowData) => {
        const { value: accept } = await Swal.fire({
            title: `<div style="overflow:visible;font-size:20px;font-weight:600;margin-top:0px">Recall DCF Submission</div>`,
            html: `<div style="overflow:auto;max-height:200px" >Are you sure want to recall this submission</div>`,

            confirmButtonColor: 'red',
            showCancelButton: true,

            confirmButtonText:
                'Revoke submission',

        })
        if (accept) {
            rowData.type = 0;
            rowData.reject = 0;
            rowData.modified_on = moment.utc()
            delete rowData.approved_by
            delete rowData.approved_on
            delete rowData.data2
            delete rowData.rejected_on

            APIServices.patch(API.DCF_Submission_Edit(rowData.id), rowData).then((res) => {
                setPast([]);
                setPresent([])
                setFuture([])

                Swal.fire({
                    title: "Data Submission has been revoked",

                    confirmButtonText: 'Exit',
                    allowOutsideClick: false,
                }).then((result) => {
                    if (result.isConfirmed) {
                        setUpdate(true)
                        let local = submitteddcf
                        local.splice(local.findIndex((k) => { return k.id === rowData.id }), 1)
                        setSubmittedDCF(local)

                        forceUpdate()
                    }
                })
            }).catch((e) => {

                Swal.fire({
                    title: "Something went wrong, try after some time. Contact admin if issue still persist",

                    confirmButtonText: 'Exit',
                    allowOutsideClick: false,
                }).then((result) => {

                })

            })
        }
    }
    const actionReportorTemplate = (rowData) => {
        delete rowData.form_type
        return (
            <>
                <span>
                    <span className="fw-7 fs-16 clr-navy cur-pointer" onClick={() => { rowData.form_type === 2 ? navigate.push({ pathname: "/cf_submission_preview/" + rowData.dcf, state: rowData }) : navigate.push({ pathname: "/submission_preview/" + rowData.dcf, state: rowData }) }}>View</span>
                    {rowData.type !== 3 && rowData.reject === 0 && <> &nbsp;/ &nbsp;
                        <span className="fw-7 fs-16 clr-navy cur-pointer" onClick={() => { recall(rowData) }}>Recall</span>
                    </>}
                </span>

            </>
        )
    }
    const actionReportorTemplate_RF = (rowData) => {
        console.log(rowData)
        return (
            <>
                <span>
                    <span style={{ textDecoration: 'underline', cursor: 'pointer', color: 'cornflowerblue' }} onClick={() => { rowData.form_type === 2 && navigate.push({ pathname: "/cf_rf_input_entry/" + rowData.dcf, state: rowData }) }}>Update</span>

                </span>

            </>
        )
    }
    const actionReportorTemplate_ = (rowData) => {
        return (
            <>
                <span>
                    <span className="fs-16 fw-7 clr-navy" onClick={() => { rowData.form_type === 2 ? navigate.push({ pathname: "/cf_submission_preview/" + rowData.dcf, state: rowData }) : navigate.push({ pathname: "/submission_preview/" + rowData.dcf, state: rowData }) }}>View</span>
                </span>

            </>
        )
    }
    const submissionTemplate = (rowData) => {
        let date = moment.utc(rowData.created_on).format('DD-MM-YYYY')
        return (
            <span>{date}</span>
        )
    }
    const lastUpdateTemplate = (rowData) => {


        let date = moment.utc(rowData.modified_on).format('DD-MM-YYYY')
        return (
            <span>{date}</span>
        )
    }
    const titleTemplate = (rowData) => {
        console.log(rowData)
        return (
            < >
                <Tooltip className="tag-tooltip" target={".tags" + rowData.id} position={'top'} autoHide={true}> {rowData.overallTags !== undefined && rowData.overallTags.map((i, j) => {
                    if (i.length !== 0) {
                        return (
                            <>
                                <label style={{ color: 'black', display: 'flex' }}> {
                                    j === 0 ? 'Must Have' : j === 1 ? 'Progressive' : 'Advanced'

                                }
                                </label>
                                {
                                    i.map((tag, k) => {

                                        return (
                                            <label style={{ color: 'green' }}>{tag}{k !== i.length - 1 && ','}</label>
                                        )

                                    })
                                }
                                <div style={{ marginBottom: 10 }} />
                            </>
                        )
                    }
                })} </Tooltip>
                <div style={{ alignItems: 'center' }} >{rowData.title} <i className={"material-icons " + "tags" + rowData.id} style={{ fontSize: 14, cursor: 'pointer' }}>info</i>  </div>

            </>
        )
    }
    const lastResponse = (rowData) => {

        let text = 'Not Responded'
        let data = rowData.data1[0]

        let index = rfresponse.findLastIndex((i) => { return i.rfid === data.rf && rowData.top_id === i.topicId && rowData.id === i.indicatorId && rowData.cat_id === i.categoryId })
        if (index !== -1) {
            text = moment.utc(rfresponse[index].created_on).format('DD MMMM YYYY')

        }
        return (
            <>
                {text}
            </>
        )
    }
    const rrTemplate = (rowData) => {
        let data = rowData.data1[0], oldData = [], id = 0, show = true

        let index = rfresponse.findLastIndex((i) => { return i.rfid === data.rf && rowData.top_id === i.topicId && rowData.id === i.indicatorId && rowData.cat_id === i.categoryId })
        if (index !== -1) {
            oldData = rfresponse[index]

        }

        return (
            < >
                <a style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => { navigate.push({ pathname: '/rf_input_entry/' + rowData.data1[0].rf, state: { data: rowData, oldData } }) }}>       {rowData.data1[0].title} </a>

            </>
        )
    }
    const historyTemplate = (rowData) => {
        let text = true
        let data = rowData.data1[0]
        let mergeData = []
        console.log(rowData)
        let index = rfresponse.findLastIndex((i) => { return i.rfid === data.rf && rowData.top_id === i.topicId && rowData.id === i.indicatorId && rowData.cat_id === i.categoryId })
        if (index !== -1) {
            text = false
            mergeData = rfresponse.filter((i) => { return i.rfid === data.rf && rowData.top_id === i.topicId && rowData.id === i.indicatorId && rowData.cat_id === i.categoryId })
            mergeData.forEach((i) => {
                i.top_title = rowData.top_title
                i.cat_title = rowData.cat_title
            })
        }
        return (
            <>
                {text ?
                    <span>NA</span> :
                    <a onClick={() => { setHistoryData(mergeData); activeRowData = rowData; setHistoryDialog(true) }}>View History</a>
                }

            </>
        )
    }
    const approverNameTemplate = (rowData) => {
        let approver_name = 'Approver not found'
        let approver_id = dcfassign.filter((i) => { return i.site === rowData.site && i.dcfId === rowData.dcf })

        if (approver_id.length !== 0) {
            let approverIndex = userList.findIndex((k) => { return k.id === approver_id[0].approver_id })
            if (approverIndex !== -1) {
                approver_name = userList[approverIndex].information.empname
            } else if (approver_id[0].approver_id === client_info.id) {
                approver_name = 'Admin'
            }
        }
        return (
            <span className="fw-7 fs-16">
                {approver_name}
            </span>
        )
    }
    const statusTemplate_ = (rowData) => {

        return (
            <>
                {rowData.reject === 1 && rowData.type === 0 && <Tooltip position="top" target={".tooltip" + rowData.id} />}
                <span className={+` tooltip` + rowData.id} data-pr-tooltip={rowData.data2}>
                    <Tag style={{ padding: '4px 8px' }} className={rowData.type === 3 ? "status-tag-green" : "status-tag-red"}>  {rowData.type === 3 ? 'Approved' : 'Rejected'}  </Tag>
                </span>
            </>
        )
    }
    const viewTemplate = (rowData) => {
        console.log(rowData)
        return (
            <span className="fw-7 fs-16 clr-navy cur-pointer" onClick={() => { rowData.form_type === 2 ? navigate.push({ pathname: "/cf_input_approve/" + rowData.dcf, state: rowData }) : navigate.push({ pathname: "/data_input_approve/" + rowData.dcf, state: rowData }) }}>Approve</span>
        )
    }
    const checkResponsibility = (rfid) => {

        return rfass.findIndex((k) => { return  k.reporter_ids !== null && k.reporter_ids.includes(login_data.id) && k.rfid === rfid }) === -1 ? false : true
    }
    const renderPreview = () => {


        return (
            <div className="col-12 grid" >
                {selectedlist.data.map((i, j) => {
                    return (
                        <label className="col-12">{j + 1}. &nbsp; {i.title}</label>
                    )
                })

                }
            </div>
        )
    }
    const items = [
        { label: `Quantitive Data to be submitted by me (${past.length + present.length})` },
        { label: `Qualitative Data to be submitted by me (${rfass.filter((k) => { return k.user_id === login_data.id }).length})` },
        { label: `Quantitive Data to be approved by me (${pendingap.filter((i) => { return (i.type === 1 || i.type === 2) }).length})` },
        { label: `Status of my Quantitative Submissions (${submitteddcf.filter((i) => { return (i.type !== 0 || i.reject === 1) && i.submitted_by === login_data.id }).length}) ` },
        { label: `Quantitive Approved by me (${pendingap.filter((i) => { return (i.reject === 1 || i.type === 3) }).length})` }
    ]
    const verticalText = (str) => {
        let div = document.createElement('div')
        div.style.position = 'absolute'
        div.style.right = '10px'
        div.style.top = '1%'
        div.style.flexDirection = 'column'
        div.style.opacity = 0.5
        div.className = 'flex'

        for (var i = 0; i < str.trim().length; i++) {
            let charc = str.trim()
            let label = document.createElement('label')
            label.innerHTML = charc[i]
            label.style = "color: lightgray; font-weight: 900; font-size: 18px;text-shadow: 1px 2px gray;"
            div.append(label)
        }

        return div.outerHTML
    }
    let notification = [{ title: 'STT GDC Issues S$450M sustainability-linked perpetual securities to drive sustainability initiatives and fuel global expansion', time: '01:45 PM', date: '11-11-2023' },
    { title: 'STT GDC Issues S$450M sustainability-linked perpetual securities to drive sustainability initiatives and fuel global expansion', time: '01:45 PM', date: '11-11-2023' },
    { title: 'STT GDC Issues S$450M sustainability-linked perpetual securities to drive sustainability initiatives and fuel global expansion', time: '01:45 PM', date: '11-11-2023' },
    { title: 'STT GDC Issues S$450M sustainability-linked perpetual securities to drive sustainability initiatives and fuel global expansion', time: '01:45 PM', date: '11-11-2023' },
    { title: 'STT GDC Issues S$450M sustainability-linked perpetual securities to drive sustainability initiatives and fuel global expansion', time: '01:45 PM', date: '11-11-2023' }
    ]
    return (
        <div className="bg-smoke font-lato" >
            <div className="col-12" >
                <div style={{}} >
                    <div className="col-12 p-5" style={{
                        justifyContent: 'flex-start'
                    }}>
                        <label className="text-big-one clr-navy flex fs-16 flex justify-content-start"> DATA SUBMISSION AND REVIEW PORTAL
                        </label>
                        <label className="text-micro clr-navy flex  justify-content-start">Your one-stop portal to view and take action on all your sustainability data submission and approvals.</label>

                    </div>
                    {/* <TabMenu model={items} activeIndex={activetab} onTabChange={(e) => setActiveTab(e.index)} /> */}
                    {(show.approver || show.entry || (login_data.id !== login_data.clientId )) ? <div classname='bg-white'>
                        {/* <div className="col-12">
                            <div className="grid" style={{ justifyContent: 'space-between' }}>
                                {show.entry && <>    <div className=" p-card" style={{ width: !show.approver ? '22%' : '14%', position: 'relative', background: activetab === 1 ? 'darkgrey' : 'white' }} onClick={() => { activetab !== 1 && setActiveTab(1) }}>
                                    <label style={{ display: 'flex', padding: 25, justifyContent: 'center', fontWeight: 600, color: activetab === 1 && 'white' }}>Reporter Data Input</label>
                                    <label style={{
                                        position: 'absolute',
                                        bottom: '10px',
                                        color: activetab === 1 ? 'white' : 'red',
                                        right: '10px',
                                        fontSize: '20px'
                                    }}>{past.length + present.length}</label>
                                </div>
                                    <div className=" p-card" style={{ width: !show.approver ? '22%' : '14%', position: 'relative', background: activetab === 2 ? 'darkgrey' : 'white' }} onClick={() => { activetab !== 2 && setActiveTab(2) }}>
                                        <label style={{ display: 'flex', padding: 25, justifyContent: 'center', fontWeight: 600, color: activetab === 2 && 'white' }}>Submitted Data Logs</label>
                                        <label style={{
                                            position: 'absolute',
                                            bottom: '10px',
                                            color: activetab === 2 && 'white',
                                            right: '10px',
                                            fontSize: '20px'
                                        }}>{submitteddcf.filter((i) => { return (i.type !== 0 || i.reject === 1) && i.submitted_by === login_data.id }).length}</label>
                                    </div>
                               
                                </>}
                                {show.approver && <> <div className=" p-card" style={{ width: show.approver ? '18.5%' : '32%', position: 'relative', background: activetab === 3 ? 'darkgrey' : 'white' }} onClick={() => { activetab !== 3 && setActiveTab(3) }}>
                                    <label style={{ display: 'flex', padding: 25, justifyContent: 'center', fontWeight: 600, color: activetab === 3 && 'white' }}>Submission Reviewer Portal</label>
                                    <label style={{
                                        position: 'absolute',
                                        bottom: '10px',
                                        color: activetab === 3 ? 'white' : 'red',
                                        right: '10px',
                                        fontSize: '20px'
                                    }}>{pendingap.filter((i) => { return (i.type === 1 || i.type === 2) }).length}</label>
                                </div>
                                    <div className=" p-card" style={{ width: show.approver ? '18.5%' : '32%', position: 'relative', background: activetab === 4 ? 'darkgrey' : 'white' }} onClick={() => { activetab !== 4 && setActiveTab(4) }}>
                                        <label style={{ display: 'flex', padding: 25, justifyContent: 'center', fontWeight: 600, color: activetab === 4 && 'white' }}>Approved Data Logs</label>
                                        <label style={{
                                            position: 'absolute',
                                            bottom: '10px',
                                            color: activetab === 4 && 'white',
                                            right: '10px',
                                            fontSize: '20px'
                                        }}>{pendingap.filter((i) => { return (i.reject === 1 || i.type === 3) }).length}</label>
                                    </div>
                                </>}
                                {login_data.id !== login_data.clientId && <div className=" p-card" style={{ width: (show.entry && show.approver) ? '14%' : show.entry ? '22%' : '32%', position: 'relative', background: activetab === 5 ? 'darkgrey' : 'white' }} onClick={() => { activetab !== 5 && setActiveTab(5) }}>
                                    <label style={{ display: 'flex', padding: 25, justifyContent: 'center', fontWeight: 600, color: activetab === 5 && 'white' }}>Qualitative Response</label>
                                    <label style={{
                                        position: 'absolute',
                                        bottom: '10px',
                                        color: activetab === 5 ? 'white' : 'red',
                                        right: '10px',
                                        fontSize: '20px'
                                    }}>{rfass.filter((k) => { return k.user_id === login_data.id }).length}</label>
                                </div>}
                            </div>
                        </div> */}
                        <div className="col-12">
                            <div className="col-8 p-4 grid align-items-center" style={{ marginBottom: 5 }} >
                                <div className="col-2">
                                    <label>Select Site : &nbsp; &nbsp;</label>
                                </div>
                                <div className="col-8">

                                    <Dropdown options={sitelist} optionValue='id' style={{ width: '100%' }} optionLabel='name' value={selectedsite} onChange={(e) => { onSiteSelected(e.value) }} />

                                </div>
                            </div>

                        </div>
                        <div className="col-12  justify-content-between">
                            <div className="12">
                                {show.entry &&
                                    <div className="p-card">
                                        <label className="text-big-one clr-gray-900 flex fs-16 fw-7 p-2  flex"> Your Submissions </label>
                                        <div className="col-12 flex justify-content-evenly" style={{ borderTop: '1px solid #E0E0E0' }}>
                                            <div className="col-12 grid">
                                                <div className="col-4 flex justify-content-center" >
                                                    <div className=" flex justify-content-center admin-card" style={{ height: 120 }} onMouseLeave={(e) => { e.currentTarget.style.background = '#ffffff'; e.currentTarget.className = ' flex admin-card justify-content-center '; e.target.style.cursor = 'pointer' }} onMouseOver={(e) => { e.preventDefault(); e.currentTarget.style.backgroundColor = '#31597510'; e.currentTarget.className = ' flex justify-content-center admin-card  box-shadow-one' }} onClick={() => { navigate.push({ pathname: '/reporter_overdue/' + selectedsite, past  }) }} >
                                                        <div className="flex justify-content-between align-items-center " style={{flexDirection:'row'}}>
                                                            <div className="big-number-navy col-2 fs-36">{past.length}</div>
                                                            <div className='col-10  grid justify-content-between'>
                                                            <div className="clr-navy   fs-18 text-bold">Quantitative</div>
                                                            <div className="flex align-items-center"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M4 12H20M20 12L14 6M20 12L14 18" stroke="#315975" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                            </svg>
                                                            </div>
                                                            </div>

                                                        </div>
                                                        {/* <div className="justify-content-between grid">
                                                            <div className="clr-navy fs-12 text-bold">REQUIRED SUBMISSIONS</div>
                                                            <div><svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M4 12H20M20 12L14 6M20 12L14 18" stroke="#315975" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                            </svg>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                </div>
                                                <div className="col-4 flex justify-content-center" >
                                                    <div className=" flex justify-content-center admin-card" style={{ height: 120 }} onMouseLeave={(e) => { e.currentTarget.style.background = '#ffffff'; e.currentTarget.className = ' flex admin-card justify-content-center '; e.target.style.cursor = 'pointer' }} onMouseOver={(e) => { e.preventDefault(); e.currentTarget.style.backgroundColor = '#31597510'; e.currentTarget.className = ' flex justify-content-center admin-card box-shadow-one' }} onClick={() => { }} >
                                                        <div className="flex justify-content-between align-items-center " style={{flexDirection:'row'}}>
                                                            <div className="big-number-navy col-2 fs-36">{0}</div>
                                                          <div className='col-10  grid justify-content-between'>
                                                            <div className="clr-navy   fs-18 text-bold">Qualitative</div>
                                                            <div className="flex align-items-center"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M4 12H20M20 12L14 6M20 12L14 18" stroke="#315975" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                            </svg>
                                                            </div>
                                                            </div>
                                                        </div>

                                                        {/* <div className="justify-content-between grid">
                                                            <div className="clr-navy fs-12 text-bold">Qualitative</div>
                                                            <div><svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M4 12H20M20 12L14 6M20 12L14 18" stroke="#315975" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                            </svg>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                </div>
                                                <div className="col-4 flex justify-content-center" >
                                                    <div className=" flex justify-content-center admin-card" style={{ height: 120 }} onMouseLeave={(e) => { e.currentTarget.style.background = '#ffffff'; e.currentTarget.className = ' flex admin-card justify-content-center '; e.target.style.cursor = 'pointer' }} onMouseOver={(e) => { e.preventDefault(); e.currentTarget.style.backgroundColor = '#31597510'; e.currentTarget.className = ' flex justify-content-center admin-card  box-shadow-one' }} onClick={() => { navigate.push({ pathname: '/reporter_historic/' + selectedsite, past }) }} >
                                                        <div className="flex justify-content-between align-items-center " style={{flexDirection:'row'}}>
                                                            <div className="big-number-navy col-2 fs-36">{submitteddcf.filter((i) => { return i.form_type !== 2 && i.type !== 0 && (i.site === selectedsite || selectedsite === 0) && i.submitted_by === login_data.id }).length}</div>
                                                            <div className='col-10  grid justify-content-between'>
                                                            <div className="clr-navy   fs-18 text-bold">Historic</div>
                                                            <div className="flex align-items-center"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M4 12H20M20 12L14 6M20 12L14 18" stroke="#315975" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                            </svg>
                                                            </div>
                                                            </div>
                                                        </div>

                                                        {/* <div className="justify-content-between grid">
                                                            <div className="clr-navy fs-12 text-bold">HISTORIC</div>
                                                            <div><svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M4 12H20M20 12L14 6M20 12L14 18" stroke="#315975" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                            </svg>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    
                                    </div>
                                }
                                {show.approver &&
                                    <div className="p-card mt-4">
                                        <label className="text-big-one clr-gray-900 fw-7 p-2 flex fs-16 flex"> Your Reviews </label>
                                 
                                        <div className="col-12 flex justify-content-evenly" style={{ borderTop: '1px solid #E0E0E0' }}>
                                            <div className="col-12 grid">
                                                <div className="col-4 flex justify-content-center" >
                                                    <div className=" flex justify-content-center admin-card" style={{ height: 120 }} onMouseLeave={(e) => { e.currentTarget.style.background = '#ffffff'; e.currentTarget.className = ' flex admin-card justify-content-center '; e.target.style.cursor = 'pointer' }} onMouseOver={(e) => { e.preventDefault(); e.currentTarget.style.backgroundColor = '#31597510'; e.currentTarget.className = ' flex justify-content-center admin-card  box-shadow-one' }} onClick={() => { navigate.push({ pathname: '/reviewer_overdue/' + selectedsite  }) }} >
                                                        <div className="flex justify-content-between align-items-center " style={{flexDirection:'row'}}>
                                                            <div className="big-number-navy col-2 fs-36">{pendingap.filter((i) => { return (i.type === 1) }).filter(obj => {
                                                                const lastReportingPeriod = obj.reporting_period[obj.reporting_period.length - 1];
                                                                const nextMonth = DateTime.fromFormat(lastReportingPeriod, 'MM-yyyy').plus({ months: 1 }).toLocal();
                                                                console.log((DateTime.local().diff(nextMonth, 'months').months).toFixed(0), nextMonth.month, DateTime.local().month);
                                                                return (DateTime.local().diff(nextMonth, 'months').months).toFixed(0) > 0
                                                            }).length}</div>
                                                            <div className='col-10  grid justify-content-between'>
                                                            <div className="clr-navy   fs-18 text-bold">Quantitative</div>
                                                            <div className="flex align-items-center"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M4 12H20M20 12L14 6M20 12L14 18" stroke="#315975" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                            </svg>
                                                            </div>
                                                            </div>

                                                        </div>
                                                        {/* <div className="justify-content-between grid">
                                                            <div className="clr-navy fs-12 text-bold">REQUIRED SUBMISSIONS</div>
                                                            <div><svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M4 12H20M20 12L14 6M20 12L14 18" stroke="#315975" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                            </svg>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                </div>
                                                <div className="col-4 flex justify-content-center" >
                                                    <div className=" flex justify-content-center admin-card" style={{ height: 120 }} onMouseLeave={(e) => { e.currentTarget.style.background = '#ffffff'; e.currentTarget.className = ' flex admin-card justify-content-center '; e.target.style.cursor = 'pointer' }} onMouseOver={(e) => { e.preventDefault(); e.currentTarget.style.backgroundColor = '#31597510'; e.currentTarget.className = ' flex justify-content-center admin-card box-shadow-one' }} onClick={() => {  }} >
                                                        <div className="flex justify-content-between align-items-center " style={{flexDirection:'row'}}>
                                                            <div className="big-number-navy col-2 fs-36">{0}</div>
                                                          <div className='col-10  grid justify-content-between'>
                                                            <div className="clr-navy   fs-18 text-bold">Qualitative</div>
                                                            <div className="flex align-items-center"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M4 12H20M20 12L14 6M20 12L14 18" stroke="#315975" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                            </svg>
                                                            </div>
                                                            </div>
                                                        </div>

                                                    
                                                    </div>
                                                </div>
                                                <div className="col-4 flex justify-content-center" >
                                                    <div className=" flex justify-content-center admin-card" style={{ height: 120 }} onMouseLeave={(e) => { e.currentTarget.style.background = '#ffffff'; e.currentTarget.className = ' flex admin-card justify-content-center '; e.target.style.cursor = 'pointer' }} onMouseOver={(e) => { e.preventDefault(); e.currentTarget.style.backgroundColor = '#31597510'; e.currentTarget.className = ' flex justify-content-center admin-card  box-shadow-one' }} onClick={() => { navigate.push({ pathname: '/reviewer_historic/' + selectedsite }) }} >
                                                        <div className="flex justify-content-between align-items-center " style={{flexDirection:'row'}}>
                                                            <div className="big-number-navy col-2 fs-36">{submitteddcf.filter((i) => { return i.form_type !== 2 && i.type === 2 && (i.site === selectedsite || selectedsite === 0) && i.reviewed_by === login_data.id }).length}</div>
                                                            <div className='col-10  grid justify-content-between'>
                                                            <div className="clr-navy   fs-18 text-bold">Historic</div>
                                                            <div className="flex align-items-center"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M4 12H20M20 12L14 6M20 12L14 18" stroke="#315975" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                            </svg>
                                                            </div>
                                                            </div>
                                                        </div>

                                                        {/* <div className="justify-content-between grid">
                                                            <div className="clr-navy fs-12 text-bold">HISTORIC</div>
                                                            <div><svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M4 12H20M20 12L14 6M20 12L14 18" stroke="#315975" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                            </svg>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {login_data.id !== login_data.clientId && list.metric !== null &&
                                    <div className="p-card mt-4">
                                        <label className="text-big-one clr-gray-900 fw-7 p-2 flex fs-16 flex"> Your Qualitative  </label>
                                        <div className="col-12 flex justify-content-evenly" style={{ borderTop: '1px solid #E0E0E0' }}>
                                            <div className="col-12 grid">
                                            <div className="col-4 flex justify-content-center" >
                                                    <div className=" flex justify-content-center admin-card" style={{ height: 120 }} onMouseLeave={(e) => { e.currentTarget.style.background = '#ffffff'; e.currentTarget.className = ' flex admin-card justify-content-center '; e.target.style.cursor = 'pointer' }} onMouseOver={(e) => { e.preventDefault(); e.currentTarget.style.backgroundColor = '#31597510'; e.currentTarget.className = ' flex justify-content-center admin-card  box-shadow-one' }} onClick={() => { navigate.push({ pathname: '/qualitative_historic/'   }) }} >
                                                        <div className="flex justify-content-between align-items-center " style={{flexDirection:'row'}}>
                                                            <div className="big-number-navy col-2 fs-36">{list.metric.filter((i) => { return i.data1 !== null && checkResponsibility(i.data1[0].rf, i) }).length}</div>
                                                            <div className='col-10  grid justify-content-between'>
                                                            <div className="clr-navy   fs-18 text-bold">HISTORIC</div>
                                                            <div className="flex align-items-center"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M4 12H20M20 12L14 6M20 12L14 18" stroke="#315975" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                            </svg>
                                                            </div>
                                                            </div>

                                                        </div>
                                                  
                                                    </div>
                                                </div>
                                            

                                            </div>
                                        </div>
                                    </div>
                                }


                            </div>

                            <div className="col-12">

                                <div style={{ background: '#41AA950D' }}>
                                    <label className="fw-7 fs-16 clr-gray-900 flex p-3">Recent updates</label>
                                    <div style={{ overflow: 'auto', height: 450 }}>
                                        <label className="m-2 flex justify-content-center" >no updates</label>
                                        {[].map((i) => {
                                            return (
                                                <div style={{ borderBottom: '1px solid #E0E0E0' }}>
                                                    <div className="p-1 flex m-4" style={{ flexDirection: 'column' }} >
                                                        <label className="fs-16 fw-4 clr-gray-900" style={{ width: '100%' }}>{i.title}</label>
                                                        <label classname='fw-4 fs-16 ' style={{ width: '100%', color: '#828282', marginTop: 20 }}>{i.date}
                                                            <svg width="6" height="6" viewBox="0 0 6 6" fill="none" style={{ marginLeft: 5, marginRight: 5 }} xmlns="http://www.w3.org/2000/svg">
                                                                <circle cx="3" cy="3" r="3" fill="#828282" />
                                                            </svg> {i.time}
                                                        </label>

                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div> :
                        <label style={{
                            justifyContent: 'center',
                            display: 'flex',
                            marginTop: '50px'
                        }}>
                            {!show.loaded ? <i className="pi pi-spin pi-spinner" style={{ fontSize: 50 }}></i> :
                                'Nothing configured to your login'
                            }
                        </label>
                    }

                </div>
            </div>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(UserHomeScreenOld, comparisonFn);
