 export const mobile_combustion = [
    {
        "vehicle_category": "Cars (by market segment)",
        "vehicle_type": "Mini",
        "fuel_type":[{name:'Diesel'},{name:'Petrol'},{name:'Unknown'},{name:'Plug-in Hybrid Electric Vehicle'}]
    },
    {
        "vehicle_category": "Cars (by market segment)",
        "vehicle_type": "Mini",
        "fuel_type":[{name:'Diesel'},{name:'Petrol'},{name:'Unknown'},{name:'Plug-in Hybrid Electric Vehicle'}]

    },
    {
        "vehicle_category": "Cars (by market segment)",
        "vehicle_type": "Supermini",
        "fuel_type":[{name:'Diesel'},{name:'Petrol'},{name:'Unknown'},{name:'Plug-in Hybrid Electric Vehicle'}]

    },
    {
        "vehicle_category": "Cars (by market segment)",
        "vehicle_type": "Supermini",
        "fuel_type":[{name:'Diesel'},{name:'Petrol'},{name:'Unknown'},{name:'Plug-in Hybrid Electric Vehicle'}]

    },
    {
        "vehicle_category": "Cars (by market segment)",
        "vehicle_type": "Lower medium",
        "fuel_type":[{name:'Diesel'},{name:'Petrol'},{name:'Unknown'},{name:'Plug-in Hybrid Electric Vehicle'}]

    },
    {
        "vehicle_category": "Cars (by market segment)",
        "vehicle_type": "Lower medium",
        "fuel_type":[{name:'Diesel'},{name:'Petrol'},{name:'Unknown'},{name:'Plug-in Hybrid Electric Vehicle'}]

    },
    {
        "vehicle_category": "Cars (by market segment)",
        "vehicle_type": "Upper medium",
        "fuel_type":[{name:'Diesel'},{name:'Petrol'},{name:'Unknown'},{name:'Plug-in Hybrid Electric Vehicle'}]

    },
    {
        "vehicle_category": "Cars (by market segment)",
        "vehicle_type": "Upper medium",
        "fuel_type":[{name:'Diesel'},{name:'Petrol'},{name:'Unknown'},{name:'Plug-in Hybrid Electric Vehicle'}]

    },
    {
        "vehicle_category": "Cars (by market segment)",
        "vehicle_type": "Executive",
        "fuel_type":[{name:'Diesel'},{name:'Petrol'},{name:'Unknown'},{name:'Plug-in Hybrid Electric Vehicle'}]

    },
    {
        "vehicle_category": "Cars (by market segment)",
        "vehicle_type": "Executive"
    },
    {
        "vehicle_category": "Cars (by market segment)",
        "vehicle_type": "Luxury",
        "fuel_type":[{name:'Diesel'},{name:'Petrol'},{name:'Unknown'},{name:'Plug-in Hybrid Electric Vehicle'}]

    },
    {
        "vehicle_category": "Cars (by market segment)",
        "vehicle_type": "Luxury",
        "fuel_type":[{name:'Diesel'},{name:'Petrol'},{name:'Unknown'},{name:'Plug-in Hybrid Electric Vehicle'}]

    },
    {
        "vehicle_category": "Cars (by market segment)",
        "vehicle_type": "Sports"
    },
    {
        "vehicle_category": "Cars (by market segment)",
        "vehicle_type": "Sports",
        "fuel_type":[{name:'Diesel'},{name:'Petrol'},{name:'Unknown'},{name:'Plug-in Hybrid Electric Vehicle'}]

    },
    {
        "vehicle_category": "Cars (by market segment)",
        "vehicle_type": "Dual purpose 4X4",
        "fuel_type":[{name:'Diesel'},{name:'Petrol'},{name:'Unknown'},{name:'Plug-in Hybrid Electric Vehicle'}]

    },
    {
        "vehicle_category": "Cars (by market segment)",
        "vehicle_type": "Dual purpose 4X4",
        "fuel_type":[{name:'Diesel'},{name:'Petrol'},{name:'Unknown'},{name:'Plug-in Hybrid Electric Vehicle'}]

    },
    {
        "vehicle_category": "Cars (by market segment)",
        "vehicle_type": "MPV",
        "fuel_type":[{name:'Diesel'},{name:'Petrol'},{name:'Unknown'},{name:'Plug-in Hybrid Electric Vehicle'}]

    },
    {
        "vehicle_category": "Cars (by market segment)",
        "vehicle_type": "MPV",
        "fuel_type":[{name:'Diesel'},{name:'Petrol'},{name:'Unknown'},{name:'Plug-in Hybrid Electric Vehicle'}]

    },
    {
        "vehicle_category": "Cars (by size)",
        "vehicle_type": "Small car",
        "fuel_type":[{name:'Diesel'},{name:'Petrol'},{name:'Hybrid'},{name:'CNG'},{name:'LPG'},{name:'Unkown'},{name:'Plug-in Hybrid Electric Vehicle'}]
       

    },
    {
        "vehicle_category": "Cars (by size)",
        "vehicle_type": "Small car",
        "fuel_type":[{name:'Diesel'},{name:'Petrol'},{name:'Hybrid'},{name:'CNG'},{name:'LPG'},{name:'Unkown'},{name:'Plug-in Hybrid Electric Vehicle'}]
      

    },
    {
        "vehicle_category": "Cars (by size)",
        "vehicle_type": "Medium car",
        "fuel_type":[{name:'Diesel'},{name:'Petrol'},{name:'Hybrid'},{name:'CNG'},{name:'LPG'},{name:'Unkown'},{name:'Plug-in Hybrid Electric Vehicle'}]
      

    },
    {
        "vehicle_category": "Cars (by size)",
        "vehicle_type": "Medium car",
        "fuel_type":[{name:'Diesel'},{name:'Petrol'},{name:'Hybrid'},{name:'CNG'},{name:'LPG'},{name:'Unkown'},{name:'Plug-in Hybrid Electric Vehicle'}]
       

    },
    {
        "vehicle_category": "Cars (by size)",
        "vehicle_type": "Large car",
        "fuel_type":[{name:'Diesel'},{name:'Petrol'},{name:'Hybrid'},{name:'CNG'},{name:'LPG'},{name:'Unkown'},{name:'Plug-in Hybrid Electric Vehicle'}]
       

    },
    {
        "vehicle_category": "Cars (by size)",
        "vehicle_type": "Large car",
        "fuel_type":[{name:'Diesel'},{name:'Petrol'},{name:'Hybrid'},{name:'CNG'},{name:'LPG'},{name:'Unkown'},{name:'Plug-in Hybrid Electric Vehicle'}]
       

    },
    {
        "vehicle_category": "Cars (by size)",
        "vehicle_type": "Average car",
        "fuel_type":[{name:'Diesel'},{name:'Petrol'},{name:'Hybrid'},{name:'CNG'},{name:'LPG'},{name:'Unkown'},{name:'Plug-in Hybrid Electric Vehicle'}]
      

    },
    {
        "vehicle_category": "Cars (by size)",
        "vehicle_type": "Average car",
        "fuel_type":[{name:'Diesel'},{name:'Petrol'},{name:'Hybrid'},{name:'CNG'},{name:'LPG'},{name:'Unkown'},{name:'Plug-in Hybrid Electric Vehicle'}]

    },
    {
        "vehicle_category": "Motorbike",
        "vehicle_type": "Small",
        "fuel_type":[{name:'Petrol'}]
    },
    {
        "vehicle_category": "Motorbike",
        "vehicle_type": "Small",
        "fuel_type":[{name:'Petrol'}]
    },
    {
        "vehicle_category": "Motorbike",
        "vehicle_type": "Medium",
        "fuel_type":[{name:'Petrol'}]
    },
    {
        "vehicle_category": "Motorbike",
        "vehicle_type": "Medium",
        "fuel_type":[{name:'Petrol'}]
    },
    {
        "vehicle_category": "Motorbike",
        "vehicle_type": "Large",
        "fuel_type":[{name:'Petrol'}]
    },
    {
        "vehicle_category": "Motorbike",
        "vehicle_type": "Large",
        "fuel_type":[{name:'Petrol'}]
    },
    {
        "vehicle_category": "Motorbike",
        "vehicle_type": "Average",
        "fuel_type":[{name:'Petrol'}]
    },
    {
        "vehicle_category": "Motorbike",
        "vehicle_type": "Average",
        "fuel_type":[{name:'Petrol'}]
    },
    {
        "vehicle_category": "Vans",
        "vehicle_type": "Class I (up to 1.305 tonnes)",
        "fuel_type":[{name:'Diesel'},{name:'Petrol'},{name:'CNG'},{name:'LPG'}]

    },
    {
        "vehicle_category": "Vans",
        "vehicle_type": "Class I (up to 1.305 tonnes)",
        "fuel_type":[{name:'Diesel'},{name:'Petrol'},{name:'CNG'},{name:'LPG'}]
    },
    {
        "vehicle_category": "Vans",
        "vehicle_type": "Class II (1.305 to 1.74 tonnes)",
        "fuel_type":[{name:'Diesel'},{name:'Petrol'},{name:'CNG'},{name:'LPG'}]
    },
    {
        "vehicle_category": "Vans",
        "vehicle_type": "Class II (1.305 to 1.74 tonnes)",
        "fuel_type":[{name:'Diesel'},{name:'Petrol'},{name:'CNG'},{name:'LPG'}]
    },
    {
        "vehicle_category": "Vans",
        "vehicle_type": "Class III (1.74 to 3.5 tonnes)",
        "fuel_type":[{name:'Diesel'},{name:'Petrol'},{name:'CNG'},{name:'LPG'}]
    },
    {
        "vehicle_category": "Vans",
        "vehicle_type": "Class III (1.74 to 3.5 tonnes)",
        "fuel_type":[{name:'Diesel'},{name:'Petrol'},{name:'CNG'},{name:'LPG'}]
    },
    {
        "vehicle_category": "Vans",
        "vehicle_type": "Average (up to 3.5 tonnes)",
        "fuel_type":[{name:'Diesel'},{name:'Petrol'},{name:'CNG'},{name:'LPG'}]
    },
    {
        "vehicle_category": "Vans",
        "vehicle_type": "Average (up to 3.5 tonnes)",
        "fuel_type":[{name:'Diesel'},{name:'Petrol'},{name:'CNG'},{name:'LPG'}]
    },
    {
        "vehicle_category": "HGV (all diesel)",
        "vehicle_type": "Rigid (>3.5 - 7.5 tonnes)",
        "fuel_type":[{name:'Diesel'}]
    },
    {
        "vehicle_category": "HGV (all diesel)",
        "vehicle_type": "Rigid (>3.5 - 7.5 tonnes)",
        "fuel_type":[{name:'Diesel'}]
    },
    {
        "vehicle_category": "HGV (all diesel)",
        "vehicle_type": "Rigid (>7.5 tonnes-17 tonnes)",
        "fuel_type":[{name:'Diesel'}]
    },
    {
        "vehicle_category": "HGV (all diesel)",
        "vehicle_type": "Rigid (>7.5 tonnes-17 tonnes)",
        "fuel_type":[{name:'Diesel'}]
    },
    {
        "vehicle_category": "HGV (all diesel)",
        "vehicle_type": "Rigid (>17 tonnes)",
        "fuel_type":[{name:'Diesel'}]
    },
    {
        "vehicle_category": "HGV (all diesel)",
        "vehicle_type": "Rigid (>17 tonnes)",
        "fuel_type":[{name:'Diesel'}]
    },
    {
        "vehicle_category": "HGV (all diesel)",
        "vehicle_type": "All rigids",
        "fuel_type":[{name:'Diesel'}]
    },
    {
        "vehicle_category": "HGV (all diesel)",
        "vehicle_type": "All rigids",
        "fuel_type":[{name:'Diesel'}]
    },
    {
        "vehicle_category": "HGV (all diesel)",
        "vehicle_type": "Articulated (>3.5 - 33t)",
        "fuel_type":[{name:'Diesel'}]
    },
    {
        "vehicle_category": "HGV (all diesel)",
        "vehicle_type": "Articulated (>3.5 - 33t)",
        "fuel_type":[{name:'Diesel'}]
    },
    {
        "vehicle_category": "HGV (all diesel)",
        "vehicle_type": "Articulated (>33t)",
        "fuel_type":[{name:'Diesel'}]
    },
    {
        "vehicle_category": "HGV (all diesel)",
        "vehicle_type": "Articulated (>33t)",
        "fuel_type":[{name:'Diesel'}]
    },
    {
        "vehicle_category": "HGV (all diesel)",
        "vehicle_type": "All artics",
        "fuel_type":[{name:'Diesel'}]
    },
    {
        "vehicle_category": "HGV (all diesel)",
        "vehicle_type": "All artics",
        "fuel_type":[{name:'Diesel'}]
    },
    {
        "vehicle_category": "HGV (all diesel)",
        "vehicle_type": "All HGVs",
        "fuel_type":[{name:'Diesel'}]
    },
    {
        "vehicle_category": "HGV (all diesel)",
        "vehicle_type": "All HGVs",
        "fuel_type":[{name:'Diesel'}]
    },
    {
        "vehicle_category": "HGVs refrigerated (all diesel)",
        "vehicle_type": "Rigid (>3.5 - 7.5 tonnes)",
        "fuel_type":[{name:'Diesel'}]
    },
    {
        "vehicle_category": "HGVs refrigerated (all diesel)",
        "vehicle_type": "Rigid (>3.5 - 7.5 tonnes)",
        "fuel_type":[{name:'Diesel'}]
    },
    {
        "vehicle_category": "HGVs refrigerated (all diesel)",
        "vehicle_type": "Rigid (>7.5 tonnes-17 tonnes)",
        "fuel_type":[{name:'Diesel'}]
    },
    {
        "vehicle_category": "HGVs refrigerated (all diesel)",
        "vehicle_type": "Rigid (>7.5 tonnes-17 tonnes)",
        "fuel_type":[{name:'Diesel'}]
    },
    {
        "vehicle_category": "HGVs refrigerated (all diesel)",
        "vehicle_type": "Rigid (>17 tonnes)",
        "fuel_type":[{name:'Diesel'}]
    },
    {
        "vehicle_category": "HGVs refrigerated (all diesel)",
        "vehicle_type": "Rigid (>17 tonnes)",
        "fuel_type":[{name:'Diesel'}]
    },
    {
        "vehicle_category": "HGVs refrigerated (all diesel)",
        "vehicle_type": "All rigids",
        "fuel_type":[{name:'Diesel'}]
    },
    {
        "vehicle_category": "HGVs refrigerated (all diesel)",
        "vehicle_type": "All rigids",
        "fuel_type":[{name:'Diesel'}]
    },
    {
        "vehicle_category": "HGVs refrigerated (all diesel)",
        "vehicle_type": "Articulated (>3.5 - 33t)",
        "fuel_type":[{name:'Diesel'}]
    },
    {
        "vehicle_category": "HGVs refrigerated (all diesel)",
        "vehicle_type": "Articulated (>3.5 - 33t)",
        "fuel_type":[{name:'Diesel'}]
    },
    {
        "vehicle_category": "HGVs refrigerated (all diesel)",
        "vehicle_type": "Articulated (>33t)",
        "fuel_type":[{name:'Diesel'}]
    },
    {
        "vehicle_category": "HGVs refrigerated (all diesel)",
        "vehicle_type": "Articulated (>33t)",
        "fuel_type":[{name:'Diesel'}]
    },
    {
        "vehicle_category": "HGVs refrigerated (all diesel)",
        "vehicle_type": "All artics",
        "fuel_type":[{name:'Diesel'}]
    },
    {
        "vehicle_category": "HGVs refrigerated (all diesel)",
        "vehicle_type": "All artics",
        "fuel_type":[{name:'Diesel'}]
    },
    {
        "vehicle_category": "HGVs refrigerated (all diesel)",
        "vehicle_type": "All HGVs",
        "fuel_type":[{name:'Diesel'}]
    },
    {
        "vehicle_category": "HGVs refrigerated (all diesel)",
        "vehicle_type": "All HGVs",
        "fuel_type":[{name:'Diesel'}]
    }
] 