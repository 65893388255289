import React, { useEffect, useState } from "react";
import { useLocation, useHistory, useParams } from "react-router-dom/cjs/react-router-dom";
import { motion } from "framer-motion";
import useForceUpdate from "use-force-update";
import LazyView from "../../components/LazyView";
import { DateTime } from "luxon";
import moment from "moment";
import { API } from "../../constants/api_url";
import APIServices from "../../service/APIService";
import { useSelector } from "react-redux";
import { Dropdown } from "primereact/dropdown";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { RadioButton } from "primereact/radiobutton";
import { Calendar } from "primereact/calendar";
import { Editor } from "primereact/editor";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import Swal from "sweetalert2";
import { BGSQ14, BGSQ15, BGSQ21, BGSQ24, BP1EQ2, BP4EQ2, BP7EQ1B, BP7EQ2, BP7LQ1, BP8EQ1, BP8LQ1, BP8LQ2, BP8LQ4, BP8LQ5, BP8LQ6, BP9LQ1, BP2LQ1, BP2LQ2, BP6EQ10, BP6EQ11, BP6EQ12, BP6LQ6, SGXGSQ7, SGXGSQ6, SGXGSQ4 } from "../../components/hardcoded/hardcodedRF";
import { hardcoded } from "../../components/hardcoded/hardcodedid";
import { AttachmentComponent } from "../../components/Forms/Attachment";
import { getRPLuxon, getRPTextFormat } from "../../components/BGHF/helper";

const DFInputEntrySubmission = () => {
    const login_data = useSelector((state) => state.user.userdetail);
    const admin_data = useSelector((state) => state.user.admindetail);
    const userList = useSelector(state => state.userlist.userList)

    const [data, setData] = useState([])
    const [sitelist, setSiteList] = useState([])
    const navigate = useHistory()
    const forceUpdate = useForceUpdate();
    const [status,setStatus] = useState(true)
    const [response, setResponse] = useState({})
    const { id, params, submitId } = JSON.parse(sessionStorage.getItem('dfpreview'))

    // const hardcodedrf = ['85', '89', '102', '104', '110', '111', '112', '113', '114', '115', '116', '117', '118', '121', '133', '134', '139', '140','148','149','150','151','181','182','183']
    const [show, setShow] = useState(true)
    useEffect(async () => {
        console.log(params)
        setStatus(params.state.status)
        APIServices.get(API.RF_Edit(id)).then((res) => {
            if (submitId) {
                APIServices.get(API.QL_Submission_Edit(submitId)).then((res2) => {

                    setResponse(res2.data)
                    if (hardcoded.rf.includes(id)) {
                        res.data.data1 = res2.data.response
                    } else {
                        res.data.data1 = migrateResponse(res.data.data1, res2.data.response)
                    }


                    setData(res.data)

                })
            } else {
                APIServices.post(API.QL_Recent_RP_Data, { dfId: id, userProfileId: admin_data.id, categoryId: params.state.data.df.cat_id, topicId: params.state.data.df.top_id, indicatorId: params.state.data.df.id, reporting_period: params.state.data.reporting_period, locationId: params.state.data.locationId, level: params.state.data.level }).then((res3) => {
                    if (res3.data.result) {
                        if (hardcoded.rf.includes(id)) {
                            res.data.data1 = res3.data.data.response
                        } else {
                            res.data.data1 = migrateResponse(res.data.data1, res3.data.data.response)
                        }
                        setData(res.data)
                    } else {
                        setData(res.data)
                    }
                })

            }

        })


    }, [login_data]);
    const getDisplayStatus = (rp) => {
        console.log(rp)
        const [startMonth, endMonth] = rp.split(' to ');

        const month = endMonth ? endMonth : startMonth;
        const [monthValue, year] = month.split('-');
        const endOfMonth = DateTime.fromObject({ year: parseInt(year), month: DateTime.fromFormat(monthValue, 'LLL').month }).endOf('month');
        const currentDate = DateTime.local();
        console.log(month, endOfMonth.diff(currentDate, 'days').days)
        return endOfMonth.diff(currentDate, 'days').days <= 0;

    }

    const checkHardcoded = () => {
        console.log(id)
        if (hardcoded.rf.includes(id)) {
            return true
        } else {
            return false
        }

    }
    const migrateResponse = (formData, oldData) => {

        formData.forEach((i) => {
            let index = oldData.findIndex((j) => { return getType(j.type) === i.type && i.name === j.name })
            if (index !== -1) {
                if (i.type !== 'checkbox-group' && i.type !== 'radio-group') {

                    i.value = oldData[index].value
                } else {

                    i.values.forEach((k, l) => {
                        k.selected = oldData[index].value.includes(k.label) || oldData[index].value.includes(l)
                    })
                }
            }
        })

        return formData
    }
    const onCheckBoxSelected = (item, cbind) => {
        console.log(item)
        item.values.map((items, ind) => {
            if (ind === cbind) {

                items.selected = !items.selected
            }
        })
        forceUpdate()
    }
    const onRadioButtonSelected = (item, cbind) => {
        console.log(item)
        item.values.map((items, ind) => {
            if (ind === cbind) {

                items.selected = true
            } else {
                items.selected = false
            }
        })
        forceUpdate()
    }
    const onDateSelected = (item, val) => {

        item.value = val;
        forceUpdate()
    }
    const onNumberChange = (item, val, nan) => {
        console.log(item, val)

        item.value = val;


        forceUpdate()
    }

    const onChangeDropwdown = (item, val) => {
        item.value = val;
        console.log(val)
        item.values.forEach((i) => {
            if (i.label === val) {
                i.selected = true
            } else {
                i.selected = false
            }
        })
        forceUpdate()
    }
    const renderItems = (item, index) => {


        if (item.type === 'checkbox-group') {

            return (
                <div className="flex flex-wrap  gap-3  grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-5 text-justify fs-16 fw-5'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.required && <span className="mandatory mr-2">*</span>}
                        {item.description !== undefined && item.description.trim().length !== 0 && <i style={{

                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={`${item.description}`}


                        > help</i>} </label>
                    <div className="col-5">
                        {item.values.map((cb, cbind) => {
                            return (
                                <div className="flex text-justify fs-14 fw-5" style={{ marginBottom: 10 }}>
                                    <Checkbox disabled={submitId ? response.type !== 0 : status ? false : true} inputId={"cb" + index + cbind} name={cb.label} value={cb.value} onChange={(e) => { onCheckBoxSelected(item, cbind) }} checked={cb.selected} />
                                    <label htmlFor={"cb" + index + cbind} className="ml-2">{cb.label}</label>
                                </div>
                            )
                        })

                        }
                    </div>

                </div>
            )
        } else if (item.type === 'date') {

            return (
                <div className="flex flex-wrap  gap-3  grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-5 fs-16 fw-5 text-justify'> {item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.required && <span className="mandatory mr-2">*</span>}
                        {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{

                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={`${item.description}`}


                        > help</i></span>} </label>
                    <Calendar disabled={submitId ? response.type !== 0 : status ? false : true} placeholder={item.placeholder} className="col-5 fs-14 fw-4" value={(item.value !== null && item.value !== undefined) ? moment(item.value).toDate() : null} onChange={(e) => { onDateSelected(item, e.value) }} />
                </div>
            )
        } else if (item.type === 'number') {
            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-5 fs-16 fw-5 text-justify'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.required && <span className="mandatory mr-2">*</span>}  {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{

                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={`${item.description}`}


                        > help</i></span>} </label>
                    <div className="col-5 fs-14 fw-4" >
                        <InputNumber disabled={submitId ? response.type !== 0 : status ? false : true} min={0} placeholder={item.placeholder} maxFractionDigits={(item.fraction === undefined || item.fraction) ? 0 : item.fraction < 0 ? 0 : item.fraction} onWheel={(e) => e.target.blur()} keyfilter="num" style={{ width: '100%' }} value={item.value} onChange={(e) => { onNumberChange(item, e.value) }} />

                    </div>
                </div>
            )
        } else if (item.type === 'paragraph') {
            return (
                <div className="flex flex-wrap  gap-3 fs-16 fw-5 text-justify justify-content-center" style={{ padding: 10 }}>

                    <label >{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.required && <span className="mandatory mr-2">*</span>} </label>

                </div>
            )
        } else if (item.type === 'radio-group') {
            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-5 fs-16 fw-5 text-justify'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.required && <span className="mandatory mr-2">*</span>}  {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{

                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={`${item.description}`}


                        > help</i></span>} </label>
                    <div className="col-5 grid" style={{ padding: 10 }} >
                        {item.values.map((cb, cbind) => {
                            return (
                                <div className="p-2 flex text-justify fs-14 fw-5 align-items-center" >
                                    <RadioButton disabled={submitId ? response.type !== 0 : status ? false : true} inputId={"rg" + index + cbind} name={cb.label} value={cb.value} onChange={(e) => onRadioButtonSelected(item, cbind)} checked={cb.selected === true} />

                                    <label htmlFor={"rg" + index + cbind} className="ml-2">{cb.label}</label>
                                </div>
                            )
                        })

                        }
                    </div>

                </div>
            )
        } else if (item.type === 'select') {
            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-5 fw-5 fs-16 text-justify'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.required && <span className="mandatory mr-2">*</span>}  {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={`${item.description}`}


                        > help</i></span>} </label>


                    <div className="col-5 fw-4 fs-14">
                        <Dropdown disabled={submitId ? response.type !== 0 : status ? false : true} placeholder={item.placeholder} options={item.values} style={{ width: '100%' }} optionLabel='label' optionValue="value" value={item.value} onChange={(e) => { onChangeDropwdown(item, e.value) }} />
                    </div>

                </div>
            )
        } else if (item.type === 'text') {
            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-5 fs-16 fw-5 text-justify'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.required && <span className="mandatory mr-2">*</span>}  {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={`${item.description}`}


                        > help</i></span>} </label>
                    <div className="col-5 fs-14 fw-4" >
                        <InputText disabled={submitId ? response.type !== 0 : status ? false : true} style={{ width: '100%' }} value={item.value} placeholder={item.placeholder} onChange={(e) => { onNumberChange(item, e.target.value) }} />

                    </div>
                </div>
            )
        } else if (item.type === 'textarea') {
            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-5 fs-16 fw-5 text-justify'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.required && <span className="mandatory mr-2">*</span>}  {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={`${item.description}`}

                        > help</i></span>} </label>
                    <div className="col-5 " >
                        <Editor disabled={submitId ? response.type !== 0 : status ? false : true} className="text-area col-5" value={item.value} style={{ width: '100%', padding: 10, maxHeight: 350, height: 158, overflow: 'scroll' }} onTextChange={(e) => onNumberChange(item, e.htmlValue)} />

                    </div>

                </div>
            )
        } else if (item.type === 'file') {
            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ marginBottom: 15, padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} />
                    <label style={{ display: 'flex' }} className='col-5 fw-5 fs-16 text-justify'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.required && <span className="mandatory mr-2">*</span>}  {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={`${item.description}`}
                            data-pr-position="right"
                            data-pr-at="right+5 top"
                            data-pr-my="left center-2"> help</i></span>} </label>
                    <div className="col-5" >
                        {(!submitId || (submitId && response.type === 0)) && <div style={{
                            background: '#f8f9fa',
                            border: '1px solid #ced4da',
                            borderRadius: '6px 6px 0px 0px',
                            padding: '8px'
                        }}>
                            <label htmlFor={'fp' + index} className="fs-14 clr-navy" style={{
                                marginRight: 10,
                                padding: '5px',

                                background: 'white',
                                border: '1px solid cornflowerblue',
                                borderRadius: '10px',

                            }} >
                                <i style={{ fontSize: 15, margin: 5 }} className="pi pi-folder-open clr-navy" />
                                Add Attachment
                            </label>
                            <label
                                onClick={() => { resetFiles(item, index) }}
                                style={{
                                    padding: '5px',
                                    fontSize: '15px',
                                    border: '1px solid indianred',
                                    background: 'white',
                                    borderRadius: '10px',
                                    color: 'indianred'
                                }} >
                                <i style={{ fontSize: 15, margin: 5 }} className="pi pi-undo" />
                                Reset
                            </label>
                            <input type='file' accept=".jpg,.JPG,.jpeg,.JPEG,.png,.PNG,.pdf,.PDF,.xls,.xlsx,.ppt,.doc,.docx,.pptx" id={'fp' + index} hidden onChange={(e) => { handleFileUpload(e, item) }} ></input>
                        </div>}
                        {item.value !== undefined && item.value.length !== 0 &&
                            <div className="col-12" style={{
                                maxHeight: 300,
                                overflow: 'auto',
                                border: '1px solid #ced4da'
                            }} >
                                <div style={{
                                    border: '1px solid #6366F170',
                                    borderRadius: '10px'
                                }}>
                                    {item.value.map((file, findex) => {

                                        return (
                                            <>
                                                <div style={{
                                                    display: 'flex',
                                                    alignItems: 'center', margin: 5
                                                }} >
                                                    <div className="flex align-items-center " style={{ width: '60%' }}>
                                                        {(file.extension === '.pdf' || file.extension === '.PDF') ?
                                                            <div>
                                                                <iframe src={API.Docs + file.originalname} /> </div> :
                                                            <img alt={file.originalname} role="presentation" src={API.Docs + file.originalname} width={100} style={{ borderRadius: 10 }} />}
                                                        <span className="flex flex-column text-left ml-3">
                                                            {file.originalname}
                                                            <small>{new Date().toLocaleDateString()}</small>
                                                        </span>
                                                    </div>
                                                    <Tag value={'View'} onClick={() => { window.open(API.Docs + file.originalname) }} style={{ width: '20%' }} severity="warning" className="px-3 py-2" />
                                                    {(!submitId || (submitId && response.type === 0)) && <Button type="button" icon="pi pi-times" style={{ marginRight: 10 }} className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => removeImage(index, findex)} />}
                                                </div>

                                            </>
                                        )
                                    })

                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>
            )
        }

    }
    const handleFileUpload = (e, item) => {
        let ext = e.target.files[0].name.substr(e.target.files[0].name.lastIndexOf('.'))
        let allowedext = ['.jpg', '.JPG', '.jpeg', '.JPEG', '.png', '.PNG', '.pdf', '.PDF']
        if (allowedext.includes(ext)) {
            let formData = new FormData()
            formData.append('file', e.target.files[0])
            APIServices.post(API.FilesUpload, formData, {
                headers: {
                    'content-type': 'multipart/form-data'

                }
            }).then((res) => {
                res.data.files[0].extension = ext
                if (item.value === undefined) {
                    item['value'] = [res.data.files[0]]
                } else {
                    if (item.multiple) {
                        item['value'].push(res.data.files[0])
                    } else {
                        item['value'] = [res.data.files[0]]
                    }

                }
                forceUpdate()

            })
        } else {
            Swal.fire({
                position: "center",
                icon: "warning",
                title: "invalid file format, supported format JPEG,PNG & PDF only",
                showConfirmButton: false,
                timer: 2000,
            });
        }
    }
    const removeImage = (index, findex) => {

        data.data1[index].value.splice(findex, 1)
        forceUpdate()

    }
    const resetFiles = (item, index) => {

        item.value = []
        forceUpdate()

    }
    const checkResponse = () => {
        let result = 0
        let total = data.data1.filter((i) => { return i.required === true }).length

        data.data1.forEach((item) => {


            if (item.type === 'checkbox-group' && item.required === true) {


                if (item.values.filter((i) => { return i.selected }).length !== 0) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            } else if (item.type === 'date' && item.required) {

                if (item.value !== undefined && item.value !== null) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            }
            else if (item.type === 'number' && item.required) {

                if (item.value !== undefined && item.value !== null && parseFloat(item.value.toString()) >= 0) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            } else if (item.type === 'number' && item.required === false) {
                if (item.value !== undefined && item.value !== null) {
                    if (item.value === null || isNaN(item.value)) {
                        result = result + 1
                        item.error = 1
                    } else if (parseFloat(item.value.toString()) < 0) {
                        result = result + 1
                        item.error = 1
                    } else if (parseFloat(item.value.toString()) >= 0) {
                        item.error = 0
                    }
                }
            } else if (item.type === 'radio-group' && item.required) {

                if (item.values.filter((i) => { return i.selected }).length === 1) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            }
            else if (item.type === 'select' && item.required) {

                if (item.values.filter((i) => { return i.selected }).length === 1) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            }
            else if (item.type === 'text' && item.required) {

                if (item.value !== undefined && item.value !== null && item.value.trim().length !== 0) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            }
            else if (item.type === 'textarea' && item.required) {

                if (item.value !== undefined && item.value !== null && item.value.trim().length !== 0) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            }
            else if (item.type === 'file' && item.required) {
                if (item.value !== undefined && item.value !== null && item.value.length !== 0) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            }


        })

        return result === total
    }
    const makeEmpty = () => {
        let result = 0
        let data_ = JSON.parse(JSON.stringify(data.data1))

        data_.forEach((item) => {


            if (item.type === 'checkbox-group') {
                item.values.forEach((i) => {
                    i.selected = false
                })


            } else if (item.type === 'date') {

                item.value = null
            }
            else if (item.type === 'number') {

                item.result = 0
            }

            else if (item.type === 'radio-group' && item.required) {
                item.values.forEach((i) => {
                    i.selected = false
                })

            }
            else if (item.type === 'select') {

                item.values.forEach((i) => {
                    i.selected = false
                })
            }
            else if (item.type === 'text') {

                item.value = 0
            }
            else if (item.type === 'textarea') {

                item.value = 0
            } else if (item.type === 'file') {
                item['value'] = []
            }


        })


        return data_
    }


    const checkResponse_ = () => {
        console.log(data)
        if (data.data1.length === 0) {
            Swal.fire({
                position: "center",
                icon: "warning",
                title: "Data set is Empty, requires minimum 1 record to submit/draft",
                showConfirmButton: false,
                timer: 1500,
            });
            return false
        } else {

            return true
        }
    }

    const getType = (item) => {
        if (item === 1) {
            return 'text'
        } else if (item === 2) {
            return 'textarea'
        } else if (item === 3) {
            return 'select'
        } else if (item === 4) {
            return 'checkbox-group'
        } else if (item === 5) {
            return 'number'
        } else if (item === 6) {
            return 'radio-group'
        } else if (item === 7) {
            return 'file'
        } else if (item === 'paragraph') {
            return 'paragraph'
        } else if (item === 9) {
            return 'date'
        }
    }
    const reduceResponse = (item) => {
        if (item.type === 'text') {
            return { type: 1, value: item.value === undefined ? '' : item.value, name: item.name }
        } else if (item.type === 'textarea') {

            return { type: 2, value: item.value === undefined ? '<p></p>' : item.value, name: item.name }
        } else if (item.type === 'select') {

            return { type: 3, value: item.value === undefined ? null : item.value, name: item.name }
        } else if (item.type === 'checkbox-group') {
            return { type: 4, value: item.values.map((k, i) => { if (k.selected === true) { return k.value } }).filter((i) => { return i !== undefined }), name: item.name }
        } else if (item.type === 'number') {
            return { type: 5, value: item.value, name: item.name }
        } else if (item.type === 'radio-group') {
            return { type: 6, value: item.values.map((k, i) => { if (k.selected === true) { return k.value } }).filter((i) => { return i !== undefined }), name: item.name }
        } else if (item.type === 'file') {
            return { type: 7, value: item.value, name: item.name }
        } else if (item.type === 'paragraph') {
            return item
        } if (item.type === 'date') {
            return { type: 9, value: item.value, name: item.name }
        }
    }
    const saveRF = async () => {
        let newObj = {}
        console.log(checkResponse())
        if (checkResponse()) {
            if (!submitId) {
                newObj['dfId'] = parseInt(id)
                newObj['response'] = data.data1.map((i) => reduceResponse(i))
                newObj['type'] = 1
                newObj['edit'] = 0
                newObj['categoryId'] = params.state.data.cat_id
                newObj['indicatorId'] = params.state.data.id
                newObj['topicId'] = params.state.data.top_id
                newObj['implevel'] = 0
                newObj['reject'] = 0

                newObj['reviewer_modified_on'] = DateTime.utc()
                newObj['reviewer_modified_by'] = login_data.id
                newObj['reporter_modified_on'] = DateTime.utc()
                newObj['reporter_modified_by'] = login_data.id
                newObj['last_modified_on'] = DateTime.utc()
                newObj['last_modified_by'] = login_data.id
                newObj['submitted_by'] = login_data.id
                newObj['submitted_on'] = DateTime.utc()
                newObj['self'] = true
                newObj['response_type'] = 1
                newObj['reporting_period'] = getRPLuxon(params.state.data.reporting_period)
                newObj['reviewed_on'] = DateTime.utc()
                newObj['reviewed_by'] = login_data.id
                newObj['user_type'] = login_data.role === 'clientuser' ? 1 : login_data.role === 'clientadmin' ? 0 : 2
                newObj['form_type'] = 1
                let { value: return_remarks, isConfirmed } = await Swal.fire({
                    html: `<label class="fs-16 fw-5">Remarks for submission</label>`,
                    input: 'textarea',

                    inputValue: '',

                    allowOutsideClick: false,
                    showCancelButton: true
                })



                if (isConfirmed) {
                    if (typeof return_remarks === 'string' && return_remarks.trim()) {
                        newObj['return_remarks'] = [{ remarks: return_remarks, user_type: 1, type: 1, user_id: login_data.id, created_on: DateTime.utc() }]

                    }
                    APIServices.post(API.QL_Submit_UP(admin_data.id), newObj).then((res) => {
                        Swal.fire({
                            title: "Form Submitted Successfully",

                            confirmButtonText: 'Exit',
                            allowOutsideClick: false,
                        }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (result.isConfirmed) {
                                window.close()
                            }
                        })
                    }).catch((e) => {
                        Swal.fire({
                            title: "Something went wrong, try after some time. Contact admin if issue still persist",

                            confirmButtonText: 'Exit',
                            allowOutsideClick: false,
                        }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (result.isConfirmed) {
                                window.close()
                            }
                        })

                    })
                }
            } else {
                newObj['last_modified_on'] = DateTime.utc()
                newObj['last_modified_by'] = login_data.id
                newObj['submitted_by'] = login_data.id
                newObj['submitted_on'] = DateTime.utc()
                newObj['reporter_modified_on'] = DateTime.utc()
                newObj['reporter_modified_by'] = login_data.id
                newObj['reject'] = 0
                newObj['type'] = 1
                let { value: return_remarks, isConfirmed } = await Swal.fire({
                    html: `<label class="fs-16 fw-5">Please Enter Remarks If Any</label>`,
                    input: 'textarea',

                    inputValue: '',

                    allowOutsideClick: false,
                    showCancelButton: true,
                    inputValidator: (value) => {
                        if ((response.return_remarks !== null && response.return_remarks.filter(i => (i.user_type === 2 || i.user_type === 3)).length !== 0) ? !value.trim() : false) {
                            return 'Enter Remarks'
                        }
                    }
                })

                if (isConfirmed) {


                    if (return_remarks) {
                        if (response.return_remarks === null) {
                            newObj['return_remarks'] = [{ remarks: return_remarks, user_type: 1, type: 1, user_id: login_data.id, created_on: DateTime.utc() }]

                        } else {
                            let lt = response.return_remarks
                            lt.push({ remarks: return_remarks, user_type: 1, type: 1, user_id: login_data.id, created_on: DateTime.utc() })
                            newObj['return_remarks'] = lt
                        }


                    }
                    APIServices.patch(API.QL_Submission_Edit(submitId), newObj).then((res) => {
                        Swal.fire({
                            title: "Form Submitted Successfully",

                            confirmButtonText: 'Exit',
                            allowOutsideClick: false,
                        }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (result.isConfirmed) {
                                window.close()
                            }
                        })
                    }).catch((e) => {
                        Swal.fire({
                            title: "Something went wrong, try after some time. Contact admin if issue still persist",

                            confirmButtonText: 'Exit',
                            allowOutsideClick: false,
                        }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (result.isConfirmed) {
                                window.close()
                            }
                        })

                    })
                }

            }




        }
        forceUpdate()

    }
    const saveAsDraft = () => {
        let newObj = {}
        console.log(checkResponse())
        if (checkResponse()) {
            if (!submitId) {
                newObj['dfId'] = parseInt(id)
                newObj['response'] = data.data1.map((i) => reduceResponse(i))
                newObj['type'] = 0
                newObj['edit'] = 0
                newObj['categoryId'] = params.state.data.cat_id
                newObj['indicatorId'] = params.state.data.id
                newObj['topicId'] = params.state.data.top_id
                newObj['implevel'] = 0
                newObj['reject'] = 0

                newObj['reviewer_modified_on'] = DateTime.utc()
                newObj['reviewer_modified_by'] = login_data.id
                newObj['reporter_modified_on'] = DateTime.utc()
                newObj['reporter_modified_by'] = login_data.id
                newObj['last_modified_on'] = DateTime.utc()
                newObj['last_modified_by'] = login_data.id
                newObj['submitted_by'] = login_data.id
                newObj['submitted_on'] = DateTime.utc()
                newObj['self'] = true
                newObj['response_type'] = 1
                newObj['reporting_period'] =  getRPLuxon(params.state.data.reporting_period)
                newObj['reviewed_on'] = DateTime.utc()
                newObj['reviewed_by'] = login_data.id
                newObj['user_type'] = login_data.role === 'clientuser' ? 1 : login_data.role === 'clientadmin' ? 0 : 2
                newObj['form_type'] = 1

                APIServices.post(API.QL_Submit_UP(admin_data.id), newObj).then((res) => {
                    Swal.fire({
                        title: "Saved As Draft Successfully",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            window.close()
                        }
                    })
                }).catch((e) => {
                    Swal.fire({
                        title: "Something went wrong, try after some time. Contact admin if issue still persist",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            window.close()
                        }
                    })

                })
            } else {
                newObj['last_modified_on'] = DateTime.utc()
                newObj['last_modified_by'] = login_data.id
                newObj['submitted_by'] = login_data.id
                newObj['submitted_on'] = DateTime.utc()
                newObj['reporter_modified_on'] = DateTime.utc()
                newObj['reporter_modified_by'] = login_data.id
                newObj['reject'] = 0
                newObj['type'] = 0
                APIServices.patch(API.QL_Submission_Edit(submitId), newObj).then((res) => {
                    Swal.fire({
                        title: "Saved As Draft Successfully",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            window.close()
                        }
                    })
                }).catch((e) => {
                    Swal.fire({
                        title: "Something went wrong, try after some time. Contact admin if issue still persist",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            window.close()
                        }
                    })

                })

            }




        }
        forceUpdate()

    }
    const saveRF__ = async () => {
        let newObj = {}
        if (data.data1.length !== 0) {
            if (!submitId) {
                newObj['dfId'] = parseInt(id)
                newObj['response'] = data.data1.map((i) => reduceResponse(i))
                newObj['type'] = 1
                newObj['edit'] = 0
                newObj['categoryId'] = params.state.data.cat_id
                newObj['indicatorId'] = params.state.data.id
                newObj['topicId'] = params.state.data.top_id
                newObj['implevel'] = data.implevel
                newObj['reject'] = 0

                newObj['reviewer_modified_on'] = DateTime.utc()
                newObj['reviewer_modified_by'] = login_data.id
                newObj['reporter_modified_on'] = DateTime.utc()
                newObj['reporter_modified_by'] = login_data.id
                newObj['last_modified_on'] = DateTime.utc()
                newObj['last_modified_by'] = login_data.id
                newObj['submitted_by'] = login_data.id
                newObj['submitted_on'] = DateTime.utc()
                newObj['self'] = true
                newObj['response_type'] = 1
                newObj['reporting_period'] =  getRPLuxon(params.state.data.reporting_period)
                newObj['reviewed_on'] = DateTime.utc()
                newObj['reviewed_by'] = login_data.id
                newObj['user_type'] = login_data.role === 'clientuser' ? 1 : login_data.role === 'clientadmin' ? 0 : 2
                newObj['form_type'] = 2
                let { value: return_remarks, isConfirmed } = await Swal.fire({
                    html: `<label class="fs-16 fw-5">Remarks for submission</label>`,
                    input: 'textarea',

                    inputValue: '',

                    allowOutsideClick: false,
                    showCancelButton: true
                })



                if (isConfirmed) {
                    if (typeof return_remarks === 'string' && return_remarks.trim()) {
                        newObj['return_remarks'] = [{ remarks: return_remarks, user_type: 1, type: 1, user_id: login_data.id, created_on: DateTime.utc() }]

                    }
                    APIServices.post(API.QL_Submit_UP(admin_data.id), newObj).then((res) => {
                        Swal.fire({
                            title: "Form Submitted Successfully",

                            confirmButtonText: 'Exit',
                            allowOutsideClick: false,
                        }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (result.isConfirmed) {
                                window.close()
                            }
                        })
                    }).catch((e) => {
                        Swal.fire({
                            title: "Something went wrong, try after some time. Contact admin if issue still persist",

                            confirmButtonText: 'Exit',
                            allowOutsideClick: false,
                        }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (result.isConfirmed) {
                                window.close()
                            }
                        })

                    })
                }

            } else {
                newObj['last_modified_on'] = DateTime.utc()
                newObj['last_modified_by'] = login_data.id
                newObj['submitted_by'] = login_data.id
                newObj['submitted_on'] = DateTime.utc()
                newObj['reporter_modified_on'] = DateTime.utc()
                newObj['reporter_modified_by'] = login_data.id
                newObj['reject'] = 0
                newObj['type'] = 1
                let { value: return_remarks, isConfirmed } = await Swal.fire({
                    html: `<label class="fs-16 fw-5">Please Enter Remarks If Any</label>`,
                    input: 'textarea',

                    inputValue: '',

                    allowOutsideClick: false,
                    showCancelButton: true,
                    inputValidator: (value) => {
                        if ((response.return_remarks !== null && response.return_remarks.filter(i => (i.user_type === 2 || i.user_type === 3)).length !== 0) ? !value.trim() : false) {
                            return 'Enter Remarks'
                        }
                    }
                })

                if (isConfirmed) {


                    if (return_remarks) {
                        if (response.return_remarks === null) {
                            newObj['return_remarks'] = [{ remarks: return_remarks, user_type: 1, type: 1, user_id: login_data.id, created_on: DateTime.utc() }]

                        } else {
                            let lt = response.return_remarks
                            lt.push({ remarks: return_remarks, user_type: 1, type: 1, user_id: login_data.id, created_on: DateTime.utc() })
                            newObj['return_remarks'] = lt
                        }


                    }
                    APIServices.patch(API.QL_Submission_Edit(submitId), newObj).then((res) => {
                        Swal.fire({
                            title: "Form Submitted Successfully",

                            confirmButtonText: 'Exit',
                            allowOutsideClick: false,
                        }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (result.isConfirmed) {
                                window.close()
                            }
                        })
                    }).catch((e) => {
                        Swal.fire({
                            title: "Something went wrong, try after some time. Contact admin if issue still persist",

                            confirmButtonText: 'Exit',
                            allowOutsideClick: false,
                        }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (result.isConfirmed) {
                                window.close()
                            }
                        })

                    })
                }
            }

        } else {
            Swal.fire({
                title: "Cannot submit as empty",

                confirmButtonText: 'Exit',
                allowOutsideClick: false,
            }).then((result) => {
                Swal.close()
            })


        }
        forceUpdate()

    }
    const saveAsDraftHC = () => {
        let newObj = {}
        if (data.data1.length !== 0) {
            if (!submitId) {
                newObj['dfId'] = parseInt(id)
                newObj['response'] = data.data1.map((i) => reduceResponse(i))
                newObj['type'] = 0
                newObj['edit'] = 0
                newObj['categoryId'] = params.state.data.cat_id
                newObj['indicatorId'] = params.state.data.id
                newObj['topicId'] = params.state.data.top_id
                newObj['implevel'] = data.implevel
                newObj['reject'] = 0

                newObj['reviewer_modified_on'] = DateTime.utc()
                newObj['reviewer_modified_by'] = login_data.id
                newObj['reporter_modified_on'] = DateTime.utc()
                newObj['reporter_modified_by'] = login_data.id
                newObj['last_modified_on'] = DateTime.utc()
                newObj['last_modified_by'] = login_data.id
                newObj['submitted_by'] = login_data.id
                newObj['submitted_on'] = DateTime.utc()
                newObj['self'] = true
                newObj['response_type'] = 1
                newObj['reporting_period'] = getRPLuxon(params.state.data.reporting_period)
                newObj['reviewed_on'] = DateTime.utc()
                newObj['reviewed_by'] = login_data.id
                newObj['user_type'] = login_data.role === 'clientuser' ? 1 : login_data.role === 'clientadmin' ? 0 : 2
                newObj['form_type'] = 2

                APIServices.post(API.QL_Submit_UP(admin_data.id), newObj).then((res) => {
                    Swal.fire({
                        title: "Form Submitted Successfully",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            window.close()
                        }
                    })
                }).catch((e) => {
                    Swal.fire({
                        title: "Something went wrong, try after some time. Contact admin if issue still persist",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            window.close()
                        }
                    })

                })

            } else {
                newObj['last_modified_on'] = DateTime.utc()
                newObj['last_modified_by'] = login_data.id
                newObj['submitted_by'] = login_data.id
                newObj['submitted_on'] = DateTime.utc()
                newObj['reporter_modified_on'] = DateTime.utc()
                newObj['reporter_modified_by'] = login_data.id
                newObj['reject'] = 0
                newObj['type'] = 0
                APIServices.patch(API.QL_Submission_Edit(submitId), newObj).then((res) => {
                    Swal.fire({
                        title: "Form Submitted Successfully",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            window.close()
                        }
                    })
                }).catch((e) => {
                    Swal.fire({
                        title: "Something went wrong, try after some time. Contact admin if issue still persist",

                        confirmButtonText: 'Exit',
                        allowOutsideClick: false,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            window.close()
                        }
                    })

                })
            }

        } else {
            Swal.fire({
                title: "Cannot Draft As Empty Dataset",

                confirmButtonText: 'Exit',
                allowOutsideClick: false,
            }).then((result) => {
                Swal.close()
            })


        }
        forceUpdate()

    }
    const saveRF_ = () => {
        let newObj = {}
        console.log(data.justification)
        if (typeof data.justification === 'string' && data.justification.trim().length !== 0) {
            newObj['dfId'] = parseInt(id)
            newObj['response'] = []
            newObj['type'] = 0
            newObj['edit'] = 0
            newObj['categoryId'] = params.state.data.cat_id
            newObj['indicatorId'] = params.state.data.id
            newObj['topicId'] = params.state.data.top_id
            newObj['implevel'] = data.implevel
            newObj['reject'] = 0

            newObj['reviewer_modified_on'] = DateTime.utc()
            newObj['reviewer_modified_by'] = login_data.id
            newObj['reporter_modified_on'] = DateTime.utc()
            newObj['reporter_modified_by'] = login_data.id
            newObj['last_modified_on'] = DateTime.utc()
            newObj['last_modified_by'] = login_data.id
            newObj['submitted_by'] = login_data.id
            newObj['submitted_on'] = DateTime.utc()
            newObj['self'] = true
            newObj['response_type'] = 0
            newObj['reporting_period'] = getRPLuxon(params.state.data.reporting_period)
            newObj['reviewed_on'] = DateTime.utc()
            newObj['reviewed_by'] = login_data.id
            newObj['user_type'] = login_data.role === 'clientuser' ? 1 : login_data.role === 'clientadmin' ? 0 : 2
            newObj['form_type'] = 3

            console.log(newObj)
            APIServices.post(API.QL_Submit_UP(admin_data.id), newObj).then((res) => {
                Swal.fire({
                    title: "Form Submitted Successfully",

                    confirmButtonText: 'Exit',
                    allowOutsideClick: false,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        window.close()
                    }
                })
            }).catch((e) => {
                Swal.fire({
                    title: "Something went wrong, try after some time. Contact admin if issue still persist",

                    confirmButtonText: 'Exit',
                    allowOutsideClick: false,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        window.close()
                    }
                })

            })



        }
        forceUpdate()

    }
    const getBGColor = (clr) => {

        if (data.implevel === 1 && clr === data.implevel) {
            return 'red'
        } else if (data.implevel === 2 && clr === data.implevel) {
            return 'indianred'
        } else if (data.implevel === 3 && clr === data.implevel) {
            return 'darkorange'
        } else if (data.implevel === 4 && clr === data.implevel) {
            return 'mediumseagreen'
        } else if (data.implevel === 5 && clr === data.implevel) {
            return 'green'
        } else {
            return 'white'
        }
    }
    const getColor = (clr) => {

        if (data.implevel === 1 && clr === data.implevel) {
            return 'white'
        } else if (data.implevel === 2 && clr === data.implevel) {
            return 'white'
        } else if (data.implevel === 3 && clr === data.implevel) {
            return 'white'
        } else if (data.implevel === 4 && clr === data.implevel) {
            return 'white'
        } else if (data.implevel === 5 && clr === data.implevel) {
            return 'white'
        } else { return 'black' }
    }
    const getUser = (id) => {
        let user_name = 'Not Found'
        if (id === admin_data.id) {
            return 'Enterprise Admin'
        }
        let index = userList.findIndex(i => i.id === id)
        if (index !== -1) {
            user_name = userList[index].information.empname
        }
        return user_name
    }

    return (
        <>
            {(admin_data.id === 289 || admin_data.id === 17) ? <div className="grid" style={{ margim: 10 }} >
                <div className="col-12">
                    {(login_data.id !== undefined && data.length !== 0) ?
                        <div>

<div className="fs-20 fw-4  clr-gray-900">
                    <div >Consolidated response of Qualitative Inputs pertaining to <span className="fw-7 clr-navy">{data.title}</span> for the Reporting Period  <span className="fw-7 clr-navy">{params.state.data.reporting_period}</span> &nbsp; <small style={{color:'grey'}}>(DF {data.id})</small>   </div>
                    

                    
                </div>


                            <div className="bg-white" style={{ padding: 24, borderBottom: '1px solid #E0E0E0' }}  >
                                <div className="grid col-12 justify-content-between">
                                    <div className="flex fs-16 " style={{ flexDirection: 'row' }}>          <span className="clr-gray-3 fw-4">Aspects:&nbsp;</span> <div className="clr-gray-900 fs-16 fw-7" style={{ display: 'flex' }}>{params.state.data.df.title}   <Tooltip className="tag-tooltip" target={".tags"} position={'top'} autoHide={true}> {params.state.data.df.overallTags.map((i, j) => {
                                        if (i.length !== 0) {
                                            return (
                                                <>
                                                    <label style={{ color: 'black', display: 'flex' }}> {
                                                        j === 0 ? 'Must Have' : j === 1 ? 'Progressive' : 'Advanced'

                                                    }
                                                    </label>
                                                    {
                                                        i.map((tag, k) => {

                                                            return (
                                                                <label style={{ color: 'green' }}>{tag}{k !== i.length - 1 && ','}</label>
                                                            )

                                                        })
                                                    }
                                                    <div style={{ marginBottom: 10 }} />
                                                </>
                                            )
                                        }
                                    })} </Tooltip>
                                        <div style={{ alignItems: 'center' }} ><i className={"material-icons ml-2 tags"} style={{ fontSize: 14, cursor: 'pointer' }}>info</i>  </div>

                                    </div>  </div>
                                    <div className="flex fs-16 " style={{ flexDirection: 'row' }}>          <span className="clr-gray-3 fw-4">Category:&nbsp;</span> <span className="clr-gray-900 fw-7">{params.state.data.df.cat_title}  </span>  </div>


                                </div>
                                <div className="grid col-12 justify-content-between mt-2">
                                    <div className="flex fs-16 " style={{ flexDirection: 'row' }}>          <span className="clr-gray-3 fw-4">Requirement:&nbsp;</span>  <span className="clr-gray-900 fw-7">{params.state.data.df.data1[0].title}</span> </div>
                                    <div className="flex fs-16 " style={{ flexDirection: 'row' }}>          <span className="clr-gray-3 fw-4">Topic:&nbsp;</span> <span className="clr-gray-900 fw-7">{params.state.data.df.top_title}   </span>  </div>

                                    {/* <div className="flex fs-16 col-4" style={{ flexDirection: 'row' }}>          <span className="clr-gray-3 fw-4">Reporting Period:&nbsp;</span> <span className="clr-gray-900 fw-7">  {params.state.data.reporting_period}</span>  </div> */}
                                </div>
                                {submitId && <div className="grid col-12 justify-content-between mt-2">
                                    <div className="flex fs-16 " style={{ flexDirection: 'row' }}>          <span className="clr-gray-3 fw-4"> {(response.type === 0 && !response.reject) ? 'Drafted On' : (response.type === 0 && response.reject) ? 'Rejected On' : response.type === 1 ? 'Submitted On' : (response.type === 3) ? 'Approved On' : ''}&nbsp;</span>  <span className="clr-gray-900 fw-7">{DateTime.fromISO(response.last_modified_on, { zone: 'utc' }).toLocal().toFormat('dd-MM-yyyy hh:mm a')}</span> </div>
                                    <div className="flex fs-16 " style={{ flexDirection: 'row' }}>          <span className="clr-gray-3 fw-4"> {(response.type === 0 && !response.reject) ? 'Drafted By' : (response.type === 0 && response.reject) ? 'Rejected By' : response.type === 1 ? 'Submitted By' : (response.type === 3) ? 'Approved By' : ''}&nbsp;</span> <span className="clr-gray-900 fw-7">{getUser(response.last_modified_by)}   </span>  </div>


                                </div>}
                            </div>


                            <div >

                                {(data.data1.length !== 0 && !checkHardcoded(id)) ?
                                    <div>
                                        <div className="bg-white" style={{ padding: 24 }} >
                                            {
                                                data.data1.map((item, index) => {

                                                    return renderItems(item, index)
                                                })

                                            }
                                        </div>
                                        <div style={{ borderTop: '1px solid #E0E0E0' }} />
                                        <div className="bg-white grid m-0 p-0" style={{ paddingTop: 24 }} >
                                            <label className="col-5 fw-7 fs-16">Comments</label>
                                            <div className="col-12" style={{ maxHeight: 300, overflowY: 'scroll', overflow: 'auto' }}>
                                                {submitId && response !== undefined && response.return_remarks !== null &&
                                                    response.return_remarks.map((cmnt) => {
                                                        return (
                                                            <div className="col-12 grid" style={{ marginBottom: 10, borderBottom: '1px solid gray' }}>
                                                                <div className="col-5">
                                                                    <div>   {cmnt.user_id === login_data.id ? 'You' : getUser(cmnt.user_id)}</div>
                                                                    <div className="mt-2" >     {DateTime.fromISO(cmnt.created_on, { zone: 'utc' }).toLocal().toFormat('dd-LLL-yyyy')} </div>
                                                                </div>
                                                                <div className="col-5">
                                                                    {cmnt.remarks}
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>

                                        <div style={{ position: 'sticky', bottom: 0, zIndex: 100 }}>
                                            <div className="flex justify-content-end" style={{ padding: 10, background: 'white' }}>
                                                <Button label='Close' className="mr-2" onClick={() => { window.close() }} text></Button>

                                                {(!submitId || (submitId && response.type <= 0)) && (status) && <Button label='Save As Draft' onClick={() => { saveAsDraft() }} ></Button>}

                                                {((!submitId || (submitId && response.type === 0))&& (status) && getDisplayStatus(params.state.data.reporting_period)) && <Button label='Save & Submit' onClick={() => { saveRF() }} ></Button>}

                                            </div>
                                        </div>
                                    </div>
                                    : checkHardcoded(id) &&
                                    <div>
                                        <div className="bg-white" style={{ padding: 24 }}>
                                            {id === '85' ?
                                                <div>
                                                    <BGSQ14 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                </div>
                                                : id === '89' ?
                                                    <div>
                                                        <BGSQ15 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                    </div> : id === '102' ?
                                                        <div>
                                                            <BGSQ21 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                        </div> : id === '104' ?
                                                            <div>
                                                                <BP1EQ2 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                            </div> : id === '110' ? <div>
                                                                <BGSQ24 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                            </div> : id === '111' ? <div>
                                                                <BP4EQ2 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                            </div> : id === '112' ? <div>
                                                                <BP7EQ2 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                            </div> : id === '113' ? <div>
                                                                <BP7LQ1 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                            </div> : id === '114' ? <div>
                                                                <BP8LQ1 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                            </div> : id === '115' ? <div>
                                                                <BP8LQ2 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                            </div> : id === '116' ? <div>
                                                                <BP8LQ4 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                            </div> : id === '117' ? <div>
                                                                <BP8LQ5 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                            </div> : id === '118' ? <div>
                                                                <BP8LQ6 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                            </div> : id === '121' ? <div>
                                                                <BP7EQ1B data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                            </div> : id === '133' ? <div>
                                                                <BP8EQ1 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                            </div> : id === '134' ? <div>
                                                                <BP9LQ1 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                            </div> : id === '139' ? <div>
                                                                <BP2LQ1 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                            </div> : id === '140' ? <div>
                                                                <BP2LQ2 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                            </div> : id === '148' ? <div>
                                                                <BP6EQ10 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                            </div> : id === '149' ? <div>
                                                                <BP6EQ11 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                            </div> : id === '150' ? <div>
                                                                <BP6EQ12 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                            </div> : id === '151' ? <div>
                                                                <BP6LQ6 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                            </div> : id === '181' ? <div>
                                                                <SGXGSQ6 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                            </div> : id === '182' ? <div>
                                                                <SGXGSQ7 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                            </div> : id === '183' &&
                                                            <div>
                                                                <SGXGSQ4 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                            </div>
                                            }</div>
                                        <div style={{ borderTop: '1px solid #E0E0E0' }} />
                                        <div className="bg-white grid m-0 p-0" style={{ paddingTop: 24 }} >
                                            <label className="col-5 fw-7 fs-16">Comments</label>
                                            <div className="col-12" style={{ maxHeight: 300, overflowY: 'scroll', overflow: 'auto' }}>
                                                {submitId && response !== undefined && response.return_remarks !== null &&
                                                    response.return_remarks.map((cmnt) => {
                                                        return (
                                                            <div className="col-12 grid" style={{ marginBottom: 10, borderBottom: '1px solid gray' }}>
                                                                <div className="col-5">
                                                                    <div>   {cmnt.user_id === login_data.id ? 'You' : getUser(cmnt.user_id)}</div>
                                                                    <div className="mt-2" >     {DateTime.fromISO(cmnt.created_on, { zone: 'utc' }).toLocal().toFormat('dd-LLL-yyyy')} </div>
                                                                </div>
                                                                <div className="col-5">
                                                                    {cmnt.remarks}
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                        <div style={{ position: 'sticky', bottom: 0, zIndex: 100 }}>
                                            <div className="flex justify-content-end" style={{ padding: 10, background: 'white' }}>
                                                <Button label='Close' onClick={() => { window.close() }}></Button>

                                                {(!submitId || (submitId && response.type <= 0)) && (status)&& <Button label='Save as Draft' onClick={() => { saveAsDraftHC() }} ></Button>}

                                                {((!submitId || (submitId && response.type === 0)) && (status) && getDisplayStatus(params.state.data.reporting_period)) && <Button label='Save & Submit' onClick={() => { saveRF__() }} ></Button>}


                                            </div>
                                        </div>
                                    </div>
                                }


                            </div>
                        </div>
                        :
                        <></>
                    }
                </div>
            </div > :
                < div className='flex justify-content-center'> You Have No Rights To Access This Page </div>}
        </>
    );
};
const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(DFInputEntrySubmission, comparisonFn);