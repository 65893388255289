import React, { useRef, useState, useEffect } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { Checkbox } from "@material-ui/core";
// Sample data for the chart
const initialData = [
  {
    year: "2019",
    Executives: 85,
    Managers: 70,
    Professionals: 90,
    AdminStaff: 65,
    ManualWorkers: 75,
    Total: 80,
  },
  {
    year: "2020",
    Executives: 80,
    Managers: 75,
    Professionals: 85,
    AdminStaff: 60,
    ManualWorkers: 70,
    Total: 78,
  },
  {
    year: "2021",
    Executives: 82,
    Managers: 68,
    Professionals: 88,
    AdminStaff: 63,
    ManualWorkers: 74,
    Total: 79,
  },
  {
    year: "2022",
    Executives: 84,
    Managers: 72,
    Professionals: 87,
    AdminStaff: 67,
    ManualWorkers: 76,
    Total: 81,
  },
  {
    year: "2023",
    Executives: 83,
    Managers: 73,
    Professionals: 89,
    AdminStaff: 64,
    ManualWorkers: 77,
    Total: 82,
  },
];

const GovernanceChart3 = () => {
  const [data, setData] = useState(initialData);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [activeMode, setActiveMode] = useState(true);
  const menuRef = useRef(null);
  const tableRef = useRef(null);
  const chartRef = useRef(null);
  const [visibleSeries, setVisibleSeries] = useState({
    Executives: true,
    Managers: true,
    Professionals: true,
    AdminStaff: true,
    ManualWorkers: true,
    Total: true,
  });

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleCheckboxChange = (key) => {
    setVisibleSeries((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
    setData((prevData) =>
      prevData.map((item) => ({
        ...item,
        [key]: visibleSeries[key]
          ? 0
          : initialData.find((d) => d.year === item.year)[key],
      }))
    );
  };

  const CustomGovernanceLegend = (props) => {
    const { payload } = props;
    return (
      <ul
        style={{
          display: "flex",
          listStyleType: "none",
          justifyContent: "center",
        }}
      >
        {payload.map((entry, index) => (
          <li key={`item-${index}`} style={{ color: entry.color }}>
            <Checkbox
              checked={visibleSeries[entry.dataKey]}
              onChange={() => handleCheckboxChange(entry.dataKey)}
              style={{
                color: entry.color,
                marginRight: 4,
                fontSize: "20px",
              }}
            />

            <span style={{ color: "#555", fontSize: "14px" }}>
              {entry.value}
            </span>
          </li>
        ))}
      </ul>
    );
  };

  const panelItems = [
    {
      items: [
        {
          label: "Export as Excel",
          icon: "pi pi-file-excel",
          command: () => {
            downloadExcelWithImage(chartRef);
          },
        },
        {
          label: "Export as PDF",
          icon: "pi pi-file-pdf",
          command: () => {
            downloadPdfWithImage(chartRef);
          },
        },
        {
          label: "Export as JPG",
          icon: "pi pi-image",
          command: () => {
            downloadChartAsJpg(chartRef);
          },
        },
        activeMode && {
          label: "Print",
          icon: "pi pi-print",
          command: () => {
            printChart(chartRef);
          },
        },
      ],
    },
  ];

  const downloadExcelWithImage = async (chartRef) => {
    // Create a new workbook and add a worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Employee Review Report");

    // Add title in A1 and merge across columns
    worksheet.mergeCells("A1:E1");
    worksheet.getCell("A1").value = "Employee Review Report, Filtered location";
    worksheet.getCell("A1").font = { bold: true, size: 16 };
    worksheet.getCell("A1").alignment = {
      vertical: "middle",
      horizontal: "center",
    };

    // Add timestamp in A2 and merge across columns
    const timestamp = new Date().toLocaleString();
    worksheet.mergeCells("A2:E2");
    worksheet.getCell("A2").value = `Generated on: ${timestamp}`;
    worksheet.getCell("A2").font = { italic: true, size: 12 };
    worksheet.getCell("A2").alignment = {
      vertical: "middle",
      horizontal: "center",
    };

    // Leave an empty row for spacing (Row 3)
    worksheet.getRow(3).values = [];

    // Manually set the column headers in row 4
    worksheet.getRow(4).values = [
      "Year",
      visibleSeries.ManualWorkers && "Manual Workers",
      visibleSeries.Executives && "Executives",
      visibleSeries.Managers && "Managers",
      visibleSeries.Professionals && "Professionals",
      visibleSeries.AdminStaff && "AdminStaff",
      visibleSeries.Total && "Total",
    ];

    // Define columns for the table (this does NOT affect Row 4 headers)
    worksheet.columns = [
      { key: "year", width: 10 },
      visibleSeries.ManualWorkers && { key: "ManualWorkers", width: 15 },
      visibleSeries.Executives && {
        key: "Executives",
        width: 15,
      },
      visibleSeries.Managers && {
        key: "Managers",
        width: 15,
      },
      visibleSeries.Professionals && {
        key: "Professionals",
        width: 15,
      },
      visibleSeries.AdminStaff && {
        key: "AdminStaff",
        width: 15,
      },
      visibleSeries.Total && {
        key: "Total",
        width: 15,
      },
    ];

    // Populate data rows starting from row 5
    initialData.forEach((row) => worksheet.addRow(row));

    // Capture the chart as an image
    //chartRef.current.data.dataset[0].data = initialData;
    const canvas = await html2canvas(chartRef.current);

    const imageData = canvas.toDataURL("image/png");

    // Add the image to the workbook below the data
    const imageId = workbook.addImage({
      base64: imageData,
      extension: "png",
    });

    worksheet.addImage(imageId, {
      tl: { col: 0.5, row: data.length + 6 }, // Adjust row based on data length
      ext: { width: 500, height: 300 },
    });

    // Download the Excel file
    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), "Employee_Review_Report.xlsx");
  };

  const downloadPdfWithImage = async (chartRef) => {
    // Create a new jsPDF instance
    const doc = new jsPDF();

    // Add title
    doc.setFontSize(16);
    doc.setFont("helvetica", "bold");
    doc.text("Governance Report", 20, 20); // Adjust position (x, y)

    // Add timestamp
    const timestamp = new Date().toLocaleString();
    doc.setFontSize(12);
    doc.setFont("helvetica", "italic");
    doc.text(`Generated on: ${timestamp}`, 20, 30); // Adjust position (x, y)

    // Add a line break
    doc.text("\n", 20, 40);

    // Define column headers
    const headers = [
      "Year",
      visibleSeries.ManualWorkers && "Manual Workers",
      visibleSeries.Executives && "Executives",
      visibleSeries.Managers && "Managers",
      visibleSeries.Professionals && "Professionals",
      visibleSeries.AdminStaff && "AdminStaff",
      visibleSeries.Total && "Total",
    ];
    const dataRows = initialData.map((row) => [
      row.year,
      row.ManualWorkers,
      row.Executives,
      row.Managers,
      row.Professionals,
      row.AdminStaff,
      row.Total,
    ]);

    // Add table (with headers and data rows)
    doc.autoTable({
      head: [headers],
      body: dataRows,
      startY: 50, // Start after the title and timestamp
      theme: "grid",
      headStyles: { fillColor: [0, 0, 0] }, // Dark background for header
      styles: { cellPadding: 2, fontSize: 10 },
    });

    // Capture the chart as an image
    const canvas = await html2canvas(chartRef.current);
    const imageData = canvas.toDataURL("image/png");

    // Add the chart image below the table
    doc.addImage(imageData, "PNG", 20, doc.lastAutoTable.finalY + 10, 180, 100); // Adjust position and size

    // Download the PDF file
    doc.save("Employee_Review_Report.pdf");
  };

  const downloadChartAsJpg = async (chartRef) => {
    // Capture the chart as an image using html2canvas
    const canvas = await html2canvas(chartRef.current);

    // Convert the canvas to JPG data
    const imageData = canvas.toDataURL("image/jpeg");

    // Create a link element to trigger the download
    const link = document.createElement("a");
    link.href = imageData;
    link.download = "Employee_Report_Review.jpg"; // Filename for the image
    link.click(); // Programmatically click the link to trigger the download
  };

  const printChart = async (chartRef) => {
    // Capture the chart as an image using html2canvas
    const canvas = await html2canvas(chartRef.current);
    const chartImage = canvas.toDataURL("image/png");

    // Create a new window for printing
    const printWindow = window.open("", "", "height=600,width=800");

    // Add some basic styles for the printed content
    const styles = `
      <style>
        body { font-family: Arial, sans-serif; }
        h1 { text-align: center; }
        h2 { margin-top: 20px; }
        img { max-width: 100%; height: auto; margin-bottom: 20px; }
        .table-container { margin-top: 20px; }
        table { width: 100%; border-collapse: collapse; }
        th, td { padding: 10px; text-align: left; border: 1px solid #ddd; }
      </style>
    `;

    // Set the content of the print window
    printWindow.document.write(
      "<html><head><title>Print Report</title>" + styles + "</head><body>"
    );
    printWindow.document.write("<h1>Employee Review Report</h1>");

    // Add chart image
    printWindow.document.write("<h2>Chart</h2>");
    printWindow.document.write(`<img src="${chartImage}" />`);

    // Add table content

    // Check if table content is available and print it

    printWindow.document.write("</body></html>");

    // Close the document and trigger the print dialog
    printWindow.document.close();
    printWindow.print();
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            fontFamily: "Lato",
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "19.2px",
            textAlign: "left",
            margin: "18px 10px 18px 10px",
          }}
        >
          Percentage of employees receiving regular performance and career
          development reviews.
        </div>
        <div
          style={{
            margin: "18px 10px 18px 10px",
            display: "flex",
          }}
        >
          {/* <input
            type="month"
            style={{
              padding: "3px",
              borderRadius: "8px",
              width: "15rem",
              border: "1px solid grey",
              height: "30px",
              fontFamily: "lato",
            }}
          /> */}
          <div
            className="buttons"
            style={{
              background: "#F0F2F4",
              borderRadius: "3px",
              width: "4.5rem",
              marginLeft: "10px",
              height: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              style={{
                background: `${!activeMode ? "#FFFFFF" : "transparent"}`,
                padding: "6px",
                color: "black",
                border: "0px",
                marginRight: "4px",
              }}
              onClick={() => {
                setActiveMode(false);
              }}
            >
              <i className="pi pi-table fs-19 " />
            </Button>
            <Button
              style={{
                background: `${activeMode ? "#FFFFFF" : "transparent"}`,
                padding: "6px",
                color: "black",
                border: "0px",
              }}
              onClick={() => {
                setActiveMode(true);
              }}
            >
              <i className="pi pi-chart-bar fs-19" />
            </Button>
          </div>
          <div ref={menuRef}>
            <Button
              style={{
                color: "black",
                height: "30px",
                marginLeft: "3px",
                background: "#F0F2F4",
                border: "0px",
                padding: "6px",
                position: "relative",
              }}
              onClick={() => {
                setDropdownOpen(!dropdownOpen);
              }}
            >
              <i className="pi pi-angle-down fs-19" />
            </Button>
            {dropdownOpen && (
              <Menu
                model={panelItems}
                style={{
                  position: "absolute",
                  right: 45,
                  zIndex: "1",
                  padding: 0,
                }}
              ></Menu>
            )}
          </div>
        </div>
      </div>
      {activeMode && (
        <div style={{ width: "100%", height: 400 }}>
          <ResponsiveContainer width="100%" height={400} ref={chartRef}>
            <BarChart
              data={data}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
              barSize={15}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="year" />
              <YAxis />
              <Tooltip />
              <Legend content={CustomGovernanceLegend} />
              <Bar dataKey="Executives" fill="#003f5c" />
              <Bar dataKey="Managers" fill="#ffa600" />
              <Bar dataKey="Professionals" fill="#ef5675" />
              <Bar dataKey="AdminStaff" fill="#7a5195" />
              <Bar dataKey="ManualWorkers" fill="#2f4b7c" />
              <Bar dataKey="Total" fill="#ffa600" />{" "}
              {/* Different color for total */}
            </BarChart>
          </ResponsiveContainer>
        </div>
      )}
      {!activeMode && (
        <div>
          <DataTable ref={tableRef} value={data} tableClassName="font-lato">
            <Column
              header="Year"
              style={{ minWidth: "8%" }}
              field="year"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header="Executives"
              style={{ minWidth: "8%" }}
              field="Executives"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header="Managers"
              field="Managers"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header="Professionals"
              field="Professionals"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header="AdminStaff"
              field="AdminStaff"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header="ManualWorkers"
              field="ManualWorkers"
              emptyMessage="No Assignment(s)"
            />
          </DataTable>
        </div>
      )}
    </>
  );
};

export default GovernanceChart3;
