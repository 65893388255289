import Axios from "axios";
import moment from "moment";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import useForceUpdate from "use-force-update";
import { API } from "../../../constants/api_url";

import { useHistory } from "react-router-dom";
import { TabMenu } from 'primereact/tabmenu';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column"
import { Tooltip } from "primereact/tooltip";
import Swal from 'sweetalert2'
import APIServices from "../../../service/APIService";
import { Tag } from "primereact/tag";
import { Button } from "primereact/button";
import { OverlayPanel } from "primereact/overlaypanel";
import { MultiSelect } from "primereact/multiselect";
import { getLocationData } from "../../../components/BGHF/helper";
const { DateTime } = require('luxon')


const UserSectionDashboard = () => {

    const login_data = useSelector((state) => state.user.userdetail)
    const admin_data = useSelector((state) => state.user.admindetail)
    const allRoles = useSelector((state) => state.user.allRoles)

    const [certcount,setCertCount] = useState(0)
    const navigate = useHistory()
   
    return (
        <div className="bg-smoke font-lato" >
            <div className="col-12" >

                <div>
                    <div className="col-12 " style={{
                        justifyContent: 'flex-start'
                    }}>
                          
                        <label className="text-big-one clr-navy flex fw-7 flex justify-content-start"> Section Dashboard
                        </label>
                       

                    </div>
                    
                        <div className="col-12  grid ">
                            <div className='col-3  user-dashboard-card p-card'  style={{ borderBottom: '5px solid red' }} >
                                <div>   <label className="fs-30 fw-7"></label> </div>
                                <div> <label className="fs-18 fw-5 "> Sustainability </label>
                                </div><div>
                                    <label className="fs-12 clr-gray"></label> </div>
                                <div>   <label className="fs-14 fw-7"> Go to Sustainability Dashboard <i className="pi pi-arrow-up-right fs-14" /> </label> </div>
                            </div>
                            <div className='col-3  user-dashboard-card p-card'  style={{ borderBottom: '5px solid skyblue' }} >
                                <div>   <label className="fs-30 fw-7"></label> </div>
                                <div> <label className="fs-18 fw-5 "> Health </label>
                                </div><div>
                                    <label className="fs-12 clr-gray"></label> </div>
                                <div>   <label className="fs-14 fw-7 "> Go to Health Dashboard <i className="pi pi-arrow-up-right fs-14" /> </label> </div>
                            </div>
                            <div className='col-3  user-dashboard-card p-card'  style={{ borderBottom: '5px solid #FFC300' }} >
                                <div>   <label className="fs-30 fw-7"></label> </div>
                                <div> <label className="fs-18 fw-5 "> Safety Central </label>
                                </div><div>
                                    <label className="fs-12 clr-gray"> </label> </div>
                                <div>   <label className="fs-14 fw-7 "> Go to Safety Central Dashboard  <i className="pi pi-arrow-up-right fs-14" /> </label> </div>
                            </div>
                            <div className='col-3  user-dashboard-card p-card'  style={{ borderBottom: '5px solid #FFC300' }} >
                                <div>   <label className="fs-30 fw-7"></label> </div>
                                <div> <label className="fs-18 fw-5 "> Safety Operational </label>
                                </div><div>
                                    <label className="fs-12 clr-gray"> </label> </div>
                                <div>   <label className="fs-14 fw-7 "> Go to Safety Operational Dashboard  <i className="pi pi-arrow-up-right fs-14" /> </label> </div>
                            </div>
                            <div className='col-3  user-dashboard-card p-card'  style={{ borderBottom: '5px solid #FFC300' }} >
                                <div>   <label className="fs-30 fw-7"></label> </div>
                                <div> <label className="fs-18 fw-5 "> Supply Chain </label>
                                </div><div>
                                    <label className="fs-12 clr-gray"> </label> </div>
                                <div>   <label className="fs-14 fw-7 "> Go to Supply Chain Dashboard  <i className="pi pi-arrow-up-right fs-14" /> </label> </div>
                            </div>
                            <div className='col-3  user-dashboard-card p-card' onClick={() => { navigate.push({ pathname: '/env_kpi_dashboard' }) }}  style={{ borderBottom: '5px solid #FFC300' }} >
                                <div>   <label className="fs-30 fw-7"></label> </div>
                                <div> <label className="fs-18 fw-5 "> Environmental </label>
                                </div><div>
                                    <label className="fs-12 clr-gray"> </label> </div>
                                <div>   <label className="fs-14 fw-7 "> Go to Environmental Dashboard  <i className="pi pi-arrow-up-right fs-14" /> </label> </div>
                            </div>
                            <div className='col-3  user-dashboard-card p-card'  style={{ borderBottom: '5px solid #FFC300' }} >
                                <div>   <label className="fs-30 fw-7"></label> </div>
                                <div> <label className="fs-18 fw-5 "> Social </label>
                                </div><div>
                                    <label className="fs-12 clr-gray"> </label> </div>
                                <div>   <label className="fs-14 fw-7 "> Go to Social Dashboard  <i className="pi pi-arrow-up-right fs-14" /> </label> </div>
                            </div>
                            <div className='col-3  user-dashboard-card p-card'  style={{ borderBottom: '5px solid #FFC300' }} >
                                <div>   <label className="fs-30 fw-7"></label> </div>
                                <div> <label className="fs-18 fw-5 "> Governance </label>
                                </div><div>
                                    <label className="fs-12 clr-gray"> </label> </div>
                                <div>   <label className="fs-14 fw-7 "> Go to Governance Dashboard  <i className="pi pi-arrow-up-right fs-14" /> </label> </div>
                            </div>
                            
                        </div>
                    
                </div>
            </div>
         
        </div>
    )

}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(UserSectionDashboard, comparisonFn);